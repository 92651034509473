//GENERAL
import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Suspense, useEffect, useState } from "react";
import ReactGa from 'react-ga'
import Modal from 'react-modal'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from './config/firebaseConfig'

//COMPONENTS
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import AlertQuestions from './modals/AlertQuestions/AlertQuestions';

//VIEWS 
import FashionCollection from './views/FashionCollection/FashionCollection';
import NFTCreation from './views/NFTCreation/NFTCreation';
import NFTDetail from './views/NFTDetail/NFTDetail';
import MyGallery from './views/MyGallery/MyGallery';
import LandingNft from './views/LandingNft/LandingNft';
import QRHandler from './views/QRHandler/QRHandler';

//UTILS
import { buttonUpload, modalContainer, modalOverlay } from './utils/constants/classes';
import { Icon } from '@iconify/react';
import CreationAssist from './views/CreationAssist/CreationAssist';
import { setUser } from './redux/actions/actionsGeneral';
import ConfigureCreation from './views/ConfigureCreation/ConfigureCreation';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.jsx'
import AlertExternalBrowser from './modals/AlertExternalBrowser/AlertExternalBrowser';
import { useRef } from 'react';
import AlertTermsConditions from './modals/AlertTermsConditions/AlertTermsConditions';


export default function App() {

  const [modalQuestions, setModalQuestions] = useState()
  const [externalOpen, setExternalOpen] = useState(false)
  const [modalTerms, setModalTerms] = useState(false)
  const user = useSelector(state => state.general.user)
  const dispatch = useDispatch();
  const auth = getAuth(app);
  const instaRedirect = useRef()

  useEffect(() => {
    ReactGa.initialize('G-NKS87H4C5J')
    ReactGa.pageview('/')
  }, [])

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng')
    if (lang === 'EN' || lang === 'ES') {
      localStorage.removeItem('i18nextLng')
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    if (user && !user.termsAccepted) {
      setModalTerms(true)
    }
  }, [user])

  useEffect(() => {
    if (navigator.userAgent.includes('Instagram') && navigator.userAgent.includes('Linux')) {
      instaRedirect.current.click()
    }
    if (navigator.userAgent.includes('Instagram') && navigator.userAgent.includes('iPhone')) {
      setExternalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onAuthStateChanged(auth, (logged) => {
      if (logged) {
        if (!user || user.firebaseID !== logged.uid) {
          dispatch(setUser({
            email: logged.email,
            uid: logged.uid
          }))
        }
      } if (!logged && user) {
        dispatch(setUser(null))
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (

    <div className="w-full h-screen relative font-montserrat">

      <Modal
        isOpen={externalOpen}
        style={{
          overlay: {
            zIndex: "9999",
            backgroundColor: '#06054280',
          }
        }}
        className={`absolute right-2 top-2 focus:outline-none rounded-2xl max-w-[220px] max-h-[120px]`}>
        <AlertExternalBrowser />
      </Modal>

      <a id='instagramRedirect' className='hidden' rel="noreferrer" ref={instaRedirect} href={window.location.href} target='_blank' download>REDIRECT INSTAGRAM</a>


      {/*SUGGESTION MODAL START*/}
      <Modal
        isOpen={modalQuestions}
        style={modalOverlay}
        className={`${modalContainer} w-[100vw] h-10/12 max-h-[560px] sm:w-[534px] sm:h-[578px] `}>
        <AlertQuestions setModalQuestions={setModalQuestions} />
      </Modal>



      <Modal
        isOpen={modalTerms}
        style={modalOverlay}
        className={`${modalContainer} w-[100vw] h-7/12 sm:w-[496px] sm:h-[552px] `}>
        <AlertTermsConditions user={user} setModalTerms={setModalTerms} />
      </Modal>

      <button onClick={() => setModalQuestions(true)} className={`${buttonUpload} cursor-pointer fixed lg:flex text-white w-[50px] h-[50px] bottom-10 right-4 md:w-[70px] md:h-[70px] md:bottom-16 md:right-10 2xl:w-[80px] 2xl:h-[80px] 2xl:bottom-20 2xl:right-16 rounded-full z-50`} >
        <Icon icon={'ep:chat-dot-round'} className='text-white w-full h-11/12 2xl:h-8/12' />
      </button>
      {/*SUGGESTION MODAL END*/}

      <BrowserRouter>
        <ScrollToTop />
        <Route exact path="/QR/:id" component={QRHandler} />

        <Suspense fallback="cargando...">

          <Route path="/" component={Navbar} />

          <div className='flex'>
            <Switch>

              <Route exact path="/" component={LandingNft} />
              <Route exact path="/fashioncollections" component={FashionCollection} />
              <Route exact path="/configure" component={ConfigureCreation} />
              <Route path="/detailnft/:idNft" component={NFTDetail} />
              <Route exact path="/gallery/:userID" component={MyGallery} />
              <Route exact path="/concierge" component={CreationAssist} />

              <Route exact path="/:creationType" component={NFTCreation} />

            </Switch>
          </div>
          <Route path="/" component={Footer} />
        </Suspense>
      </BrowserRouter>
    </div>

  );
}
