import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'

export default function SpanTutorial({ classSpan }) {

    const { t } = useTranslation()

    return (
        <div className={`w-full ${classSpan} text-pinky font-semibold`}>
            <Icon icon={"ant-design:play-circle-filled"} className="text-[14px] sm:text-[23px] mr-2 inline"/>
            <a 
                target={'_blank'} 
                href={'https://www.youtube.com/watch?v=J9aX-976iX0'}
                className={'hover:underline text-[12px] sm:text-[16px] inline'}
            >
                {t("toolkitNft.tutorial")}
            </a>
        </div>
    )
}
