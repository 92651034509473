import { Icon } from '@iconify/react';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonSecondary } from '../../utils/constants/classes';

export default function ButtonsAndWarning({setFormOn, isValid, classNameContainer}) {
    
    const {t} = useTranslation();

    return (
        <div className={`flex flex-col w-full justify-start items-center gap-y-3 ${classNameContainer} `}>

            <div className='w-full  flex justify-center items-center bg-white rounded-xl shadow-around text-[#59096E] py-1'>
                <div className='flex justify-start items-center w-11/12 gap-x-4'>
                    <Icon icon='clarity:warning-line' className='text-[60px]'/>
                    <span className='text-[12px] sm:text-[14px]'>{t('detailNFT.formNameDescription.beforeMint')}</span>
                </div>
            </div>
            
            <div className='flex justify-between items-center w-full gap-x-12 '>
                <button className={`text-[#E671ED] w-full text-[14px] sm:text-[15px] lg:text-[16px] flex items-center text-center justify-center`} onClick={()=>setFormOn(false)}>
                    <Icon icon='ri:arrow-go-back-line' className='mr-1 -mt-0.5'/>
                    {t('detailNFT.goBack')}
                </button>

                <button className={`${ButtonSecondary} w-full text-[#E671ED] disabled:text-gray-500 text-[14px] sm:text-[15px] lg:text-[16px] font-semibold`} type='submit' disabled={!isValid}>
                    {t('detailNFT.save')}
                </button>
            </div>

        </div>
    )
}
