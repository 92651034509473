//GENERAL
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

//COMPONENTS
import Loader from '../../components/Loader/Loader';
import DataDetailNFT from '../../components/DataDetailNFT/DataDetailNFT';
import CardCreatorDetailNFT from '../../components/CardCreatorDetailNFT/CardCreatorDetailNFT';

//UTILS
import { backgroundGradient, containerStructureGeneral, structureViewsPanel } from '../../utils/constants/classes'
import Confetti from 'react-confetti'
import ViewerAndTeaser from '../../components/ViewerAndTeaser/ViewerAndTeaser';
import UtilitiesAndQR from '../../components/UtilitiesAndQR/UtilitiesAndQR';
import GridCreationDetail from '../../components/GridCreationDetail/GridCreationDetail';
import BenefitsDescription from '../../components/BenefitsDescription/BenefitsDescription';
import AdderBenefits from '../../components/AdderBenefits/AdderBenefits';
import { fetchBackend } from '../../utils/functions/logic/fetchBackend';
import { setCreationType } from '../../redux/actions/actionsToolkit';

export default function NFTDetail() {

    const params = useParams()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const stateType = useSelector(state => state.toolkit.type)
    const [responseNft, setResponseNft] = useState()
    const [loading, setLoading] = useState(true)
    const [flagViewer, setFlagViewer] = useState(false)
    const [confetti, setConfetti] = useState(false)
    const [updated, setUpdated] = useState(false)

    let buttonAR = document.getElementById("pepe")

    let buttonAR2 = document.getElementById("test2")


    let url8Wall2 = buttonAR2 ? buttonAR2.getAttribute("href") : null;
    let url8Wall = buttonAR ? buttonAR.getAttribute("href") : null;
    
    const handle8thWall = () => {
        if (stateType === "Vehicle") {
            window.location.href = `https://musureworld.8thwall.app/swapmusureghini/?textLeft=${responseNft?.texture_1.slice(35)}`
        }
        if (stateType === "Sneaker") {

            window.location.href = `https://musureworld.8thwall.app/textureswap/?textLeft=${responseNft?.texture_1.slice(35)}`
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchBackend('GET', `/NFT/?idNFT=${params.idNft}`)
            .then((res) => {
                dispatch(setCreationType({ type: res.data.nft.type }))
                setResponseNft(res.data.nft)
                setFlagViewer(!flagViewer)
                setLoading(false)

            })
            .catch(err => history.goBack())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.idNft, updated])

    useEffect(() => {
        if (location.pathname.includes("new")) {
            setConfetti(true)
        }
        if (location.pathname.includes("arcode") && responseNft) {
            handle8thWall()
        }
        if (location.pathname.includes("arcode") && location.pathname.includes("62eb31138895c75d49af95b0")) {
            window.location.href = "https://musureworld.8thwall.app/qrexperiencie"
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonAR, url8Wall, buttonAR2, url8Wall2])

    let width = window.innerWidth
    let height = window.innerHeight

    return (
        <div className={`${containerStructureGeneral}  ${backgroundGradient}`}>
            {confetti &&
                <Confetti
                    width={width}
                    height={height}
                    tweenDuration={30000}
                    wind={0.03}
                    gravity={0.09}
                    initialVelocityX={4}
                    initialVelocityY={15}
                    run={true}
                    recycle={false}
                    numberOfPieces={width < 768 ? 700 : width < 1024 ? 1500 : 3000}
                    onConfettiComplete={() => setConfetti(false)}
                />
            }
            <div className={`${structureViewsPanel}`}>

                {loading ?
                    <Loader />
                    :
                    <div className={`w-full h-full flex flex-col justify-around items-start `}>

                        <div className={`w-full h-full flex flex-col justify-around items-start gap-y-12`}>

                            {/*HEAD*/}
                            <div className={`w-full flex flex-col justify-start items-center lg:flex-row lg:items-start lg:justify-between gap-y-4 lg:gap-x-2 mb-12`}>

                                <div className='w-full flex flex-col items-center justify-start lg:w-[44%] gap-y-4 lg:gap-y-0'>
                                    <CardCreatorDetailNFT creator={responseNft?.createdBy} nft={responseNft} place='mobile' />
                                    <ViewerAndTeaser nft={responseNft} flagViewer={flagViewer} />
                                </div>

                                <div className='w-full lg:h-[480px] flex flex-col items-start justify-start lg:w-[52%]'>
                                    <CardCreatorDetailNFT creator={responseNft?.createdBy} place='desktop' />
                                    <DataDetailNFT nft={responseNft} setUpdated={setUpdated} updated={updated} />
                                </div>
                            </div>

                            {/*UTILITIES AND NFTS*/}
                            <div className='w-full flex flex-col items-start justify-start lg:flex-row lg:gap-x-5'>

                                <div className='w-full h-full relative flex flex-col gap-y-3 justify-around items-start text-white lg:w-9/12'>

                                    <div className='w-full flex flex-col justify-start items-start gap-y-3 '>
                                        <BenefitsDescription nft={responseNft} setUpdated={setUpdated} updated={updated} />

                                        <AdderBenefits nft={responseNft} setUpdated={setUpdated} updated={updated} />
                                    </div>

                                    <div className='w-full lg:hidden'>
                                        <UtilitiesAndQR nft={responseNft} handle8thWall={handle8thWall} place='mobile' />
                                    </div>

                                    <GridCreationDetail nft={responseNft} />

                                </div>

                                <div className='w-full lg:flex hidden text-white lg:w-3/12'>
                                    <UtilitiesAndQR handle8thWall={handle8thWall} nft={responseNft} place='desktop' />
                                </div>

                            </div>

                        </div>
                    </div>
                }
            </div>
        </div >
    )
}
