//GENERAL
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

//COMPONENTS
import { CopyToClipboard } from 'react-copy-to-clipboard';

//UTILS
import { Icon } from '@iconify/react';
import { ButtonPrimary, ButtonSecondary } from '../../utils/constants/classes';
import { urlBase } from '../../config/configServer';
import FormNameDescription from '../FormNameDescription/FormNameDescription';
import NameAndDescription from '../NameAndDescription/NameAndDescription';
import BenefitsOverview from '../BenefitsOverview/BenefitsOverview';

export default function DataDetailNFT({ nft, setUpdated, updated }) {

    const { t } = useTranslation()
    const [copy, setCopy] = useState(false)
    const user = useSelector(state => state.general.user)
    const [formOn, setFormOn] = useState(false)

    const handleMinted = () => {
        window.open(nft.onSale);
        //handelear con una direccion por id
    }

    return (
        <div className='w-full h-full gap-y-4 flex flex-col justify-start lg:justify-between items-start text-white pt-2 lg:pt-6'>
            {
                !formOn ?
                    //NAME AND DESCRIPTION
                    <NameAndDescription nft={nft} setFormOn={setFormOn} />
                    :
                    <FormNameDescription nft={nft} setFormOn={setFormOn} setUpdated={setUpdated} updated={updated} />
            }


            {/*BUTTONS*/}
            {!formOn &&
                <div className='w-full flex flex-col sm:flex-row justify-center gap-y-4 sm:gap-x-4 sm:gap-y-0 items-center mt-4 sm:mt-8'>

                    <button disabled={nft.onSale === "false" || !nft.onSale} className={`${ButtonPrimary} w-full flex justify-center items-center xl:w-6/12 gap-x-2 h-[44px] max-w-[360px] sm:max-w-none`} onClick={() => handleMinted()}>
                        <Icon icon='clarity:wallet-line' className="text-[22px]" />
                        <h4>{t("detailNFT.buy")}</h4>
                    </button>


                    {/* <BenefitsOverview classNameContainer='flex sm:hidden' nft={nft}/> */}

                    <CopyToClipboard text={`${urlBase}/detailnft/${nft._id}`} onCopy={() => setCopy(true)}>
                        <button disabled={copy} className={`${ButtonSecondary} w-full flex justify-center items-center xl:w-6/12 gap-x-2 h-[44px] max-w-[360px] sm:max-w-none`}>
                            <Icon icon={"ant-design:share-alt-outlined"} className="text-[22px]" />
                            <h4>{copy ? t('detailNFT.copied') : t("detailNFT.share")}</h4>
                        </button>
                    </CopyToClipboard>


                </div>}

        </div>
    )
}
