export const filtersBase = {
    status:{
        minted: false,
        notMinted: false,
    },
    creationType:{
        Sneaker: false,
        Pet: false,
        Vehicle: false,
        Skin: false,
        Wing: false,
        Graffitti: false,
        Dance: false,
        Bazooka: false,
    }
}

export const sortsBase = {
    createdAt:{
        ascending: false,
        descending: false,
    }
}