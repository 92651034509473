import { Icon } from '@iconify/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export default function MessageMarketGrid() {

    const location = useLocation()
    const {t}= useTranslation();
    const objectSearch = Object.fromEntries(new URLSearchParams(location.search))
    const searchValues = Object.values(objectSearch).filter(v=> v !== 'null' )

    return (
        <div className="w-full h-full pt-20 md:pt-32">
            {
                searchValues.length > 0 ?

                    <div className='w-full flex flex-col items-center justify-center gap-y-2'>

                        <Icon className="text-[56px] sm:text-[76px] lg:text-[86px] text-white " icon='carbon:tag-none' />
                        <h3 className="sm:text-[18px] lg:text-[21px] text-center text-white font-semibold mt-2">{t("fashioncollection.marketGrid.titleFilter")}</h3>
                        <span className="text-white text-center text-[14px] sm:text-[15px] lg:text-[16px]">{t("fashioncollection.marketGrid.subtitleFilter")}</span>

                    </div>
                :
                    <div className='w-full flex flex-col items-center justify-center gap-y-2'>
                        <Icon className="text-[56px] sm:text-[76px] lg:text-[86px] text-white " icon='tabler:pencil-off' />
                        <h3 className="sm:text-[18px] lg:text-[21px] text-center text-white font-semibold mt-2">{t("fashioncollection.marketGrid.titleEmpty")}</h3>
                    </div>
            }
        </div>
    )
}
