import { setTextureSneaker } from "../../../redux/actions/actionsToolkit"
import { handleTexturePignata } from "./handleTexture";

export async function handleRandomTexture(e, dispatch, setModalError, setProcessing, setName, setFlagViewer, flagViewer) {

  
    let files = Object.values(e.target.files)
    let aux2 = files.find(e => e.size > '2000000')
    let aux = files.find(e => e.type !== "image/jpeg" && e.type !== 'image/png')
    let file1;
    try {

        if (aux || aux2) {
            //ERROR  FORMATO
            //SETEAR OBJ ERROR
            console.log('error')
            return setModalError(true)
        }
        else {
            setProcessing(true)
            setName(files[0].name)
            file1 = await handleTexturePignata(files[0])

            //ACA SE PODRIA ROTAR LA IMAGEN Y PASARLA NUEVAMENTE POR IPFS A LA ROTADA
            dispatch(setTextureSneaker(`https://musure.mypinata.cloud/ipfs/${file1.data.IpfsHash}`))

            setFlagViewer(!flagViewer)
            setProcessing(false)
        }
    }
    catch (err) {
        console.log(err)
    }
}

export function removeRandomTexture(dispatch, setName, setFlagViewer, flagViewer) {
    dispatch(setTextureSneaker(""))

    setName("")
    setFlagViewer(!flagViewer)
}