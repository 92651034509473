import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    'redirectInstagram': {
                        'title': 'Please click <0></0> and select <1>"Open in browser"</1> to get Full access to the website'
                    },
                    'modal': {
                        'modalErrorInputFile': {
                            'title': 'Oops! We were unable to load the image',
                            'text': 'There was an error in the file. Check that it is a JPG or PNG and is no larger than 2MB.',
                            'text2': 'You can reduce its weight from <0>here</0>',
                            'button': 'OK'
                        },
                        'modalTransfer': {
                            "title": "Transfer Creation",
                            "usernotfound": "User does not exist",
                            "text": "Choose the person to send this creation to",
                            "inputName": "E-mail",
                            "placeholder": "creator@musure.world",
                            "transfer": "Transfer",
                            "cancel": "Cancel"

                        },
                        'modalGift': {
                            "title": "Mint a Gift",
                            "description": "Mint this creation free to gift it",
                            "select": "Select a gift way",
                            "email": "E-mail",
                            "wallet": "Wallet",
                            "gift": "Gift",
                            "cancel": "Cancel",
                            "error": "Error on the gift direction",
                            "send": "Sended"
                        },
                        "modalDelete": {
                            "title": "Do you want to delete this creation?",
                            "text": "Once deleted, you will not be able to recover it.",
                            "button1": "Delete",
                            "button2": "Cancel"
                        },
                        "alertDownload": {
                            "title": "You have downloaded the templates!",
                            "subtitle": "Now you can begin to shape your art",
                            "text2": "When you are done editing the templates, upload them in <strong>JPG, without compression or image resizing.</strong>",
                            "button": "OK",
                            "failDownload": "Did the templates not download? <strong>Download again</strong>"
                        },
                        "alertTermsConditions": {
                            "title": "Terms and Conditions and Privacy Policy",
                            "subtitle": "I have read and accept the <0>Terms and Conditions</0>.",
                            "terms": "<0>Terms and Conditions</0>",
                            "text1": "Ratifico la cesión de derechos de Propiedad Intelectual en favor de MUSURE.",
                            "text2": "Ratifico la gestión comercial a cargo de MUSURE, que reconocerá en favor del Creador Verificado:",
                            "subtext1": "El cincuenta por ciento (50%) del resultado obtenido de la primera comercialización.",
                            "subtext2": "Y el cinco por ciento (5%) del precio transaccionado a partir de las operaciones subsiguientes.",
                            "button": "Accept"
                        },
                        "alertQuestions": {
                            "title": "We want to know your opinion",
                            "text": "Write to us with any suggestions or requests you may have. Our team will take your comments into account to improve the experience.",
                            "label1": "Name",
                            "label2": "E-mail",
                            "label3": "Message *",
                            "place1": "Your Name",
                            "place2": "creator@musure.world",
                            "place3": "Leave your comments",
                            "button": "Send",
                            "send": "Message sent",
                            'invalidEmail': 'That is not a valid Email',
                            'messageRequired': 'Do not forget to write your message',
                            'messageLength': 'Message length must be between {{min}} and {{max}} characters long',
                        }
                    },
                    "navbar": {
                        'fashionCollection': 'Explore',
                        'creationToolkit': 'Creation Toolkit',
                        'language': 'Language',
                        'es': 'Español',
                        'en': 'English',
                        'profile': 'My profile',
                        'logout': 'Log out',
                        'login': 'Log in'
                    },
                    "fashioncollection": {
                        "title": "Creations in MUSURE world",
                        "text": "Explore other creations made in MUSURE world.",
                        "search": "Search",
                        'clearFilterSort': 'Clear',
                        'applyFilterSort': 'Apply',
                        "sort": {
                            "title": "Sort",
                            "titleModal": "Sort by",
                            'createdAt': {
                                'title': 'Creation date',
                                'olderFirst': 'Older first',
                                'newestFirst': 'Newest first'
                            }
                        },
                        "filter": {
                            "title": "Filter",
                            "titleModal": "Filter by",
                            'status': {
                                'title': 'Status',
                                'minted': 'On sale',
                                'notMinted': 'Not for sale'
                            },
                            'creationType': {
                                'title': 'Creation type',
                                'sneaker': 'Sneaker',
                                'pet': 'Pet',
                                'vehicle': 'Vehicle',
                                'skin': 'Skin',
                                'wing': 'Wing',
                                'graffitti': 'Graffitti',
                                'dance': 'Dance',
                                'bazooka': 'Bazooka',
                                'airforce': 'Airforce'
                            }
                        },
                        "marketGrid": {
                            "titleFilter": "There are no creations that match your search",
                            'subtitleFilter': "Try other filters to get new results",
                            "titleEmpty": "There are no creations yet",
                        }
                    },
                    'toolkitNft': {
                        "title": "Create your own Digital Fashion in the Metaverse",
                        "title2": "Create your own fashion brand in the Metaverse",
                        "buttonDownload": "Download templates",
                        "manualDescription": "Download the templates, edit them and upload them again. If you like the result, configure and save your creation.",
                        "manualButtonUpload": "Upload Templates",
                        "automaticDescription": "Load any image from your mobile device or computer onto the 3D model. If you like the result, configure and save your creation.",
                        "automaticButtonUpload": "Upload image",
                        "tutorial": "If you have any doubts, watch the video tutorial",
                        "automatic": 'Upload JPG or PNG image',
                        "left": "Load the LEFT shoe here...",
                        "right": "Load the RIGHT shoe here...",
                        "startCreation": "Start creation",
                        "selector": "Back to selector",
                        "size": "The file must not exceed 2MB in size.",
                        "conciergeDescription": "Apply to become a Verified Creator so that MUSURE world can design your creations as you wish, ready for the Metaverse.",
                        "verify": "Verify",
                        /* debe ir un subtítulo que diga "Customize the template with your favorite software:" sobre la parte de software */
                        "softwares": {
                            "paint": "Paint",
                            "corel": "CorelDraw",
                            "gimp": "Gimp",
                            "affinity": "Affinity Photo",
                            "ilustrator": "Ilustrator",
                            "photoshop": "Photoshop"
                        },
                        "landing": { // <--
                            "description": "Choose how you want to customize the 3D models, and turn them into unique creations, made by you.",
                            "automatic": "Automatic Creation",
                            "manual": "Custom Creation",
                            "concierge": "Assisted Creation",
                            "textAuto": "Load any image on the 3D model and if you like it, make your creation.",
                            "textManual": "Download and edit the template. Load it on the 3D model and make your creation.",
                            "textPersonalize": "Ask the Musure team for a unique template design to make your creation.",
                        },
                        "viewCreate": { // <--
                            "title": "Configure your creation",
                            "acceptterms": "I have read and accept the <0>terms and conditions</0>",
                            "backcreate": "Back to create",
                        },
                        'viewConfigure': { // <--
                            'nameTitle': 'Name',
                            'usdc': "USDC",
                            'namePlaceholder': 'Name your creation',
                            'price': "Price",
                            'pricePlaceholder': "Eg 0.78",
                            'alertDescription': 'You will be able to edit it only if your creation is not sold.',
                            'saveCreation': "Save creation",
                            'nameRequired': 'Do not forget to give it a name',
                            'nameLength': 'Name must be between {{min}} and {{max}} characters long',
                            'priceLength': 'The price must be greater than {{min}}',
                            'loreTitle': 'Description',
                            'lorePlaceholder': 'Tell us a little bit about it',
                            'loreRequired': 'Describe your creation',
                            'loreLength': 'Description must be between {{min}} and {{max}} characters long',
                            'nameNotAvailable': 'Sneakers with this name already exist'
                        },
                    },
                    'footer': {
                        'copyright': "©️Copyright MUSURE world. All rights reserved.",
                        'home': 'Home',
                        'whitepaper': 'Whitepaper',
                        "blog": 'Blog',
                        "telegram": 'Telegram',
                        "discord": 'Discord',
                        'instagram': 'Instagram',
                        "twitter": 'Twitter',
                        'linkedin': 'LinkedIn',
                        'tiktok': 'TikTok',
                    },
                    'gallery': { // <--
                        'defaultBio': 'New user at MUSURE world',
                        'defaultName': 'Type your name',
                        'edit': 'Edit profile',
                        'save': 'Save',
                        'goback': 'Go Back',
                        'tooltipShare': 'Copied to clipboard',
                        'form': {
                            'usernameLength': 'Username must be between {{min}} and {{max}} characters long',
                            'usernameUsed': 'Username already used',
                            'bioPlaceholder': 'Tell us a little bit about you',
                            'socialNetUsed': 'That {{socialNet}} is already in use',
                            'socialNetPlaceholder': 'Username',
                            'bioLength': 'Bio must be between {{min}} and {{max}} characters long'
                        }
                    },
                    "detailNFT": {
                        "name": "Name",
                        "description": "Description",
                        "admin": "Admin",
                        "price": "Price",
                        "thisCreator": "this creator",
                        "unnamed": "Unnamed",
                        "buy": "Buy",
                        "transfer": "Transfer creation",
                        "mintGift": "Gift Mint",
                        "share": "Share",
                        'copied': 'Copied',
                        "profile": "View Profile",
                        "noverified": "Unverified user",
                        "moreaccessories": "More from {{creator}}",
                        "othercreations": "Other creations",
                        "seemore": "See more",
                        "play": "Your creation in AR!",
                        "textplay": "You will be able to see them in the physical world using Augmented Reality from your cell phone. <0>Use them wherever you want!", /* otro párrafo: "Live the experience by scanning the QR code and share it with whomever you like." */
                        "ar": "Use AR",
                        "explore": "Explore more utilities",
                        "playCreation": "Play with your creation in 3D Worlds",
                        "commingSoon": "Coming soon...",
                        "putonsale": "Put on sale",
                        "goNft": "Go to creation",
                        "viewAr": "View AR",
                        'link': 'Creation Link',
                        'copy': 'Copy',
                        'benefits': 'Includes Benefits',
                        'edit': 'Edit creation',
                        'delete': 'Delete creation',
                        'save': 'Save',
                        'goBack': 'Go Back',
                        'benefitsOverviewTitle': 'Benefits and Utilities',
                        'benefitsDescriptionTitle': 'Benefits and Utilities',
                        'benefitsDescriptionSubtitle': 'Add the benefits to wish to give to your followers here.',
                        'seeBenefits': 'See Benefits...',
                        'unique': 'Unique Creation',
                        'formNameDescription': {
                            'nameLength': 'Name must be between {{min}} and {{max}} characters long',
                            'nameRequired': 'Do not forget to give it a name',
                            'loreLength': 'Description must be between {{min}} and {{max}} characters long',
                            'loreRequired': 'Describe your creation',
                            'beforeMint': 'You will be able to make changes as long as your creation has not been sold.'
                        },
                        'formBenefits': {
                            'image': 'Image',
                            'titleBenefit': 'Benefit Title',
                            'description': 'Description',
                            'placeholderBenefitTitle': 'Insert here benefit name',
                            'placeholderDescriptionBenefit': 'Describe here the benefit',
                            'titleRequired': 'Title is required',
                            'descriptionRequired': 'Description required',
                            'titleLength': 'Benefit title must be between {{min}} and {{max}} characters long',
                            'descriptionLength': 'Description must be between {{min}} and {{max}} characters long',
                            'addBenefits': '+ Add benefits',
                            'saveBenefits': 'Save benefit',
                            'cancel': 'Cancel',
                            'edit': 'Edit',
                            'delete': 'Delete',
                            'imageUpload': 'Upload JPG or PNG image'
                        },
                    }
                }
            },
            es: {
                translation: {
                    'redirectInstagram': {
                        'title': 'Haz click en <0></0> y selecciona <1>"Abrir en el navegador"</1> para tener acceso completo a la web'
                    },
                    'modal': {
                        'modalErrorInputFile': {
                            'title': '¡Ups! No pudimos cargar la imagen',
                            'text': 'Hubo un error en el archivo. Revisa que sea un JPG o PNG y no supere 2MB', // <--
                            'text2': 'Puedes reducir su peso desde <0>aquí</0>',
                            'button': 'Entendido'
                        },
                        'modalTransfer': {
                            "title": "Transferir Creación",
                            "text": "Elige la persona para enviarle esta creación",
                            "inputName": "E-mail",
                            "usernotfound": "Usuario inexistente",
                            "placeholder": "creator@musure.world",
                            "transfer": "Transferir",
                            "cancel": "Cancelar"
                        },
                        'modalGift': {
                            "title": "Mintear un Regalo",
                            "description": "Mintea esta creación para regalarla",
                            "select": "Selecciona el medio",
                            "email": "E-mail",
                            "wallet": "Wallet",
                            "gift": "Regalar",
                            "cancel": "Cancelar",
                            "error": "Error en la dirección de regalo",
                            "send": "Enviado"
                        },
                        "modalDelete": {
                            "title": "¿Quieres eliminar esta creación?",
                            "text": "Una vez eliminada, no podrás recuperarla.",
                            "button1": "Eliminar",
                            "button2": "Cancelar"
                        },
                        "alertDownload": {
                            "title": "¡Has descargado las plantillas!",
                            "subtitle": "Ahora ya puedes comenzar a plasmar tu arte",
                            "text2": "Cuando termines de editar las plantillas, cárgalas en <strong>JPG, sin compresión o variación en el tamaño.</strong>",
                            "button": "Aceptar",
                            "failDownload": "¿No se descargaron las plantillas? <strong>Volver a descargar</strong>"
                        },
                        "alertTermsConditions": {
                            "title": "Términos y condiciones, y Política de privacidad",
                            "subtitle": "He leído y acepto los <0>Términos y Condiciones</0>",
                            "terms": "<0>Términos y Condiciones</0>",
                            "text1": "Ratifico la cesión de derechos de Propiedad Intelectual en favor de MUSURE.",
                            "text2": "Ratifico la gestión comercial a cargo de MUSURE, que reconocerá en favor del Creador Verificado:",
                            "subtext1": "El cincuenta por ciento (50%) del resultado obtenido de la primera comercialización.",
                            "subtext2": "Y el cinco por ciento (5%) del precio transaccionado a partir de las operaciones subsiguientes.",
                            "button": "Acepto"
                        },
                        "alertQuestions": {
                            "title": "Queremos saber tu opinión",
                            "text": "Escríbenos cualquier sugerencia o pedido que tengas. Nuestro equipo tendrá en cuenta tus comentarios para mejorar la experiencia.",
                            "label1": "Nombre",
                            "label2": "E-mail",
                            "label3": "Mensaje *",
                            "place1": "Tu Nombre",
                            "place2": "creator@musure.world",
                            "place3": "Déjanos tus comentarios",
                            "button": "Enviar",
                            "send": "Mensaje enviado",
                            'invalidEmail': 'No es un Email válido',
                            'messageRequired': 'No olvides escribir tu mensaje',
                            'messageLength': 'El mensaje debe tener entre {{min}} y {{max}} caracteres',
                        }
                    },
                    "navbar": {
                        'fashionCollection': 'Explorar',
                        'creationToolkit': 'Creation Toolkit',
                        'language': 'Idioma',
                        'es': 'Español',
                        'en': 'English',
                        'profile': 'Mi perfil',
                        'logout': 'Cerrar sesión',
                        'login': 'Iniciar sesión'
                    },
                    "fashioncollection": {
                        "title": "Creaciones en MUSURE world",
                        "text": "Explora otras creaciones hechas en MUSURE world.",
                        "search": "Buscar",
                        'clearFilterSort': 'Limpiar',
                        'applyFilterSort': 'Aplicar',
                        "sort": {
                            "title": "Ordenar",
                            "titleModal": "Ordenar por",
                            'createdAt': {
                                'title': 'Fecha de creación',
                                'olderFirst': 'Más viejo primero',
                                'newestFirst': 'Más nuevo primero'
                            }
                        },
                        "filter": {
                            "title": "Filtrar",
                            "titleModal": "Filtrar por",
                            'status': {
                                'title': 'Estado',
                                'minted': 'A la venta',
                                'notMinted': 'Sin vender'
                            },
                            'creationType': {
                                'title': 'Tipo de Creación',
                                'sneaker': 'Zapatillas',
                                'pet': 'Mascota',
                                'vehicle': 'Auto',
                                'skin': 'Vestimenta',
                                'wing': 'Alas',
                                'graffitti': 'Graffitti',
                                'dance': 'Baile',
                                'bazooka': 'Arma',
                                'airforce': 'Airforce'
                            }
                        },
                        "marketGrid": {
                            "titleFilter": "No hay creaciones que se ajusten a tu búsqueda",
                            'subtitleFilter': "Prueba otros filtros para tener nuevos resultados",
                            "titleEmpty": "No hay creaciones aún",
                        }
                    },
                    'toolkitNft': {
                        "title": "Crea tu propia Moda Digital para el Metaverso",
                        "title2": "Crea tu propia marca de Moda para el Metaverso",
                        "buttonDownload": "Descargar plantillas",
                        "manualDescription": "Descarga las plantillas, edítalas y cárgalas nuevamente. Si te gusta el resultado, configura y guarda tu creación.",
                        "manualButtonUpload": "Cargar plantillas",
                        "automaticButtonUpload": "Cargar imagen",
                        "automaticDescription": "Carga cualquier imagen desde tu dispositivo móvil u ordenador sobre el modelo 3D. Si te gusta el resultado, configura y guarda tu creación.",
                        "tutorial": "Si tienes dudas, mira el video tutorial",
                        "automatic": 'Cargar imagen JPG o PNG',
                        "left": "Carga aquí la zapatilla IZQUIERDA...",
                        "right": "Carga aquí la zapatilla DERECHA...",
                        "size": "El archivo debe pesar 2MB máximo.",
                        "startCreation": "Comenzar creación",
                        "selector": "Regresar al selector",
                        "conciergeDescription": "Solicita ser un Creador Verificado para que MUSURE world sea quien diseñe tus creaciones a gusto, listas para el Metaverso.",
                        "verify": "Verifícate",
                        /* debe ir un subtítulo que diga "Personaliza la plantilla con tu software favorito:" sobre la parte de software */
                        "softwares": {
                            "paint": "Paint",
                            "corel": "CorelDraw",
                            "gimp": "Gimp",
                            "affinity": "Affinity Photo",
                            "ilustrator": "Ilustrator",
                            "photoshop": "Photoshop"
                        },
                        "landing": {
                            "description": "Elige como quieres personalizar los modelos 3D, y conviértelos en creaciones únicas hechas por tí.",
                            "automatic": "Creación Automática",
                            "manual": "Creación Personalizada",
                            "concierge": "Creación Asistida",
                            "textAuto": "Carga cualquier imagen sobre el modelo 3D y si te gusta, haz tu creación.",
                            "textManual": "Descarga y edita la plantilla. Cárgala sobre el modelo 3D y haz tu creación.",
                            "textPersonalize": "Solicita al equipo MUSURE, un diseño único de la plantilla para hacer tu creación.",
                        },
                        "viewCreate": {
                            "title": "Configura tu creación",
                            "acceptterms": "He leído y acepto los <0>términos y condiciones</0>",
                            "backcreate": "Volver a crear",
                        },
                        'viewConfigure': {
                            'nameTitle': 'Nombre',
                            'namePlaceholder': 'Dale un nombre a tu creación',
                            'usdc': "USDC",
                            'alertDescription': "Podrás editar estos datos más adelante, siempre que no esté a la venta.",
                            'price': "Precio",
                            'pricePlaceholder': "Ej 0.78",
                            'saveCreation': "Guardar creación",
                            'nameRequired': 'No te olvides de darle un nombre',
                            'nameLength': 'El nombre debe tener entre {{min}} y {{max}} caracteres',
                            'priceLength': 'El precio debe ser mayor a {{min}}',
                            'loreTitle': 'Descripción',
                            'lorePlaceholder': 'Cuéntanos un poco sobre ella',
                            'loreRequired': 'Describe cómo es tu creación',
                            'loreLength': 'La descripción debe tener entre {{min}} y {{max}} caracteres',
                            'nameNotAvailable': 'Ya existen Sneakers con ese nombre, prueba otro.'
                        }
                    },
                    'footer': {
                        'copyright': "©️Copyright MUSURE world. Todos los derechos reservados.",
                        'home': 'Home',
                        'whitepaper': 'Whitepaper',
                        "blog": 'Blog',
                        "telegram": 'Telegram',
                        "discord": 'Discord',
                        'instagram': 'Instagram',
                        "twitter": 'Twitter',
                        'linkedin': 'LinkedIn',
                        'tiktok': 'TikTok',
                    },
                    'gallery': { // <--
                        'defaultBio': 'Nuevo usuario en MUSURE world',
                        'defaultName': 'Escribe tu nombre',
                        'edit': 'Editar perfil',
                        'save': 'Guardar',
                        'goback': 'Atrás',
                        'tooltipShare': 'Copiado al portapapeles',
                        'form': {
                            'usernameLength': 'El nombre de usuario debe tener entre {{min}} y {{max}} caracteres',
                            'usernameUsed': 'Ese nombre ya esta en uso',
                            'bioPlaceholder': 'Cuéntanos un poco sobre tí',
                            'socialNetUsed': 'Ese {{socialNet}} esta en uso',
                            'socialNetPlaceholder': 'Nombre de usuario',
                            'bioLength': 'La biografía debe tener entre {{min}} y {{max}} caracteres'
                        }
                    },
                    "detailNFT": {
                        "name": "Nombre",
                        "description": "Descripción",
                        "admin": "Admin",
                        "price": "Precio",
                        "unnamed": "Sin nombre",
                        "thisCreator": "este Creador",
                        "buy": "Comprar",
                        "share": "Compartir",
                        'copied': 'Copiado',
                        "transfer": "Transferir creación",
                        "mintGift": "Mintear Regalo",
                        "profile": "Ver Perfil",
                        "noverified": "Usuario no verificado",
                        "moreaccessories": "Más de {{creator}}",
                        "othercreations": "Otras creaciones",
                        "seemore": "Ver más",
                        "play": "¡Tu creación en AR!",
                        "textplay": "Podrás verlas en el mundo físico usando Realidad Aumentada desde tu celular. <0>¡Úsalas donde quieras!</0>", /* otro párrafo: "Vive la experiencia escaneando el código QR y compártelo con quién quieras." */
                        "ar": "Usar AR",
                        "explore": "Explora más utilidades",
                        "playCreation": "Juega con tu creación en Mundos 3D",
                        "commingSoon": "Muy pronto...",
                        "putonsale": "Poner a la venta",
                        "goNft": "Ir a la creación",
                        "viewAr": "Ver AR",
                        'link': 'Link de creación',
                        'copy': 'Copiar',
                        'benefits': 'Incluye Beneficios',
                        'edit': 'Editar datos',
                        'delete': 'Eliminar creación',
                        'save': 'Guardar',
                        'goBack': 'Volver',
                        'unique': 'Creación Unica',
                        'benefitsOverviewTitle': 'Beneficios y Utilidades',
                        'benefitsDescriptionTitle': 'Beneficios y Utilidades',
                        'benefitsDescriptionSubtitle': 'Agrega aqui los beneficios que desees regalar a tus seguidores.',
                        'seeBenefits': 'Ver Beneficios...',
                        'formNameDescription': {
                            'nameLength': 'El nombre debe tener entre {{min}} y {{max}} caracteres',
                            'nameRequired': 'No te olvides de darle un nombre',
                            'loreLength': 'La decripción debe tener entre {{min}} y {{max}} caracteres',
                            'loreRequired': 'Describe cómo es tu creación',
                            'beforeMint': 'Podrás hacer cambios siempre que tu creación no se haya vendido.'
                        },
                        'formBenefits': {
                            'image': 'Imagen',
                            'titleBenefit': 'Titulo Beneficio',
                            'description': 'Descripción',
                            'placeholderBenefitTitle': 'Ingrese aca el nombre del beneficio',
                            'placeholderDescriptionBenefit': 'Describa aquí el beneficio',
                            'titleRequired': 'El titulo del beneficio es obligatorio',
                            'descriptionRequired': 'La descripción es obligatoria',
                            'titleLength': 'El titulo del beneficio debe tener entre {{min}} y {{max}} caracteres',
                            'descriptionLength': 'La descripción debe tener entre {{min}} y {{max}} caracteres',
                            'addBenefits': '+ Agregar beneficios',
                            'saveBenefits': 'Guardar beneficio',
                            'cancel': 'Cancelar',
                            'edit': 'Editar',
                            'delete': 'Eliminar',
                            'imageUpload': 'Cargar imagen JPG o PNG'
                        },
                    }
                }
            }
        },
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
            saveMissing: true
        }
    });