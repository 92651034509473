//GENERAL
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

//UTILS
import { Icon } from '@iconify/react';
import musureLogo from '../../assets/images/logos/logo_musure_white.svg'

export default function CardCreatorDetailNFT({creator, nft, place}) {

    const history = useHistory()
    const { t } = useTranslation()
  
    return (
        <div className={`w-full h-[53px] sm:h-[89px] flex justify-center py-1 lg:py-2 items-center rounded-xl bg-[#500065] shadow-around-light text-white ${place === 'desktop'? 'hidden lg:flex' : 'flex lg:hidden'}`}>
            <div className='flex w-11/12 h-11/12 justify-between gap-x-3 items-center'>

                <div className='flex items-center justify-start w-9/12 pt-1 overflow-x-hidden relative'>

                    <img src={creator.image || musureLogo} alt="" className={`h-[32px] w-[32px] sm:w-[65px] sm:h-[65px] rounded-lg object-cover ${nft?.benefits?.length > 0 && 'border-2 border-[#E9C956]'}`} />

                    <div className="flex flex-col relative w-full justify-start items-start ml-4">

                        <div className="w-full flex justify-start items-center relative gap-x-1.5 text-white">
                            <h4 className="font-semibold text-[14px] sm:text-[24px]">{creator.username || t("detailNFT.unnamed")}</h4>
                            {creator.isValidated && 
                            <div className='flex justify-center items-center'>
                                <span className='bg-white w-[9px] sm:w-[20px] h-[9px] sm:h-[20px] rounded-full absolute z-10'/>
                                <Icon icon={"ic:round-verified"} className="text-[15px] sm:text-[26px] text-[#31B5FF] z-20" />
                            </div> 
                            }
                        </div>
                        {/* <h5 className='text-[12px] font-light sm:text-[22px]'>{creator.username && `${creator._id.slice(0, 12)}...`}</h5> */}

                    </div>

                </div>

                <button
                    className='w-3/12 text-[#E671ED] text-[10px] sm:text-[14px] font-semibold cursor-pointer'
                    onClick={() => history.push(`/gallery/${creator._id}`)}
                >
                    {t("detailNFT.profile")}
                </button>

            </div>
        </div>
    )
}
