import { Icon } from "@iconify/react";
import { useRef } from "react";
import { ButtonSecondary, buttonUpload } from "../../utils/constants/classes";

export default function ButtonDropdown({ setOpenDrop, openDrop, title, options }) {

    const inputBtn = useRef()

    const btnUpload = document.getElementById('automatic')

    
    return (
        <div className={`${openDrop && 'bg-darkpurple rounded-2xl'} w-full`}>
            
            <button 
                onClick={()=>setOpenDrop(!openDrop)} 
                className={`${openDrop ? ButtonSecondary : buttonUpload} w-full`}
                type={"button"}
                >
                {title}
            </button>

            

            <div className={`${openDrop ? 'flex flex-col' : 'hidden'} w-full py-2 gap-y-2 justify-around items-center rounded-b-2xl bg-darkpurple`}>
                {
                    options.map((opt)=>{ 
                        return (
                            <div className="w-[85%] flex items-center ">

                                <input
                                    id={opt.id}
                                    ref={inputBtn}
                                    disabled={opt.disabled}
                                    type='file'
                                    size={'1000000'}
                                    accept="image/png, image/jpeg"
                                    className={`hidden`}
                                    onChange={(e)=>opt.onChange(e)}
                                    onClick={()=>inputBtn.current.value = null}
                                />

                                <div className={`w-full flex items-center justify-between py-2 relative ${opt.disabled? 'cursor-wait': 'cursor-pointer'} `}>
                                    
                                    <div className="w-11/12 flex items-center justify-start">
                                        {
                                        opt.processing?
                                            <Icon icon='line-md:loading-loop' className='animate-spin text-pink text-[22px] mr-4'/>
                                        :
                                            <Icon icon={opt.icon} className='text-[22px] text-white mr-2 sm:mr-4'/>
                                        }

                                        <span className={`text-white ${opt.name? 'text-opacity-90': 'text-opacity-40'} truncate  text-[12px] sm:text-[14px]`}>
                                            {opt.name ? opt.name : opt.placeholder}
                                        </span>
                                    </div>
                                
                                    <Icon 
                                    icon='bi:x' 
                                    className={`text-[30px] w-1/12 text-white transition-all duration-500 ${opt.reduxImg || opt.processing? "rotate-0": "rotate-45"}`}
                                    onClick={opt.reduxImg? ()=>opt.onChangeRemove() : ()=>inputBtn.current.click()}
                                    />

                                </div>

                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
