import React from 'react';
import { errorSpan, inputClass, inputErrorClass } from '../../utils/constants/classes';
import BasicTooltip from '../BasicTooltip/BasicTooltip';

export default function BasicInput({inputAttributes, reference, title, tooltip, className, register, registerName, registerObj, errors, inputClassName, inputErrorClassName, classTitle,error2}) {
    
    return (
        <div className={`flex flex-col items-start justify-start w-full ${className}`}>

            {/*HEADER*/}
            <div className='flex items-center justify-start'>
                <h4 className={`${classTitle}`}>{title}</h4>
                { tooltip && <BasicTooltip message={tooltip}/> }
            </div>
            
            {/*INPUT*/}
            <div className='flex justify-center items-center relative w-full'>
                <input
                {...inputAttributes}
                className={`${errors[registerName] ? `${inputErrorClass} ${inputErrorClassName}` : `${inputClass} ${inputClassName}`}`}
                autoComplete="off"
                {...register(registerName, {...registerObj})}
                
                />

                {
                    reference &&
                    <h5 className='absolute leading-5 right-[16px] bottom-[14px] text-white'>{reference}</h5>
                }
                
            </div>

            {/*ERROR*/}
            { errors[registerName] && <span className={`${errorSpan} ${error2 &'text-black'}`}> {errors[registerName].message} </span> }

        </div>
    )
}
