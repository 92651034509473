//GENERAL
import { useTranslation } from "react-i18next"
import { useState } from "react"
import Modal from 'react-modal'
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
//COMPONENTS

//UTILS
import { containerStructureGeneral, backgroundGradient, structureViewsPanel, buttonUpload, modalOverlay, modalContainer } from "../../utils/constants/classes"
import AlertDownload from "../../modals/AlertDownload/AlertDownload"
import IconDownload from '../../assets/download/DownloadIcon.svg'
import CardsNFTs from "../../components/CardsNFTs/CardsNFTs"
import CreationType from "../../components/CreationType/CreationType"
import ModelViewer from "../../components/ModelViewer/ModelViewer"

import { Icon } from "@iconify/react"

export default function NFTCreation() {

    const [modal, setModal] = useState(false)
    const [openDrop, setOpenDrop] = useState(false)
    const [flagViewer, setFlagViewer] = useState(false)

    const history = useHistory()
    const textureSneaker = useSelector(state => state.toolkit.textureSneaker)
    const creationType = useSelector(state => state.toolkit.type)


    const { t } = useTranslation()

    let modalDownload = {
        img: IconDownload,
        title: t("modal.alertDownload.title"),
        subtitle: t("modal.alertDownload.subtitle"),
        text1: t("modal.alertDownload.text1"),
        text2: t("modal.alertDownload.text2"),
        button: t("modal.alertDownload.button"),
        failDownload: "modal.alertDownload.failDownload",
        icon2: "clarity:warning-line",
        type: creationType,
        close: () => setModal(false)
    }

    return (
        <div className={`${containerStructureGeneral}  ${backgroundGradient}`}>

            <div className={`${structureViewsPanel}`}>

                {modal &&
                    <Modal
                        style={modalOverlay}
                        className={`${modalContainer} w-[100vw] h-10/12 max-h-[560px] sm:w-[534px] sm:h-[609px]`}
                        isOpen={modal}
                        onRequestClose={() => setModal(false)}
                    >
                        <AlertDownload modalObj={modalDownload} />
                    </Modal>
                }

                <div className="flex flex-col justify-center items-center gap-y-10  w-full h-full relative">

                    <div className="flex flex-col-reverse lg:flex-row justify-around  w-full h-full relative text-white gap-x-8">

                        <div className="w-full h-auto lg:h-[560px] lg:w-[52%] xl:w-[45%]">
                            <CreationType
                                setOpenDrop={setOpenDrop}
                                openDrop={openDrop}
                                setModal={setModal}
                                flagViewer={flagViewer}
                                setFlagViewer={setFlagViewer}
                            />
                        </div>

                        {/* VIEWER  */}
                        <div className="w-full flex flex-col justify-between  lg:w-[48%] xl:w-[55%]">
                            <div className="w-full flex flex-col justify-between h-[265px]  sm:min-h-[471px] xl:min-h-[602px] ">
                                <ModelViewer
                                    flagViewer={flagViewer}
                                    textureSneaker={textureSneaker}

                                    modelProps={{
                                        'camera-controls': true,
                                        'interaction-prompt': 'auto'
                                    }}
                                />
                            </div>

                            {openDrop || textureSneaker ?
                                <button
                                    disabled={!textureSneaker}
                                    className={`${buttonUpload} w-full hidden lg:flex`}
                                    onClick={() => history.push('/configure')}
                                >
                                    {t("toolkitNft.startCreation")}
                                </button> : null
                            }
                        </div>

                        {/* BUTTON BACK MOBILE */}
                        <div>
                            <button
                                onClick={() => history.push('/')}
                                className="w-auto flex justify-start items-center gap-x-2 pb-7 text-pinky lg:hidden"
                            >
                                <Icon icon={"akar-icons:chevron-down"} className="text-[16px] rotate-90" />
                                <span>{t("toolkitNft.selector")}</span>
                            </button>
                        </div>

                    </div>

                    <CardsNFTs />

                </div>
            </div>
        </div>
    )
}
