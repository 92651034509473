import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import musureLogo from "../../assets/images/logos/musureNavbar.png";
import { buttonUpload } from "../../utils/constants/classes";
import { Login, Logout } from "../../utils/functions/logic/logInOut";
import DropdownLangNavbar from "../DropdownLangNavbar/DropdownLangNavbar";
import HamburguerButton from "../HamburguerButton/HamburguerButton";
import ItemNavbar from "../ItemNavbar/ItemNavbar";

export default function Navbar() {

    const [openHamburger, setOpenHamburger] = useState(false)
    const user = useSelector(state=> state.general.user)
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    
    let onClickItems =  (site)=>{
        history.push(site)
        setOpenHamburger(false)
    }

    const options = [
        {
            type:'fashionCollection',
            icon: "ph:squares-four",
            text: t('navbar.fashionCollection'),
            redirect:'/fashioncollections'
        },
        {
            type:'toolkit',
            icon: "tabler:tools",
            text: t('navbar.creationToolkit'),
            redirect:'/'
        },
        {
            type: 'language',
            icon: 'iconoir:chat-bubble-translate',
            text: t('navbar.language'),
            languageOptions: [
                {
                    text: t('navbar.es'),
                    type: 'es'
                }, 
                {
                    text: t('navbar.en'),
                    type: 'en'
                }
            ]
        },
        {
            type:'profile',
            icon: "bx:user",
            text: t('navbar.profile'),
            redirect:`/gallery/${user?._id}`
        },
    ]

    const optionLogout = {icon: 'bx:bx-log-out', text: t('navbar.logout')}
    const classItemBaseDropdown = `flex items-center justify-start mt-4 w-full sm:w-auto ${openHamburger ? 'opacity-1 h-auto' : 'opacity-0 h-0 hidden'} `
    const classItemTransAndColors = `cursor-pointer hover:text-pink transition-all ease-linear duration-150 select-none`

    return (
        <div className="w-full flex flex-col items-center justify-center shadow-around z-[999]">

            {/*NAVBAR FIXED*/}
            <nav className={`h-[62px] lg:h-[86px] w-full flex items-center justify-center bg-[#0F0B16] z-[999] fixed top-0 shadow-around`}>
                <div className="w-[89%] sm:w-[83.33%] lg:w-[91%] flex items-center justify-center sm:justify-between relative text-white">

                    <a className="flex   justify-center items-center sm:justify-start  h-1/2 w-8/12" href="https://musure.world">
                        <img src={musureLogo} alt="musure-logo" className="w-auto h-[25px] sm:h-[30px]  lg:h-[42px] focus:outline-none"/>
                    </a> 

                    <HamburguerButton 
                    containerClassname={"flex flex-col items-center justify-center h-[30px] w-[24px] absolute right-0 lg:hidden cursor-pointer"}
                    openHamburger={openHamburger} 
                    setOpenHamburger={setOpenHamburger}/>

                    <div className="hidden lg:flex items-center justify-center gap-x-12">
                        <ItemNavbar 
                        classNameItem={`hidden lg:flex items-center justify-start w-auto ${classItemTransAndColors} ${location.pathname === options[0].redirect && 'text-pink '}`}
                        opt={options[0]}
                        onClickItem={()=>onClickItems(options[0].redirect)}
                        />
                        <ItemNavbar 
                        classNameItem={`items-center justify-start w-auto lg:flex hidden ${classItemTransAndColors} ${location.pathname === options[1].redirect && 'text-pink '}`}
                        opt={options[1]}
                        onClickItem={()=>onClickItems(options[1].redirect)}
                        />
                        <div 
                        className={`hidden lg:flex items-end justify-center gap-x-1 ${classItemTransAndColors} group`} 
                        onMouseEnter={()=>setOpenHamburger(!openHamburger)}
                        >
                            <Icon icon='bx:user' className=" border-[1.5px] border-white rounded-sm text-[24px] group-hover:border-pink"/>
                            <Icon icon='akar-icons:chevron-down' className={`${openHamburger? 'rotate-180' : 'rotate-0'} transition-all ease-linear duration-150`}/>
                        </div>
                    </div>

                </div>
            </nav>

            {/*NAVBAR DROPDOWN*/}
            <div 
            className={`bg-[#0F0B16]  w-full flex items-center justify-center fixed top-[62px] lg:top-[86px] lg:w-[315px] lg:right-0 z-[999] text-white shadow-around transition-all ease-linear duration-150 rounded-b-2xl pb-8 ${openHamburger ? 'h-auto flex' : 'h-0 hidden invisible'}`}
            onMouseLeave={()=>setOpenHamburger(false)}
            >
                    <div className={`flex flex-col items-center justify-start w-[200px] sm:flex-row sm:w-[86%] sm:max-w-[640px] sm:flex-wrap sm:justify-center sm:items-start sm:gap-x-4 lg:flex-col lg:items-start lg:justify-start lg:gap-x-0 lg:w-[200px]`}>
                    {
                        options.map(opt=>{

                            if(opt.type === 'language'){
                                return <DropdownLangNavbar opt={opt} openHamburger={openHamburger} classColorTrans={classItemTransAndColors}/>
                            }
                            if(opt.type === 'profile'){
                                return <ItemNavbar classNameItem = {`${classItemBaseDropdown} ${!user ? 'hidden':'flex'} ${classItemTransAndColors}`} opt={opt} onClickItem={()=>onClickItems(opt.redirect)}/>
                            }
                            else{
                                return <ItemNavbar classNameItem = {`${classItemBaseDropdown} flex lg:hidden ${classItemTransAndColors}`} opt={opt} onClickItem={()=>onClickItems(opt.redirect)}/>
                            }
                        })
                    }
                    {
                        user?
                            <ItemNavbar 
                            classNameItem={`${classItemBaseDropdown} ${classItemTransAndColors}`}
                            opt={optionLogout} 
                            onClickItem={()=>{
                                Logout(dispatch, setOpenHamburger)
                                if(location.pathname.includes('gallery')){
                                    history.push('/')
                                }
                            }}/>
                        :
                            <button className={`${buttonUpload} w-full mt-6 ${openHamburger ? 'opacity-1 flex' : 'opacity-0 hidden'} `}
                            onClick={()=>Login(setOpenHamburger)}>
                                {t('navbar.login')}
                            </button>
                    }
                    </div>
            </div>

        </div>
    )
}
