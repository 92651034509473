import { useState, useEffect } from 'react';
import { buttonDownload, ButtonSecondary } from '../../utils/constants/classes';

import { downloadImage } from '../../utils/functions/downloadImg';

export default function ButtonDownload({ setModal, title, type }) {



    //TRAER EL MODAL ACA
    const [buttonSecondaryFlag, setButtonSecondaryFlag] = useState(false)

    return (
        <a
            onClick={(e) => {
                downloadImage(e)
                setModal(true)
                setButtonSecondaryFlag(true)
            }}
            target="_blank"
            href={type === "Sneaker" ? "https://musure.mypinata.cloud/ipfs/QmTA1KMKHcCFzYAotAvkhzvSMEeH8hXvGPNRD6wuZLBLtJ" : type === "Airforce" ? "https://musure.mypinata.cloud/ipfs/QmTiAhRQs5vov9ZyaSQ3ZzCnM8VapH3JMYBDndncEKXGTe" : "https://musure.mypinata.cloud/ipfs/QmWLcXyqyq9huNHCzndXEidVfJko2mLXEdCTGto4mP8m9t"}
            download="img"
            className={`${buttonSecondaryFlag ? ButtonSecondary : buttonDownload} h-[43px] w-full mb-4`}
        >
            {title}
        </a>
    )
}
