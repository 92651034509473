import React from 'react'

export default function BasicButton({onClick, title, className}) {

    return (
        <button 
            className={className} 
            onClick={()=>onClick()}
        >
            {title}
        </button>
    )
}
