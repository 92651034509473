import { Redirect, useParams } from 'react-router-dom'

export default function QRHandler() {

  const params = useParams();
  const id = params.id
  
  return (
    <Redirect to={`/detailnft/${id}/arcode`} />
  )
  
}
