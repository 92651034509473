//GENERAL
import { t } from 'i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'

//COMPONENTS
import FormBenefits from '../FormBenefits/FormBenefits'

//UTILS
import { ButtonSecondary } from '../../utils/constants/classes'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend'

export default function AdderBenefits({nft, setUpdated, updated}) {

    const [addBenefit, setAddBenefit] = useState(false)
    const [newImageBenefit, setNewImageBenefit] = useState();
    const user = useSelector(state=> state.general.user)
    
    const onSubmit = async(data) => {
        let obj = {
            idNFT: nft._id,
            benefit: {
                overview: data.overview,
                description: data.description,
                image: newImageBenefit
            }
        }
        await fetchBackend('POST', '/nft/benefit', obj, user )
        setAddBenefit(false)
        setUpdated(!updated)
    };

    return (
        user?.isValidated && user?.firebaseID === nft.createdBy.firebaseID ?
            <div className={`w-full flex flex-col justify-start items-start mb-8 ${addBenefit? '' : 'sm:flex sm:items-start'}`}>
                {
                    nft.benefits.length === 0 && 
                    <div className='w-full flex flex-col items-start justify-start gap-y-2 mb-6'>
                        <h3 className='text-[16px] sm:text-[18px] font-bold'>{t('detailNFT.benefitsDescriptionTitle')}</h3>
                        <h4 className={`text-[14px] sm:text-[16px] ${addBenefit && 'hidden'}`}>{t('detailNFT.benefitsDescriptionSubtitle')}</h4>
                    </div>
                }
                {
                    !addBenefit?
                        <button className={`${ButtonSecondary} w-full lg:w-8/12`} onClick={()=>setAddBenefit(true)}>
                            {t('detailNFT.formBenefits.addBenefits')}
                        </button>
                    :
                        <FormBenefits closeForm={setAddBenefit} onSubmit={onSubmit} image={newImageBenefit} setImage={setNewImageBenefit}/>
                }
            </div>
        :
            null
    )
}
