import textureLeft from '../../../assets/images/Left_BaseColor.2k.png'
import vehicleTexture from '../../../assets/images/VehicleTemplate.png'
import airforceTemplate from '../../../assets/images/AirforceTemplate.png'
import { handleTexturePignata } from './handleTexture';

export const changeTexture = async (ref, channel, textures, creationType) => {

    try {

        let materialLeft = ref && ref.model.materials[0];

        let materialAiforce = ref && ref.model.materials[1]

        if (!textures.textureSneaker) {

            switch (creationType) {
                case "Airforce":
                    const texture1 = await ref.createTexture(airforceTemplate);
                    materialLeft.pbrMetallicRoughness[channel].setTexture(texture1);
                    break;

                case "Sneaker":
                    const texture2 = await ref.createTexture(textureLeft);
                    materialLeft.pbrMetallicRoughness[channel].setTexture(texture2);
                    break;

                case "Vehicle":
                    const texture3 = await ref.createTexture(vehicleTexture);
                    materialLeft.pbrMetallicRoughness[channel].setTexture(texture3);
                    break;
            }
        } else {
            const texture = await ref.createTexture(textures.textureSneaker);
            if (channel.includes('base') || channel.includes('metallic')) {
                if(creationType !== "Airforce"){

                    materialLeft.pbrMetallicRoughness[channel].setTexture(texture);
                }else{
                    materialLeft.pbrMetallicRoughness[channel].setTexture(texture);
                }
            }
        }


    } catch (err) {
        console.log(err)
    }
}


export async function CreatePoster() {
    try {

        let ref;
        ref = document.querySelector("model-viewer#api-frame");
        const photoPoster = await ref.toBlob({ name: "poster", mimeType: 'image/png', idealAspect: false })
        const img = await handleTexturePignata(photoPoster)
        return img

    } catch (err) {
        console.log(err)
    }
}