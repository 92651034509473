import { SET_FLAG_CHARGE_FILES, GET_ALL_CREATIONS, SET_TYPES_NFTS, SET_USER, GET_USER_CREATIONS } from "./actionTypes"
import { newServerUrl } from "../../config/configServer"
import { fetchBackend } from "../../utils/functions/logic/fetchBackend"

export const setFlagChargeFiles = (payload) => {
    return {
        type: SET_FLAG_CHARGE_FILES,
        payload
    }
}

export function getItemsMarket(params) {
   
    try {
        if (Object.entries(params).length !== 0) {
            return async (dispatch) => {
                const items = await fetchBackend('GET', `${newServerUrl}/NFT/filterSort?name=${params.name || null}&filterMinted=${params.status || null}&filterType=${params.creationType || null}&sortCreatedAt=${params.sortCreatedAt || null}`)
                dispatch({ type: GET_ALL_CREATIONS, payload: items.data.results })
            }
        }
        return async (dispatch) => {
            const items = await fetchBackend('GET', `${newServerUrl}/NFT/filterSort`)
            dispatch({ type: GET_ALL_CREATIONS, payload: items.data.results })
        }

    } catch (err) {
        console.log(err)
    }
}

export const setTypesNFTs = (payload) => {
    return {
        type: SET_TYPES_NFTS,
        payload: payload
    }
}

export const setUser = (data) => {
    try {
        
        if(data){
            return async (dispatch) => {
                let obj = {
                    email: data.email,
                    firebaseID: data.uid
                }
                const userData = await fetchBackend('POST',`${newServerUrl}/user`, obj)
                dispatch({ type: SET_USER, payload: userData.data.user })
            }
        }
        else{
            return async (dispatch) => {
                dispatch({ type: SET_USER, payload: null })
            }
        }

    } catch (err) {
        console.log(err)
    }
}

export function getItemsUser(userID, params) {
    try {
        if (Object.entries(params).length !== 0) {
            return async (dispatch) => {
                const items = await fetchBackend('GET', `${newServerUrl}/NFT/filterSort?userID=${userID}&name=${params.name || null}&filterMinted=${params.status || null}&filterType=${params.creationType || null}&sortCreatedAt=${params.sortCreatedAt || null}`)
                
                dispatch({ type: GET_USER_CREATIONS, payload: items.data.results })
            }
        }
        return async (dispatch) => {
            const items = await fetchBackend('GET', `${newServerUrl}/NFT/filterSort?userID=${userID}`)
            
            dispatch({ type: GET_USER_CREATIONS, payload: items.data.results })
        }

    } catch (err) {
        console.log(err)
    }
}