
export default async function searchCreation(e, history, location, place) {
    //FALTA ARMAR ENDPOINT
    e.preventDefault()
    let params = Object.fromEntries(new URLSearchParams(location.search))
   
    if(place === 'fashioncollections'){
        history.push(`/fashioncollections?name=${e.target[0].value || null}&creationType=${params.creationType || null}&status=${params.status || null}&sortCreatedAt=${params.sortCreatedAt || null}`)
    }
    if(place === 'gallery'){
        //CAMBIAR ESTO
        history.push(`/gallery/${location.pathname.slice(9,33)}/?name=${e.target[0].value || null}&creationType=${params.creationType || null}&status=${params.status || null}&sortCreatedAt=${params.sortCreatedAt || null}`)
    }
}