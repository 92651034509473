//GENERAL
import { useTranslation } from 'react-i18next'


//COMPONENTS

//UTILS
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { urlBase } from "../../config/configServer";
import { Icon } from '@iconify/react'
import { ButtonSecondary } from '../../utils/constants/classes'
import { Trans } from 'react-i18next';


export default function ShareLinkQr({ idNft, nftQR, handle8thWall, place }) {

    const { t } = useTranslation()
  
    return (
        <div className={`w-full h-full flex justify-center items-center bg-[#000000]/50 shadow-around rounded-2xl py-6  ${place === 'desktop' ? '' : 'lg:h-[275px] xl:h-[225px]'}`}>
            <div className={`w-10/12 h-8/12 sm:w-11/12 flex flex-col sm:flex-row sm:justify-between justify-center ${place === 'desktop' ? 'lg:flex-col lg:justify-start lg:gap-x-0': ''} lg:px-3 items-center sm:gap-x-12`}>

                <img src={nftQR} alt='nft-QR' className='w-full bg-white p-4 rounded-2xl max-w-[280px] sm:w-[175px] sm:h-[175px] mb-6 sm:mb-0' />
                
                <div className='w-full flex flex-col items-start justify-start gap-y-3 text-[14px] lg:items-center'>

                    <h2 className='text-[21px] font-semibold text-[#E671ED] mb-2 lg:mt-2'>{t("detailNFT.play")}</h2>

                    <div className='inline'>
                        <Trans i18nKey={t("detailNFT.textplay")} components={[<strong className='font-bold'></strong>]} />
                    </div>

                    <div className='flex w-full justify-center items-center sm:justify-start sm:gap-x-3 mt-6 sm:mt-4 '>

                        <button onClick={() => handle8thWall()} className={`${ButtonSecondary} w-full sm:w-7/12 lg:w-full text-[16px] gap-x-2 font-semibold text-[#E671ED]`}>
                            <Icon icon={"ion:logo-apple-ar"} className="text-[25px]" />
                            {t("detailNFT.ar")}
                        </button>

                        <CopyToClipboard text={`${urlBase}/detailnft/${idNft}`}>
                            <button className={`${ButtonSecondary} hidden sm:flex font-semibold text-[#E671ED] lg:hidden`}>
                                <Icon icon={"ant-design:share-alt-outlined"} className={`text-[25px] font-bold`} />
                            </button>
                        </CopyToClipboard>

                    </div>

                </div>
            </div>
        </div>
    )
}
