import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonSecondary } from '../../utils/constants/classes';
import BasicInput from '../BasicInput/BasicInput';
import BasicTextArea from '../BasicTextArea/BasicTextArea';
import BasicInputImage from '../BasicInputImage/BasicInputImage';
import { useState } from 'react';
import { handleTexturePignata } from '../../utils/functions/logic/handleTexture';
import { useEffect } from 'react';

export default function FormBenefits({closeForm, defaultValues, onSubmit, image, setImage}) {

    const {t} = useTranslation();
    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 500, defaultValues: defaultValues || null });

    useEffect(()=>{
        if(defaultValues){
            setImage(defaultValues.image)
        }
    },[])

    const uploadImageBenefit = async(e) => {
        let hash = await handleTexturePignata(e.target.files[0])
        setImage(`https://musure.mypinata.cloud/ipfs/${hash.data.IpfsHash}`)
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col items-center justify-start gap-y-4 '>
            <div className='w-full flex flex-col items-start justify-start gap-y-4 sm:flex-row sm:gap-x-6'>
                <div className='w-full sm:max-w-[312px] flex flex-col items-start justify-start'>
                    <h4 className='text-[14px]  font-semibold mb-1'>{t('detailNFT.formBenefits.image')}</h4>
                    <BasicInputImage
                    srcImage={image}
                    classNameImgContainer='w-full relative  border border-white rounded-[10px] max-h-[155px] sm:max-h-[175px] min-h-[155px] sm:min-h-[175px] bg-black bg-opacity-30'
                    classnameImage={'w-full h-full object-cover object-center rounded-[10px]'}
                    idImage='newImageBenefit'
                    hoverImageClass={'top-[0] bottom-[0] m-auto left-[0] right-[0]'}
                    onChange={uploadImageBenefit}
                    />
                </div>

                <div className='w-full flex flex-col items-start justify-start gap-y-1'>

                    <div className='w-full flex flex-col items-start justify-start '>
                        <h4 className='text-[14px]  font-semibold'>{t('detailNFT.formBenefits.titleBenefit')}</h4>
                        <BasicInput
                        errors={errors}
                        register={register}
                        registerName='overview'
                        className='text-[14px] sm:text-[15px] lg:text-[16px]'
                        inputClassName={`px-[9px] py-[7px] text-[14px] sm:text-[15px] lg:text-[16px]`}
                        classTitle=' mb-1'
                        inputAttributes={{
                            placeholder: t('detailNFT.formBenefits.placeholderBenefitTitle') ,
                            type: 'text',
                        }}
                        registerObj={{
                            required: {
                                value: true,
                                message: t('detailNFT.formBenefits.titleRequired')
                            },
                            maxLength: {
                                value: 60,
                                message: t('detailNFT.formBenefits.titleLength', { min: 3, max: 60 })
                            },
                            minLength: {
                                value: 3,
                                message: t('detailNFT.formBenefits.titleLength', { min: 3, max: 60 })
                            }
                        }}
                        />
                    </div>

                    <div className='w-full flex flex-col items-start justify-start'>
                        <h4 className='text-[14px]  font-semibold'>{t('detailNFT.formBenefits.description')}</h4>
                        <BasicTextArea
                            watch={watch}
                            errors={errors}
                            register={register}
                            registerName='description'
                            textareaAttributes={{
                                maxLength: 200,
                                placeholder: t('detailNFT.formBenefits.placeholderDescriptionBenefit')
                            }}
                            registerObj={{
                                required: {
                                    value: true,
                                    message: t('detailNFT.formBenefits.descriptionRequired')
                                },
                                maxLength: {
                                    value: 200,
                                    message: t('detailNFT.formBenefits.descriptionLength', { min: 3, max: 200 })
                                },
                                minLength: {
                                    value: 3,
                                    message: t('detailNFT.formBenefits.descriptionLength', { min: 3, max: 200 })
                                }
                            }}
                            classNameTextArea={'mb-0 h-[180px] sm:h-[100px] text-[14px] sm:text-[15px] lg:text-[16px]'}
                            className=''
                        />
                    </div>

                    
                </div>
            </div>

            <div className='w-full flex flex-col items-center justify-start gap-y-4 sm:flex-row-reverse'>
                <button className={`${ButtonSecondary} w-full sm:w-6/12`} type='submit'>
                    {t('detailNFT.formBenefits.saveBenefits')}
                </button>

                <button className={`text-[#E671ED] font-semibold sm:w-6/12`} onClick={()=>closeForm(false)}>
                    {t('detailNFT.formBenefits.cancel')}
                </button>
            </div>

        </form>
    )
}
