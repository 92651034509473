//GENERAL
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal'
import { Icon } from '@iconify/react'

//COMPONENTS
import AlertConfirm from '../../modals/AlertConfirm/AlertConfirm';

//UTILS
import { modalContainer, modalOverlay } from '../../utils/constants/classes';
import deleteSvg from '../../assets/images/icons/delete.svg'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend';
import svgTransfer from '../../assets/images/icons/transfer.svg'
import AlertTransfer from '../../modals/AlertTransfer/AlertTransfer';
import AlertGift from '../../modals/AlertGift/AlertGift';

export default function NameAndDescription({ nft, setFormOn }) {

    const { t } = useTranslation();
    const history = useHistory()
    const user = useSelector(state => state.general.user)
    const [openHamburger, setOpenHamburger] = useState(false)
    const [openHamburgerAdm, setOpenHamburgerAdm] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [alertAdmTransfer, setAlertAdmTransfer] = useState(false)
    const [alertAdmGift, setAlertAdmGift] = useState(false)
    

    let objModalTransfer = {
        img: svgTransfer,
        title: t("modal.modalTransfer.title"),
        name: nft.name,
        text: t("modal.modalTransfer.text"),
        buttonConfirm: t("modal.modalTransfer.transfer"),
        inputName: t("modal.modalTransfer.inputName"),
        placeholder: t("modal.modalTransfer.placeholder"),
        buttonCancel: t("modal.modalTransfer.cancel"),
        close: () => setAlertAdmTransfer(false),
        idNft: nft._id,
    }

    let objModalGift = {
        idNft: nft._id,
        close: () => setAlertAdmGift(false)
    }

    let objModalDelete = {
        img: deleteSvg,
        title: t("modal.modalDelete.title"),
        text: t("modal.modalDelete.text"),
        confirm: t("modal.modalDelete.button1"),
        toDoIfConfirm: user?.role === "user" ? async () => {
            let obj = {
                idNFT: nft._id
            }
            let res = await fetchBackend('DELETE', `/nft`, obj, user)
            setModalDelete(false)
            history.goBack()
        } : async () => {
            let obj = {
                idNFT: nft._id
            }
            let res = await fetchBackend('DELETE', `/nft/admin`, obj, user)
            setModalDelete(false)
            history.goBack()
        },
        close: () => setModalDelete(false),
    }

    const optionsMenu = () => {
        if (user && user.role === "admin") {
            setOpenHamburgerAdm(!openHamburgerAdm)
        } else {
            setOpenHamburger(!openHamburger)
        }
    }
    return (
        <div className={`w-full flex flex-col justify-start items-start gap-y-3 font-montserrat`}>

            <Modal
                isOpen={modalDelete}
                style={modalOverlay}
                className={`${modalContainer} font-montserrat w-[100vw] h-6/12 max-h-[260px] sm:w-[533px] sm:h-[336px] sm:max-h-[336px]`}
                onRequestClose={() => setModalDelete(false)}
            >
                <AlertConfirm modalObj={objModalDelete} />
            </Modal>

            <Modal
                isOpen={alertAdmTransfer}
                style={modalOverlay}
                className={`${modalContainer} font-montserrat w-[100vw] h-6/12  sm:w-[533px] sm:h-[427px] sm:max-h-[427px]`}
                onRequestClose={() => setAlertAdmTransfer(false)}
            >
                <AlertTransfer modalObj={objModalTransfer} />
            </Modal>

            <Modal
                isOpen={alertAdmGift}
                style={modalOverlay}
                className={`${modalContainer} font-montserrat w-[100vw] h-6/12  sm:w-[533px] sm:h-[427px] sm:max-h-[380px]`}
                onRequestClose={() => setAlertAdmGift(false)}
            >
                <AlertGift modalObj={objModalGift} />
            </Modal>

            <div className='flex justify-between items-center w-full'>
                <span className='text-[24px] font-semibold sm:text-[32px] '>{nft.name}</span>
                {
                    user?.firebaseID === nft.createdBy.firebaseID || user?.role === 'admin' ?
                        <button className='text-[#E671ED] font-semibold flex items-center justify-center self-center ' onClick={() => optionsMenu()}>
                            {<Icon icon='carbon:overflow-menu-vertical' className='text-[32px] sm-text-[39px] text-white mr-0.5 -mt-0.5' />}

                        </button>
                        :
                        null
                }
            </div>

            {/* ADMIN BUTTONS */}
            <div className='w-full flex relative'>
                <div
                    className={`bg-[#0F0B16] absolute  flex flex-col items-center justify-center gap-y-3 h-full py-2 w-[168px] px-3 -top-3 right-1 z-[999] text-white transition-all ease-linear duration-150 rounded-xl  ${openHamburgerAdm ? 'h-auto flex' : 'h-0 hidden invisible'}`}
                    onMouseLeave={() => setOpenHamburgerAdm(false)}
                >
                    <div className='flex flex-col items-start justify-center px-1 self-start w-full text-white disabled:text-gray-400 disabled:cursor-not-allowed'>
                        <span >{t("detailNFT.admin")}</span>
                        <span className='bg-white w-full h-[1px]'></span>

                    </div>

                    <button className='font-semibold gap-x-2 flex items-center justify-center self-start text-white disabled:text-gray-400 disabled:cursor-not-allowed' onClick={() => setAlertAdmGift(true)}>
                        {<Icon icon='ant-design:gift-outlined' className='text-[15px]' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.mintGift")}</span>
                    </button>

                    <button className='font-semibold gap-x-2 flex items-center justify-center self-start text-white disabled:text-gray-400 disabled:cursor-not-allowed' onClick={() => setAlertAdmTransfer(true)}>
                        {<Icon icon='icon-park-outline:transfer-data' className='text-[15px]' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.transfer")}</span>
                    </button>

                    {user?.firebaseID === nft.createdBy.firebaseID && <button disabled={nft.onSale} className='font-semibold gap-x-2 flex items-center justify-center self-start text-white disabled:text-gray-400 disabled:cursor-not-allowed' onClick={() => setFormOn(true)}>
                        {<Icon icon='eva:edit-outline' className='text-[22px]' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.edit")}</span>
                    </button>}

                    <button className='text-[#FF7D7D] font-semibold gap-x-2 flex items-center justify-center self-start' onClick={() => setModalDelete(true)} >
                        {<Icon icon='fluent:delete-12-regular' className='text-[22px]   ' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.delete")}</span>
                    </button>



                </div>

            </div>

            {/* OWNER BUTTONS */}

            <div className='w-full flex relative'>
                <div
                    className={`bg-[#0F0B16] absolute  flex flex-col items-center justify-center gap-y-3 h-[86px] w-[168px] px-3 -top-3 right-1 z-[999] text-white transition-all ease-linear duration-150 rounded-xl  ${openHamburger ? 'h-auto flex' : 'h-0 hidden invisible'}`}
                    onMouseLeave={() => setOpenHamburger(false)}
                >
                    <button disabled={nft.onSale} className='font-semibold gap-x-2 flex items-center justify-center self-start text-white disabled:text-gray-400 disabled:cursor-not-allowed' onClick={() => setFormOn(true)}>
                        {<Icon icon='eva:edit-outline' className='text-[22px]' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.edit")}</span>
                    </button>
                    <button className='text-[#FF7D7D] font-semibold gap-x-2 flex items-center justify-center self-start' onClick={() => setModalDelete(true)} >
                        {<Icon icon='fluent:delete-12-regular' className='text-[22px]   ' />}
                        <span className='text-[12px] font-normal'>{t("detailNFT.delete")}</span>
                    </button>

                </div>

            </div>

            <div className='w-full flex flex-col items-start justify-start gap-y-1'>
                <span className='text-[14px] sm:text-[16px] font-semibold'>{t("detailNFT.description")}</span>
                <p className='text-[14px] sm:text-[16px]'>{nft.lore}</p>
            </div>

            {nft.price !== undefined && <div className='w-full flex items-center justify-start gap-x-3'>
                <img className="w-[39px]" src="https://musure.mypinata.cloud/ipfs/QmNcZiNJh5dfydir52Qk5dMorYUBN7DwmcQ9Kd81vjNSxL" alt="USDC" />
                <p className='text-[32px] '>{nft.price}</p>
                <span className='text-[22px] '>{t('toolkitNft.viewConfigure.usdc')}</span>
            </div>}

            <span className='text-[14px] sm:text-[16px]'>{t("detailNFT.unique")}</span>


        </div>
    )
}
