//GENERAL
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useState } from "react"
import Modal from 'react-modal'


//COMPONENTS
import AlertConfirm from "../../modals/AlertConfirm/AlertConfirm";

//UTILS
import { modalContainer, modalOverlay } from "../../utils/constants/classes"

import HeaderCreationType from "../HeaderCreationType/HeaderCreationType"
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown"
import ButtonDownload from "../ButtonDownload/ButtonDownload"
import { handleRandomTexture, removeRandomTexture } from "../../utils/functions/logic/handleRandomTexture"
import warningGradient from '../../assets/images/icons/warningGradient.svg'
import { Icon } from "@iconify/react"

export default function CreationType({ setModal, setOpenDrop, openDrop, flagViewer, setFlagViewer }) {

    const { t } = useTranslation()
    const history = useHistory()
    const params = useParams();
    const dispatch = useDispatch();
    const creationType = params.creationType

    const [processingAutomatic, setProcessingAutomatic] = useState(false);
    const [nameAutomatic, setNameAutomatic] = useState();

    const [modalError, setModalError] = useState(false);

    const textureSneaker = useSelector(state => state.toolkit.textureSneaker)
    const typeCreation = useSelector(state => state.toolkit.type)

    const flagFiles = useSelector(state => state.general.flagFiles)



    let objModalError = {
        img: warningGradient,
        title: t("modal.modalErrorInputFile.title"),
        text: t("modal.modalErrorInputFile.text"),
        text2: t("modal.modalErrorInputFile.text2"),
        confirm: t("modal.modalErrorInputFile.button"),
        close: () => setModalError(false),
    }


    const optionsAutomatic = [
        {
            id: "automatic",
            icon: 'akar-icons:image',
            placeholder: t('toolkitNft.automatic'),
            disabled: !flagFiles,
            processing: processingAutomatic,
            name: nameAutomatic,
            reduxImg: textureSneaker,
            onChange: (e) => handleRandomTexture(e, dispatch, setModalError, setProcessingAutomatic, setNameAutomatic, setFlagViewer, flagViewer),
            onChangeRemove: () => removeRandomTexture(dispatch, setNameAutomatic, setFlagViewer, flagViewer)
        }
    ]




    return (
        <div className="flex flex-col gap-y-6 relative w-full h-full">

            {
                modalError &&
                <Modal
                    isOpen={modalError}
                    style={modalOverlay}
                    className={`${modalContainer} w-[100vw] h-6/12 max-h-[260px] sm:w-[534px] sm:max-h-[354px]`}
                    onRequestClose={() => setModalError(false)}
                >
                    <AlertConfirm modalObj={objModalError} />
                </Modal>
            }

            <HeaderCreationType
                type={creationType}
                creation={t(`toolkitNft.landing.${creationType}`)}
                description={t(`toolkitNft.${creationType}Description`)}
                openDrop={openDrop}
                setModal={setModal}
                optionsAutomatic={optionsAutomatic}
                setOpenDrop={setOpenDrop}
            />

            {/*BOTONES DESKTOP*/}

            <div className="w-full hidden lg:flex flex-col gap-y-3 justify-between">

                {creationType === 'manual' &&
                    <ButtonDownload
                        setModal={setModal}
                        type={typeCreation}
                        title={t("toolkitNft.buttonDownload")}
                    />
                }


                <ButtonDropdown
                    setOpenDrop={setOpenDrop}
                    openDrop={openDrop}
                    title={t(`toolkitNft.${creationType}ButtonUpload`)}
                    setFlagViewer={setFlagViewer}
                    flagViewer={flagViewer}
                    options={optionsAutomatic}
                />
                <div className="flex w-full justify-start items-center gap-x-2  text-white">
                    <Icon icon="mdi:information-outline" className="text-[15px] sm:text-[17px]"/>
                    <span className="text-[14px]">{t("toolkitNft.size")}</span>
                </div>


            </div>

        </div>

    )
}
