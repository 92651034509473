//UTILS
import { buttonDownload } from '../../utils/constants/classes';
import { Trans } from 'react-i18next';

export default function AlertConfirm({ modalObj }) {

    function toDoOnConfirm() {
        if (modalObj.toDoIfConfirm) {
            modalObj.toDoIfConfirm()
        }
        modalObj.close(false)
    }

    return (
        <div className="flex items-center justify-center w-full h-full font-montserrat bg-white rounded-t-2xl sm:rounded-2xl">
            <div className='flex flex-col items-center justify-start w-10/12 h-11/12 sm:h-10/12 sm:w-8/12 gap-y-2'>

                <img src={modalObj.img} className='w-[40px] h-[40px] sm:w-[80px] sm:h-[80px]' />

                <div className={`flex flex-col justify-center items-center text-center text-darkpurple ${modalObj.text2 && 'gap-y-3'}`}>

                    <h4 className="font-bold sm:text-[21px]">{modalObj.title}</h4>
                    <p className=" text-[12px] sm:text-[16px] text-[#4D3E3E]">{modalObj.text}</p>

                    {modalObj.text2 &&
                    <div className='inline text-[#4D3E3E]'>
                        <Trans className='inline' i18nKey={modalObj.text2} components={[<a href='https://imagecompressor.com/es/' target={'_blank'} className='text-[#DF66DB] underline'></a>]} />
                    </div>}


                </div>

                <button className={`${buttonDownload} w-full text-[14px] sm:text-[16px] mt-6 sm:w-9/12`} onClick={() => toDoOnConfirm()}>{modalObj.confirm}</button>

            </div>
        </div>
    )
}
