//GENERAL
import { useTranslation } from "react-i18next";

//COMPONENTS


//UTILS
import logowhite from '../../assets/images/logos/logo_musure_white.svg'
import musureLogo from "../../assets/images/logos/musureLogoHD.svg";

export default function Footer() {

    let classLink = 'hover:text-pinky transition duration-150 ease-in-out'

    const { t } = useTranslation()

    return (
        <div className="h-[220px] xl:h-[280px] w-full relative shadow-lg bg-black flex items-end justify-center ">

            <div className="max-w-[1100px] px-2 w-full h-full relative flex flex-col justify-center items-center xl:items-start">


                <div className="flex w-full h-auto justify-around items-start text-white text-[12px]">

                    <div className="flex md:flex-col  w-4/12 md:w-6/12 gap-y-7  relative justify-center items-center xl:items-start">
                        <img src={musureLogo} alt="logoMusure" className="hidden md:flex  " />
                        <img src={logowhite} alt="logoMusure" className="flex w-[50px] h-[50px] md:hidden " />

                    </div>

                    <div className="flex xl:flex-col xl:gap-y-6  w-8/12 md:w-6/12 justify-between md:justify-around items-start">

                        <div className="flex flex-col w-full xl:flex-row relative justify-end gap-x-10 items-start  gap-y-3 ">
                            <a className={classLink} href='https://musure.world'>{t("footer.home")}</a>
                            <a className={classLink} href='https://whitepaper.musure.world' target='_blank'>{t("footer.whitepaper")}</a>
                            <a className={classLink} href='https://musureworld.medium.com/' target='_blank'>{t("footer.blog")}</a>
                            <a className={classLink} href='https://www.linkedin.com/company/musureworld/' target='_blank'>{t("footer.linkedin")}</a>
                        </div>
                        <div className="flex flex-col  w-full xl:flex-row relative justify-end gap-x-10 items-start  gap-y-3">
                            <a className={classLink} href='https://t.me/MUSUREworld' target='_blank'>{t("footer.telegram")}</a>
                            <a className={classLink} href='https://discord.com/invite/2mvfw4w7jR' target='_blank'>{t("footer.discord")}</a>
                            <a className={classLink} href='https://www.instagram.com/musureworld/' target='_blank'>{t("footer.instagram")}</a>
                            <a className={classLink} href='https://www.tiktok.com/@musureworld' target='_blank'>{t("footer.tiktok")}</a>
                            <a className={classLink} href='https://twitter.com/musureworld' target='_blank'>{t("footer.twitter")}</a>
                        </div>

                    </div>
                </div>
                
                <div className="flex w-full pt-5 md:pt-0  xl:pt-8 justify-center items-center">
                    <span className="text-lightpink md:w-9/12 xl:w-full md:text-left text-[10px] md:text-[12px]">{t("footer.copyright")}</span>
                </div>

            </div>




            {/* <div className="max-w-[1100px] px-2 w-full h-full relative flex justify-around items-center">

                <div className="w-[70px] xl:w-6/12 h-full relative flex flex-col justify-start items-start">

                    <div className="w-[60px] xl:w-6/12 h-full relative flex flex-col justify-start pt-14 gap-y-10  items-start">

                        <div className="flex w-full  relative justify-start items-center">
                            <img src={musureLogo} alt="logoMusure" className="hidden xl:flex xl:w-[200px] xl:h-[60px]" />
                            <img src={logowhite} alt="logoMusure" className="flex xl:hidden xl:w-[50x] xl:h-[50px]" />
                        </div>

                        <span className="text-lightpink hidden xl:flex text-[12px]">{t("footer.copyright")}</span>
                    </div>
                </div>

                <div className="w-9/12 xl:w-6/12 h-full relative flex flex-col justify-center   items-center">

                    <div className="w-full h-auto relative flex xl:flex-col gap-y-10 gap-x-5 xl:gap-x-0 justify-center text-[12px] items-start text-white">

                        <div className="flex flex-col xl:flex-row w-full relative justify-end gap-x-10 items-start xl:items-end ">
                            <a className={classLink} href='https://musure.world'>{t("footer.home")}</a>
                            <a className={classLink} href='https://whitepaper.musure.world' target='_blank'>{t("footer.whitepaper")}</a>
                            <a className={classLink} href='https://musureworld.medium.com/' target='_blank'>{t("footer.blog")}</a>
                        </div>

                        <div className="flex flex-col xl:flex-row w-full relative justify-end gap-x-10 items-start xl:items-end">
                            <a className={classLink} href='https://t.me/MUSUREworld' target='_blank'>{t("footer.telegram")}</a>
                            <a className={classLink} href='https://discord.com/invite/2mvfw4w7jR' target='_blank'>{t("footer.discord")}</a>
                            <a className={classLink} href='https://www.instagram.com/musureworld/' target='_blank'>{t("footer.instagram")}</a>
                            <a className={classLink} href='https://twitter.com/musureworld' target='_blank'>{t("footer.twitter")}</a>
                        </div>

                    </div>

                    <span className="text-lightpink flex xl:hidden w-full text-[12px]">{t("footer.copyright")}</span>
                </div>

            </div> */}
        </div>
    )
}
