//classes buttons
export const structureButton = " px-2 py-2 rounded-full | flex items-center justify-center transition ease-in-out duration-900 focus:outline-none box-border text-center"

export const ButtonPrimary= `${structureButton} text-white font-semibold bg-gradient-to-tr | from-lightpurple to-pinky hover:bg-gradient-to-tr | hover:from-darkpink hover:to-darkpurple | active:from-lightpurple active:to-darkpurple | disabled:bg-none disabled:bg-opacity-50 disabled:text-white disabled:bg-disabledgrey disabled:text-opacity-50 disabled:cursor-not-allowed`

export const buttonDownload = `${structureButton} text-white font-semibold bg-gradient-to-b | from-buttonviolet to-darkpurple hover:bg-gradient-to- | hover:from-darkpink hover:to-darkpurple | active:from-lightpurple active:to-darkpurple | disabled:bg-none disabled:bg-opacity-50 disabled:text-white disabled:bg-disabledgrey disabled:text-opacity-50 disabled:cursor-not-allowed`

export const buttonUpload = `${structureButton} text-white font-semibold bg-gradient-to-b | from-pinky to-buttonpinky hover:bg-gradient-to- | hover:from-darkpink hover:to-darkpurple | active:from-lightpurple active:to-darkpurple | disabled:bg-none  disabled:text-disabledUpload disabled:bg-disableTextUpload  disabled:cursor-not-allowed`

export const ButtonSecondary = `${structureButton} text-[#E671ED] border-2 border-pink font-bold hover:text-purplesecondary hover:border-purplesecondary active:border-purpleblue active:text-purpleblue disabled:text-disabledgrey disabled:border-disabledgrey focus:outline-none`

export const ButtonRarity = "absolute bottom-3 left-4 bg-black px-3 py-1.5 rounded-full text-white text-center text-xs z-40"



//background
export const backgroundGradient = "w-full h-full bg-gradient-to-b from-[#59096E] to-[#060542] overflow-y-auto z-10 relative scrollbar-hide";
export const backgroundGradientTransp = "w-full h-full bg-gradient-to-b from-transparent via-darkpurple to-darkblue overflow-y-auto z-10 relative scrollbar-hide"


//structures
export const limitViewToStructure="w-[89%] sm:w-[83.33%] lg:w-[75%] mx-auto max-w-[1120px]"

export const structureViewsPanel = `flex flex-col justify-start items-start ${limitViewToStructure} z-20 relative mb-20 `

export const structureCreationAssisted = `flex flex-col justify-start items-start ${limitViewToStructure} z-20  mb-20 `

export const containerStructureGeneral = "w-full h-full min-h-[78.9vh] mb-auto font-montserrat mt-[62px] lg:mt-[86px] pt-10 lg:pt-16 xl:pt-20"


//fonts
export const normalTitle = "text-[24px] sm:text-[28px] lg:text-[32px] xl:text-[38px] font-semibold leading-tight z-50 whitespace-normal "

export const bigTitle="text-bigtitle text-white font-bold text-center w-9/12 mx-auto leading-tight mt-20"

export const subTitle="text-subtitle text-white font-semibold"


//modal
export const modalContainer = "absolute bottom-0 sm:right-0 sm:left-0 sm:top-0 m-auto focus:outline-none rounded-2xl z-20"
 
export const modalOverlay = {
        overlay:{
            zIndex:"50",
            backgroundColor: '#06054280',
        }
    }

    
//inputs
export const inputTitle = 'text-white leading-4 text-[13px] mb-1.5 mr-1.5 flex items-center justify-start'

const inputBase = 'relative w-full resize-none mb-1 px-[16px] py-[10px] leading-5 focus:outline-none box-border'

export const inputClass =  `${inputBase} border bg-transparent rounded-[10px] border-white text-white placeholder:text-white/50 disabled:border-white/50 disabled:text-white/50`;

export const inputErrorClass = `${inputBase} border bg-transparent rounded-[10px] border-rederror text-white placeholder:text-rederror/50 disabled:border-white/50 disabled:text-white/50`;

export const inputModalClass = `${inputBase} border bg-transparent rounded-2xl border-darkpurple text-darkpurple placeholder:text-darkpurple/50`

export const inputModalErrorClass = `${inputBase} border bg-transparent rounded-2xl border-rederror text-darkpurple placeholder:text-rederror/50`

export const errorSpan = 'text-rederror text-[10px] lg:text-[12px]'

export const optionDropdownClass = 'text-darkpurple hover:bg-whitepurple'

export const inputWhiteTransp = `${inputBase} bg-white/20 rounded-[10px] text-white placeholder:text-white/70 text-[12px] lg:text-[16px]`