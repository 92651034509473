import { errorSpan, inputClass, inputErrorClass, inputTitle } from "../../utils/constants/classes";
import Tooltip from 'rc-tooltip';

export default function BasicTextArea({textareaAttributes, watch, register, tooltip, title, classNameTextArea, registerName, className, registerObj, errors, textColor}) {


    return (
        <div className={`relative w-full flex flex-col items-start justify-start ${className}`}>

            {/*HEADER*/}
            <h4 className={`${inputTitle} ${textColor}`}>{title}</h4>
            {
                tooltip &&
                <Tooltip/>
            }

            {/*TEXTAREA*/}
            <div className="relative w-full">
                <textarea
                    {...textareaAttributes}
                    autoComplete="off"
                    className={`${classNameTextArea} ${errors[registerName] ? `${inputErrorClass} ${textColor}` : `${inputClass} ${textColor}`}`}
                    {...register(registerName, registerObj)}
                />

                <p className={`absolute z-40 bottom-[15px] right-[15px] text-white text-[10px] ${errors[registerName] && errors[registerName]?.type !== 'required' && 'text-rederror'}`}>{watch(registerName) ? textareaAttributes.maxLength - watch(registerName)?.length : textareaAttributes.maxLength}</p>
            </div>

            {/*ERROR*/}
            { errors[registerName] && <span className={errorSpan}> {errors[registerName].message} </span> }
        
        </div>
    )
}
