import { Icon } from '@iconify/react'
import { useRef } from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom';
import { buttonUpload } from '../../utils/constants/classes';
import { filtersBase, sortsBase } from '../../utils/data/objects';

export default function FilterAndSort({flag, close, title, show, options , openOptions, setOpenOptions, selectedSort, setSelectedSort, setSelectedFilters , selectedFilters, place}) {

    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const applyButton = useRef();
    
    return (
        <div className={`${show? 'w-screen opacity-1 z-[9999] top-[62px] right-0': 'w-0 opacity-0 z-0'} h-[95vh] bg-[#0E0D0E] transition-all fixed md:absolute md:top-0 md:right-0 md:w-[300px] md:h-auto md:py-6 md:rounded-2xl md:mt-12 md:shadow-around duration-250 ease-in scrollbar-hide`}>
            <div className='flex items-start justify-center w-full h-full mt-8 lg:mt-0'>
                <div className='w-10/12 h-[80%] flex flex-col justify-between items-start'>
                    
                    {/*CLOSE AND CLEAR*/}
                    <div className='flex items-center justify-between text-white w-full'>
                        <div className='flex items-center justify-start select-none'>
                            <Icon 
                            icon='akar-icons:chevron-down' 
                            className='rotate-90 text-[25px] cursor-pointer'
                            onClick={()=>close(!show)}/>
                            <h2 className='font-semibold text-[18px] tracking-wide'>{title}</h2>
                        </div>

                        <button 
                        className='text-[18px] text-[#E671ED]' 
                        onClick={()=>{
                            if(flag === 'filter'){
                                setSelectedFilters({...filtersBase})
                                if(place === 'fashioncollections'){
                                    history.push(`/fashioncollections?creationType=null&status=null&sortCreatedAt=${Object.keys(selectedSort.createdAt)[0]}`)
                                }
                                if(place === 'gallery'){
                                    history.push(`/gallery/${location.pathname.slice(9,33)}/?creationType=null&status=null&sortCreatedAt=${Object.keys(selectedSort.createdAt)[0]}`)
                                }
                            }
                            else{
                                setSelectedSort({...sortsBase})
                                let creationTypeValue = null
                                let statusValue = null;
                                for (const key in selectedFilters.creationType) {
                                    if (selectedFilters.creationType[key]) {
                                        creationTypeValue = key
                                    }
                                }
                                if(selectedFilters.status.minted){
                                    statusValue = true
                                }
                                if(selectedFilters.status.notMinted){
                                    statusValue = false
                                }
                                if(place === 'fashioncollections'){
                                    history.push(`/fashioncollections?creationType=${creationTypeValue}&status=${statusValue}&sortCreatedAt=null`)
                                }
                                if(place === 'gallery'){
                                    history.push(`/gallery/${location.pathname.slice(9,33)}/?creationType=${creationTypeValue}&status=${statusValue}&sortCreatedAt=null`)
                                }
                            }
                            close(!show)
                        }}>
                            {t('fashioncollection.clearFilterSort')}
                        </button>
                    </div>

                    {/*OPTIONS FILTER AND SORT*/}
                    <div className='w-full h-full mt-10 flex flex-col items-start justify-start gap-y-6 overflow-y-scroll scrollbar-hide'>
                        {
                            options?.map(o=>{
                                return (
                                    <div className='w-full'>

                                        {/*OPTION TITLE*/}
                                        <div 
                                        className='w-full flex items-center justify-between text-white cursor-pointer select-none' 
                                        onClick={()=>setOpenOptions({
                                            ...openOptions, 
                                            [o.category] : !openOptions[o.category]
                                        })}
                                        >
                                            <h4 className='text-[14px] md:text-[16px]'>{o.title}</h4>
                                            <Icon icon='akar-icons:chevron-down' className={`${flag === 'sort' && 'hidden'} text-[24px] ${openOptions[o.category]? '-rotate-180' : 'rotate-0'} transition-all ease-linear duration-150`}/>
                                        </div>

                                        {/*SPECIFIC OPTIONS*/}
                                        <div className={`flex flex-col items-start justify-start gap-y-1 mt-2 ${openOptions[o.category]? 'h-auto opacity-1': 'h-0 opacity-0'} transition-all duration-250 ease-linear`}>
                                            {
                                                o.options.map(io=>{
                                                    return (
                                                        <div 
                                                        className={`${openOptions[o.category] ? 'text-opacity-100 w-full bg-white/20' : 'text-opacity-0 w-0 bg-transparent'} ${flag === 'filter' ? selectedFilters[o.category][io.type] && 'bg-[#E671ED] bg-opacity-50' : selectedSort[o.category][io.type] && 'bg-[#E671ED] bg-opacity-50'} text-white  flex items-center justify-between px-4 py-2.5 rounded-[10px] cursor-pointer select-none`}
                                                        onClick={()=>{
                                                            flag === 'filter'?
                                                                setSelectedFilters({
                                                                    ...selectedFilters,
                                                                    [o.category]:{
                                                                        ...filtersBase[o.category],
                                                                        [io.type] : !selectedFilters[o.category][io.type]
                                                                    }
                                                                })
                                                            :
                                                                setSelectedSort({
                                                                    ...sortsBase,
                                                                    [o.category]:{
                                                                        [io.type] : !selectedSort[o.category][io.type]
                                                                    }
                                                                })
                                                        }}
                                                        >
                                                            <span className='text-[12px] md:text-[14px]'>{io.text}</span>
                                                            <Icon icon='ci:check-bold' className={`text-[18px] ${flag === 'filter' ? (selectedFilters[o.category][io.type] ? 'opacity-100': 'opacity-0') : selectedSort[o.category][io.type] ? 'opacity-100': 'opacity-0'}`}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>

                    {/*APPLY*/}
                    <div className='w-full mt-4'>
                        <button 
                        ref={applyButton}
                        className={`${buttonUpload} w-full py-2.5 text-[14px]`}
                        onClick={()=>{
                            //filters
                            let creationTypeValue = null;
                            let statusValue = null;
                            for (const key in selectedFilters.creationType) {
                                if (selectedFilters.creationType[key]) {
                                    creationTypeValue = key
                                }
                            }
                            if(selectedFilters.status.minted){
                                statusValue = true
                            }
                            if(selectedFilters.status.notMinted){
                                statusValue = false
                            }

                            //sort
                            let sortValue = null
                            if(selectedSort.createdAt.ascending){
                                sortValue = 'ascending'
                            }
                            if(selectedSort.createdAt.descending){
                                sortValue = 'descending'
                            }
                            if(place === 'fashioncollections'){
                                history.push(`/fashioncollections?creationType=${creationTypeValue}&status=${statusValue}&sortCreatedAt=${sortValue}`)
                            }
                            if(place === 'gallery'){
                                history.push(`/gallery/${location.pathname.slice(9,33)}/?creationType=${creationTypeValue}&status=${statusValue}&sortCreatedAt=${sortValue}`)
                            }
                            close(!show)
                        }}
                        >
                            {t('fashioncollection.applyFilterSort')}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}
