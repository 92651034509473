//GENERAL
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DataHeaderGallery from "../../components/DataHeaderGallery/DataHeaderGallery";
import FormHeaderGallery from "../../components/FormHeaderGallery/FormHeaderGallery";
import Loader from "../../components/Loader/Loader";
import MarketGrid from "../../components/MarketGrid/MarketGrid";
import Toolbar from "../../components/Toolbar/Toolbar";
import { getItemsUser } from "../../redux/actions/actionsGeneral";
import { backgroundGradient, containerStructureGeneral, structureViewsPanel } from "../../utils/constants/classes";
import { fetchBackend } from "../../utils/functions/logic/fetchBackend";

//COMPONENTS

//UTILS

export default function MyGallery() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()
    const params = useParams();
    const items = useSelector(state => state.general.gallery)
    const [loading, setLoading] = useState(true)
    const [owner, setOwner] = useState()
    const [editing, setEditing] = useState(false)

    useEffect(()=>{
        setLoading(true)
        dispatch(getItemsUser(params.userID, Object.fromEntries(new URLSearchParams(location.search))))
        .then(()=> {
             return fetchBackend('GET',`/user?userID=${params.userID}`)
        })
        .then((res)=>{
            setOwner(res.data)
            setLoading(false)
        })
        .catch(err=>history.goBack())
    },[location])

    return (
        <div className={`${containerStructureGeneral} ${backgroundGradient}`}>
            <div className={`${structureViewsPanel} `}>

                {
                    loading?
                        <Loader/>
                    :
                        <div className="w-full flex flex-col items-start justify-start">
                            {
                                editing?
                                    <FormHeaderGallery editing={editing} setEditing={setEditing} setOwner={setOwner}/>
                                :
                                    <DataHeaderGallery setEditing={setEditing} owner={owner}/>
                            }
                            <Toolbar place='gallery'/>
                            <MarketGrid items={items}/>
                        </div>
                }
            </div>
        </div>
    )
}
