
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import {app} from '../../../config/firebaseConfig.js'
import { setUser } from "../../../redux/actions/actionsGeneral.js";

const auth = getAuth(app);

export async function Login(close) {
    
    const provider = new GoogleAuthProvider();
    
    signInWithPopup(auth, provider)
    .then((result)=>{
        if(close){
            close(false)
        }
        return result
    })
    .catch(error=>console.log(error))

}

export async function Logout(dispatch, close) {

    signOut(auth)
    .then(() => {
        // Sign-out successful.
        if(close){
            close(false)
        }
        dispatch(setUser(null))
    }).catch((error) => {
        // An error happened.
        console.error(error)
    });
}
