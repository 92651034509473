//GENERAL
import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from 'react-redux';


//COMPONENTS

//UTILS

export default function DoubleCarrousel() {

    const typeSelected = useSelector(state=> state.toolkit.type)
    const [arrCarrousel , setArrCarrousel] = useState([])

    const urlPinata = 'https://musure.mypinata.cloud/ipfs/'
    useEffect(()=>{
        if(typeSelected === 'Sneaker'){
            setArrCarrousel([
                `${urlPinata}QmeyRqd9f2CJi786s2dtdtFAgf3sFgJozwhgh7ptqFUsCk`,
                `${urlPinata}Qmd5T8PfZJMbAKRMGBGEE3YAiCLd4WJmYTggTcezfuKXmV`,
                `${urlPinata}QmWmg81JG5n7xJrnMbNwr73yRn9XKezSxryWJV9YyqgF59`,
                `${urlPinata}QmU8AZoyzgPoa1B3NiKoJ9dqk5Rg6oKgtv38FonndRsByG`,
                `${urlPinata}QmQrC8j9FTWZdQJhyD3o39qwzN7urqLZUiZaiTBWo1mq9z`,
                `${urlPinata}QmRF22TKAjPxuqfih2FCPP69yBCEConP2m2W8hjiPdV9nk`,
            ])
        }
        if(typeSelected === 'Vehicle'){
            setArrCarrousel([
                `${urlPinata}QmTSa58mh55fWse5FR5NbBNdbW7GvkupzRjCttCHDkvQB5`,
                `${urlPinata}QmYHwJrAVg9gYw99UXvr6PyRvP43MqGjgcZzYmRfRbJwBS`,
                `${urlPinata}QmXQPasnAXmkVYo6NXm5ifsFWgkwBFDMGdtrXyZJFMyVYk`,
                `${urlPinata}QmXB1Q8NTyeC5mGwHh5cWoopEDDLpgrxEdYyXR18WNFgfS`,
                `${urlPinata}QmZQJzrKzcDggsRK4aZvCKceKBTx8q82r4jLrFfYZib5EM`,
                `${urlPinata}QmfT37bouEhmc6NVaBq8RNB68H2Ngm6KrteHPGKPhHRCQr`,
                `${urlPinata}QmPP4QFR1eG2U6uJXMaUVBob4Yw8WtJRn1UTwQCoCUiY3S`,
                `${urlPinata}QmVuBxu1oJGgzkAb8dUgVe5ZX3GPfoDU5vUE8KooaGwHWB`,
            ])
        }
    },[typeSelected])

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8,
            slidesToSlide: 3.5 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3.1,
            slidesToSlide: 1.5 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2.6,
            slidesToSlide: 1.5 // optional, default to 1.
        }
    };
    const responsive2 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3.1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2.6,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <div className='w-screen justify-center md:h-[460px] md:max-h-[500px] lg:flex lg:justify-start lg:h-full items-center'>
            <Carousel
                responsive={responsive1}
                draggable={false}
                swipeable={true}
                infinite={true}
                arrows={false}
                autoPlaySpeed={4500}
                renderButtonGroupOutside={false}
                customButtonGroup={false}
                autoPlay={true}
                sliderClass={`relative h-88 lg:flex lg:flex-col lg:items-start `}
                itemClass="flex justify-start items-center max-h-[250px] lg:h-full relative"
                containerClass={`relative w-full lg:w-6/12 z-20 `}
            >
                {arrCarrousel.length > 0 &&
                    arrCarrousel.sort().map(e => {
                        return (
                            <div className='w-full h-[full] flex flex-col justify-center items-center'>
                                <img src={e} alt="" className='w-full h-full object-cover object-center' />
                            </div>
                        )
                    })
                }

            </Carousel>
            <Carousel
                responsive={responsive2}
                draggable={true}
                swipeable={true}
                infinite={true}
                arrows={false}
                autoPlaySpeed={4700}
                renderButtonGroupOutside={false}
                customButtonGroup={false}
                autoPlay={true}
                sliderClass={`relative h-88 lg:flex lg:flex-col `}
                itemClass="flex justify-center items-center max-h-[250px] relative"
                containerClass={`relative w-full lg:w-auto z-20`}
            >
                {arrCarrousel.length > 0 &&
                    arrCarrousel.sort().reverse().map(e => {
                        return (
                            <div className='w-full h-[full] flex flex-col justify-center items-center'>
                                <img src={e} alt="" className='w-full h-full object-cover object-center' />
                            </div>
                        )
                    })
                }

            </Carousel>
        </div>
    )
}
