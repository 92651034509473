import { Icon } from '@iconify/react';
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BasicInput from "../BasicInput/BasicInput";
import BasicTextArea from "../BasicTextArea/BasicTextArea";
import bannerDefaultMusure from '../../assets/images/bannerMusure.svg'
import logoDefaultMusure from '../../assets/images/logoGallery.png'
import BasicInputImage from '../BasicInputImage/BasicInputImage';
import { useState } from 'react';
import { setUser } from '../../redux/actions/actionsGeneral';
import { handleTexturePignata } from '../../utils/functions/logic/handleTexture';
import { fetchBackend } from '../../utils/functions/logic/fetchBackend';

export default function FormHeaderGallery({editing, setEditing, setOwner}) {

    const {t} = useTranslation();
    const dispatch = useDispatch()
    const [bannerUploaded, setBannerUploaded] = useState();
    const [imageUploaded, setImageUploaded] = useState();
    const [processing, setProcessing] = useState(false);
    const user = useSelector(state=>state.general.user);
    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 500, defaultValues: user});
    const userID = `${user?._id.slice(0,16)}...`

    const onSubmit = async (data) => {
        setProcessing(true)
        let obj = {
            userID: data._id, 
            username: data.username || "empty", 
            bio: data.bio || "empty", 
            image: imageUploaded || data.image, 
            twitter: data.twitter || "empty", 
            instagram: data.instagram || "empty", 
            tiktok: data.tiktok || "empty", 
            banner: bannerUploaded || data.banner
        }
        const res = await fetchBackend('PATCH', '/user', obj, user)

        dispatch(setUser({
            email: data.email,
            uid: data.firebaseID
        }))
        
        setOwner(res.data.user)
        setProcessing(false)
        setEditing(false)
    };

    const socialNetworks = [
        {
            type: 'instagram',
            icon: 'akar-icons:instagram-fill'
        },
        {
            type: 'twitter',
            icon: 'radix-icons:twitter-logo'
        },
        {
            type: 'tiktok',
            icon: 'ph:tiktok-logo'
        },
    ];

    const onChangeBanner = async(e)=>{
        let hash = await handleTexturePignata(e.target.files[0])
        setBannerUploaded(`https://musure.mypinata.cloud/ipfs/${hash.data.IpfsHash}`)
    }

    const onChangeImageUpload = async(e)=>{
        let hash = await handleTexturePignata(e.target.files[0])
        setImageUploaded(`https://musure.mypinata.cloud/ipfs/${hash.data.IpfsHash}`)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col items-center justify-start gap-y-2'>
            <BasicInputImage
            srcImage={bannerUploaded || user?.banner || bannerDefaultMusure}
            classNameImgContainer='min-w-[100vw] absolute top-0 h-[100px] sm:h-[144px] lg:h-[209px] -mt-10 lg:-mt-16 xl:-mt-20'
            classnameImage={'w-full h-full object-cover object-center absolute top-0 h-[100px] sm:h-[144px] lg:min-h-[209px] xl:mt-20 '}
            idImage='banner'
            hoverImageClass={'text-[10px] top-4 sm:top-4 lg:top-10 sm:text-[14px] xl:top-24 m-auto left-[0] right-[0]'}
            onChange={onChangeBanner}
            />

            <BasicInputImage
            srcImage={imageUploaded || user?.image || logoDefaultMusure}
            classNameImgContainer={`w-[72px] h-[72px] sm:w-[125px] sm:h-[125px] lg:w-[174px] lg:h-[174px] border-2 border-white rounded-2xl z-30   relative`}
            classnameImage={`w-full h-full bg-black object-cover object-center rounded-2xl`}
            idImage='image'
            hoverImageClass={'top-0 bottom-0 m-auto left-0 right-0 text-center text-[10px] sm:text-[14px]'}
            onChange={onChangeImageUpload}
            />

            <div className='flex items-center justify-start text-pink self-start cursor-pointer mb-1' onClick={()=>setEditing(false)}>
                <Icon icon='eva:arrow-back-outline' className='text-[18px] sm:text-[20px]'/>
                <h5 className='text-[12px] sm:text-[14px] ml-0.5'>{t('gallery.goback')}</h5>
            </div>
            
            <BasicInput
                errors={errors}
                register={register}
                registerName='username'
                className='md:w-8/12 lg:w-6/12'
                inputClassName={`px-[9px] py-[7px] text-[14px]`}
                inputAttributes={{
                    placeholder: user?.username || userID ,
                    type: 'text',
                }}
                registerObj={{
                    required: false,
                    maxLength: {
                        value: 15,
                        message: t('gallery.form.usernameLength', { min: 3, max: 15 })
                    },
                    minLength: {
                        value: 3,
                        message: t('gallery.form.usernameLength', { min: 3, max: 15 })
                    },
                    validate: async (username) => {
                        if(username && username.length >= 3){
                            let res = await fetchBackend('GET', `/user/check?userID=${user._id}&property=username&value=${username}`, null, user)
                            if(res.data.available){
                                return true
                            } else{
                                return t('gallery.form.usernameUsed')
                            }
                        }
                    }
                }}
            />
            <BasicTextArea
                watch={watch}
                errors={errors}
                register={register}
                registerName='bio'
                textareaAttributes={{
                    maxLength: 120,
                    placeholder: t('gallery.form.bioPlaceholder')
                }}
                registerObj={{
                    required: false,
                    maxLength: {
                        value: 120,
                        message: t('gallery.form.bioLength', { min: 3, max: 120 })
                    },
                    minLength: {
                        value: 3,
                        message: t('gallery.form.bioLength', { min: 3, max: 120 })
                    }
                }}
                classNameTextArea={'h-[127px] mb-0 text-[14px]'}
                className=''
            />
            <div className='flex flex-col justify-start items-center w-full lg:flex-row lg:gap-x-4'>
                {
                    socialNetworks.map(sn=>{
                        return (
                            <div className='w-full relative md:w-8/12'>
                                <Icon icon={sn.icon} className={`text-[20px] ${errors[sn.type]? 'text-rederror': 'text-white'} absolute top-3.5 left-[10px]`}/>
                                <BasicInput
                                errors = {errors}
                                register = {register}
                                registerName = {sn.type}
                                className = ''
                                inputClassName = {`pl-[36px] pr-[9px] py-[7px] text-[14px] `}
                                inputErrorClassName={`pl-[36px] pr-[9px] py-[7px] text-[14px] text-rederror`}
                                inputAttributes = {{
                                    placeholder: user[sn.type] || t('gallery.form.socialNetPlaceholder'),
                                    type: 'text',
                                }}
                                registerObj = {{
                                    required: false,
                                    validate: async (value) => {
                                        if(value){
                                            let res = await fetchBackend('GET', `/user/check?userID=${user._id}&property=${sn.type}&value=${value}`, null, user)
                                            if(res.data.available){
                                                return true
                                            } else{
                                                return t('gallery.form.socialNetUsed',{socialNet: sn.type})
                                            }
                                        }
                                    }
                                }}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <button 
            disabled={!isValid}
            className='text-[14px] sm:text-[15px] lg:text-[16px] text-[#E671ED] cursor-pointer font-semibold mt-2 disabled:text-gray-500 disabled:cursor-not-allowed'
            type='submit'
            >
                {
                    processing?
                        <Icon icon={'line-md:loading-loop'} className={`text-[20px] lg:text-[22px] text-pink`}/>
                    :
                        t('gallery.save')

                }
            </button>
        </form>
    )
}
