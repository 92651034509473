//GENERAL
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'


//COMPONENTS
import TitleWithIcon from '../../components/TitleWithIcon/TitleWithIcon'

//UTILS
import { Icon } from '@iconify/react'
import { backgroundGradient, buttonUpload, containerStructureGeneral, structureCreationAssisted, structureViewsPanel } from '../../utils/constants/classes'
import DoubleCarrousel from '../../components/DoubleCarrousel/DoubleCarrousel'
import i18next from "i18next"
import BasicButton from '../../components/BasicButton/BasicButton'

export default function CreationAssist() {


    const { t } = useTranslation()
    const history = useHistory()
    const typeSelected = useSelector(state=> state.toolkit.type)
    

    return (
        <div className={`${containerStructureGeneral}  ${backgroundGradient}`}>

            <div className={`${structureCreationAssisted} text-white`}>

                <div className='w-full h-full flex flex-col justify-center gap-y-5 lg:w-full  items-center lg:flex-row lg:justify-start lg:items-start '>

                    <div className='w-full h-full flex flex-col justify-center gap-y-5 lg:w-6/12 lg:justify-start  items-center'>

                        <div className='w-full flex flex-col gap-y-5 lg:flex-col-reverse  '>
                            <button
                                onClick={() => history.push('/')}
                                className="w-fit flex justify-start items-center gap-x-2 text-[12px] sm:text-[16px] text-pinky" 
                            >
                                <Icon icon={"akar-icons:chevron-down"} className="text-[16px] sm:text-[24px] rotate-90" />
                                <span>{t("toolkitNft.selector")}</span>
                            </button>

                            <div className="w-full flex justify-center items-center lg:justify-start lg:items-start ">
                                <div className='w-9/12 sm:w-full flex justify-center items-center lg:justify-start lg:w-11/12 '>
                                    <TitleWithIcon title={t("toolkitNft.title")} icon={"twemoji:shinto-shrine"} />
                                </div>
                            </div>

                        </div>

                        {/* CARROUSEL MOBILE Y TABLET */}
                        <div className=' flex w-screen justify-center lg:hidden items-center'>
                            <DoubleCarrousel />
                        </div>

                        <div className={`flex w-full h-full flex-col items-center z-50 justify-start gap-y-10 pt-10 lg:pt-0 lg:gap-y-7 lg:h-full lg:items-start`}>

                            <div className="w-full flex justify-center items-center lg:justify-start">
                                <h5 className="w-9/12 text-center sm-w-full font-semibold text-[22px]  z-20 sm:w-10/12 sm:text-[38px] lg:text-[28px] lg:text-left">{t("toolkitNft.landing.concierge")}</h5>
                            </div>

                            <div className='w-full lg:w-11/12 flex flex-col gap-y-3 justify-center items-center'>
                                <span className="text-[12px] sm:text-[16px] text-center lg:text-left z-20">{t("toolkitNft.conciergeDescription")}</span>
                            </div>

                            <a className="w-full flex sm:justify-center lg:justify-start" href={i18next.language === 'es' ? "https://docs.google.com/forms/d/1s4Pb9UT03HSoJPGUQ2d8FA9TUKsq1IvKafnhcZICyuE" : "https://docs.google.com/forms/d/12loKAAfnZkb14QHnZcBAHq1dUs4oYmTOyCbsJSCKRR4"} target="_blank">
                                <BasicButton className={`${buttonUpload} w-full sm:w-8/12 lg:w-11/12 text-[16px] `} title={t("toolkitNft.verify")} />
                            </a>

                        </div>
                    </div>

                    <div className='absolute w-[50vw] top-0 right-0  justify-center h-[100%] hidden lg:flex items-start'>
                      <img src={typeSelected === 'Sneaker' ?"https://musure.mypinata.cloud/ipfs/Qme9MSvTbvDxuUoFLYj5JSbhSCGz63vrc9GB1s1C2MZuyR" : "https://musure.mypinata.cloud/ipfs/QmWp2YWGWmHMNm7Pw9Q9h8h1EBCFFKd2w8HWvEJUs9CJLP"} alt="" className='w-full h-full object-cover object-left-top' />
                    </div>
                     
                </div>


            </div>
        </div>
    )
}
