//GENERAL
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'


//COMPONENTS


//UTILS
import { downloadImage } from '../../utils/functions/downloadImg'
import { buttonDownload } from '../../utils/constants/classes'
import { Icon } from '@iconify/react'


export default function AlertDownload({ modalObj }) {

    const { t } = useTranslation()
    return (
        <div className="w-full h-full flex items-center justify-center bg-white rounded-2xl text-darkpurple font-montserrat z-50">

            <Icon onClick={modalObj.close} icon='bi:x' className={`absolute text-[30px] z-[60] cursor-pointer text-darkpurple right-2 top-2  `} />

            <div className='flex flex-col justify-between items-center w-11/12 sm:h-11/12 relative gap-y-4 sm:gap-y-7'>

                <img src={modalObj.img} className="w-[42px] sm:w-[78px] " alt="icon" />

                <div className='flex flex-col items-center justify-start gap-x-2'>
                    <h3 className='text-[16px] sm:text-[21px] font-bold text-darkpurple text-center'>{modalObj.title}</h3>
                    <span className='text-[12px] sm:text-[16px] text-black'>{modalObj.subtitle}</span>
                </div>

                <div className='flex w-11/12 items-center justify-center gap-x-4 rounded-3xl border border-[#8855C5] p-4  bg-[#F8F2FF]'>
                    <div className='w-2/12'>
                        <Icon icon={modalObj.icon2} className="text-[50px] sm:text-[80px] text-darkpurple" />
                    </div>

                    <div className='w-10/12 inline text-[12px] sm:text-[16px]'>
                        <Trans t={t} i18nKey={modalObj.text2} />
                    </div>

                </div>

                {modalObj.text3 && <div className='w-10/12 inline text-center  text-[12px] sm:text-[16px] text-black'>
                    <Trans t={t} i18nKey={modalObj.text3} />
                </div>}

                <button onClick={modalObj.close} className={`${buttonDownload} w-full sm:w-8/12 `}>{modalObj.button}</button>

                <a
                    onClick={(e) => downloadImage(e)}
                    target="_blank"
                    href={modalObj.type === "Sneaker" ? `https://musure.mypinata.cloud/ipfs/QmTA1KMKHcCFzYAotAvkhzvSMEeH8hXvGPNRD6wuZLBLtJ` : modalObj.type === "Airforce" ? "https://musure.mypinata.cloud/ipfs/QmTiAhRQs5vov9ZyaSQ3ZzCnM8VapH3JMYBDndncEKXGTe" : "https://musure.mypinata.cloud/ipfs/QmWLcXyqyq9huNHCzndXEidVfJko2mLXEdCTGto4mP8m9t"}
                    download="img"
                    className={`w-full h-full cursor-pointer inline text-center text-[12px] sm:text-[16px]`}
                >
                    <Trans href={modalObj.file} d t={t} i18nKey={modalObj.failDownload} />
                </a>

            </div>
        </div>

    )
}
