//GENERAL
import { useTranslation } from "react-i18next"
//COMPONENTS
import BasicInput from "../BasicInput/BasicInput"
import BasicTextArea from "../BasicTextArea/BasicTextArea"

//UTILS
import { Icon } from "@iconify/react"
import { Trans } from "react-i18next"
import { inputTitle } from "../../utils/constants/classes"

export default function ConfigureCreationForm({ register, errors, watch, termsConditions, setTermsConditions }) {

    const { t } = useTranslation()

    return (
        <div className='w-full flex flex-col items-start gap-y-5 justify-around lg:gap-y-8'>
            <BasicInput
                inputAttributes={{
                    placeholder: t('toolkitNft.viewConfigure.namePlaceholder'),
                    type: 'text'
                }}
                title={t('toolkitNft.viewConfigure.nameTitle')}
                classTitle={inputTitle}
                register={register}
                registerName='name'
                registerObj={{
                    required: {
                        value: true,
                        message: t('toolkitNft.viewConfigure.nameRequired')
                    },
                    maxLength: {
                        value: 20,
                        message: t('toolkitNft.viewConfigure.nameLength', { min: 3, max: 20 })
                    },
                    minLength: {
                        value: 3,
                        message: t('toolkitNft.viewConfigure.nameLength', { min: 3, max: 20 })
                    },
                }}
                errors={errors}
            />
            <BasicTextArea
                textareaAttributes={{
                    maxLength: 280,
                    placeholder: t('toolkitNft.viewConfigure.lorePlaceholder')
                }}
                title={t('toolkitNft.viewConfigure.loreTitle')}
                classTitle={inputTitle}
                watch={watch}
                errors={errors}
                register={register}
                registerObj={{
                    required: {
                        value: true,
                        message: t('toolkitNft.viewConfigure.loreRequired')
                    },
                    maxLength: {
                        value: 280,
                        message: t('toolkitNft.viewConfigure.loreLength', { min: 3, max: 280 })
                    },
                    minLength: {
                        value: 3,
                        message: t('toolkitNft.viewConfigure.loreLength', { min: 3, max: 280 })
                    }
                }}
                classNameTextArea={'h-[127px]'}
                registerName='lore'
                className=''
            />
            <div className="w-full relative flex justify-center items-center">

                <BasicInput
                    inputAttributes={{
                        placeholder: t('toolkitNft.viewConfigure.pricePlaceholder'),
                        type: 'number',
                        step: '.01'
                    }}
                    title={t('toolkitNft.viewConfigure.price')}
                    classTitle={inputTitle}
                    register={register}
                    registerName='price'
                    registerObj={{
                        required: {
                            value: false,
                        },
                        min: {
                            value: 18,
                            message: t('toolkitNft.viewConfigure.priceLength', { min: 18})
                        },

                    }}
                    errors={errors}
                />
                <span className="absolute right-3 top-8 ">{t('toolkitNft.viewConfigure.usdc')}</span>
            </div>
            <div className='w-full  flex justify-center items-center bg-white rounded-xl shadow-around text-[#59096E] py-1'>
                <div className='flex justify-start items-center w-11/12 gap-x-4'>
                    <Icon icon='clarity:warning-line' className='text-[60px]' />
                    <span className='text-[12px] sm:text-[14px]'>{t('toolkitNft.viewConfigure.alertDescription')}</span>
                </div>
            </div>

            <div className="flex w-11/12 h-full justify-start gap-x-2 items-center lg-w-full">

                <label htmlFor="terms" className="w-[21px] h-[18px] flex justify-center items-center ">

                    {termsConditions ? <Icon icon={"charm:tick"} className="text-[14px] absolute cursor-pointer" /> : null}
                    <input
                        id='terms'
                        className={`w-full h-full rounded-md border-2 border-white appearance-none cursor-pointer ${termsConditions && 'border-pinky'}`}
                        type={'checkbox'}
                        checked={termsConditions}
                        onClick={() => setTermsConditions(!termsConditions)}
                    />

                </label>
                <span className="text-[12px] md:text-[16px]">
                    <Trans i18nKey={"toolkitNft.viewCreate.acceptterms"} components={[<strong onClick={() => window.open('https://www.musure.world/terms-and-conditions', '_blank').focus()} className='text-pinky cursor-pointer underline'></strong>]} />
                </span>
            </div>
        </div>
    )
}
