//GENERAL
import { Icon } from '@iconify/react';
import React from 'react'
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { fetchBackend } from '../../utils/functions/logic/fetchBackend';

//COMPONENTS
import FormBenefits from '../FormBenefits/FormBenefits';

//UTILS

export default function BenefitsDescription({ nft, setUpdated, updated }) {

    const { t } = useTranslation();
    const user = useSelector(state => state.general.user)
    const [edit, setEdit] = useState();
    const [imageBenefit, setImageBenefit] = useState();

    const onSubmitEdit = async (data) => {
        let obj = {
            idNFT: nft._id,
            benefit: {
                overview: data.overview,
                description: data.description,
                image: imageBenefit
            },
            benefitID: data._id
        }
        await fetchBackend('PATCH', '/nft/benefit', obj, user)
        setEdit("")
        setUpdated(!updated)
    };

    const deleteBenefit = async (id) => {
        let obj = {
            idNFT: nft._id,
            benefitID: id
        }
        await fetchBackend('DELETE', '/nft/benefit', obj, user)
        setUpdated(!updated)
    };

    return (
        nft.benefits && nft.benefits?.length > 0 ?
            <div id='benefits-description' className='w-full flex flex-col items-start justify-start -mt-2 text-white mb-6 lg:mb-5 gap-y-4'>

                <div className='w-full flex flex-col items-start justify-start gap-y-2'>
                    <h3 className='text-[16px] sm:text-[18px] font-bold'>{t('detailNFT.benefitsDescriptionTitle')}</h3>
                    <h4 className={`text-[14px] sm:text-[16px] ${nft.benefits?.length > 0 && 'hidden'}`}>{t('detailNFT.benefitsDescriptionSubtitle')}</h4>
                </div>

                <div className='w-full flex flex-col items-center justify-start gap-y-4'>
                    {
                        nft.benefits.map(b => {
                            return (

                                edit === b._id ?
                                    <FormBenefits closeForm={setEdit} defaultValues={b} onSubmit={onSubmitEdit} image={imageBenefit} setImage={setImageBenefit} />
                                    :
                                    <div className='w-full min-h-[155px] sm:max-h-[260px] flex flex-col sm:flex-row justify-center items-center sm:items-start rounded-[10px] sm:gap-x-6 bg-black bg-opacity-50'>

                                        {b.image && <img src={b.image} alt='no-benefit' className='rounded-t-[10px] sm:rounded-t-none sm:rounded-l-[10px] min-h-[155px] max-h-[155px] sm:max-h-[260px] object-cover w-full sm:w-5/12' />}

                                        <div className={`w-11/12 h-auto  sm:w-full sm:mr-6 py-4 flex flex-col items-start justify-start ${b.image ? "" : " sm:px-3"}  gap-y-2`}>

                                            <div className='w-full h-full flex flex-col justify-start gap-y-2'>

                                                <div className='w-full  flex items-start justify-start text-[#E9C956]'>
                                                    <Icon icon='ant-design:star-filled' className='text-[18px] mr-0.5' />
                                                    <h4 className='text-[14px] sm:text-[16px] font-bold'>{b.overview}</h4>

                                                </div>
                                                <span className={`text-[14px]  ${b.image ? "sm:min-h-[70px] md:min-h-[110px]" : ""}  sm:text-[16px]`}>{b.description.includes("http") ? <a className='text-[#F44CE1] ' href={b.description}>{b.description}</a> : b.description}</span>



                                            </div>

                                            {
                                                user?.isValidated && nft.createdBy.firebaseID === user?.firebaseID ?
                                                    <div className='w-full  flex items-center justify-between  sm:gap-x-6 text-[#E671ED] font-semibold mt-2'>
                                                        <button className='flex items-center justify-start' onClick={() => deleteBenefit(b._id)}>
                                                            <Icon icon='fluent:delete-12-regular' className='text-[27px] mr-0.5' />
                                                            <span className='underline underline-offset-2 font-semibold'>{t('detailNFT.formBenefits.delete')}</span>
                                                        </button>
                                                        <button className='flex items-center justify-start' onClick={() => setEdit(b._id)}>
                                                            <Icon icon='eva:edit-outline' className='text-[27px] mr-0.5' />
                                                            <span className='underline underline-offset-2 font-semibold'>{t('detailNFT.formBenefits.edit')}</span>
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>

                                    </div>
                            )
                        })
                    }
                </div>

            </div>
            :
            null
    )
}
