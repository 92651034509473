//GENERAL
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from '../../config/firebaseConfig'

//COMPONENTS
import CardsNFTs from '../../components/CardsNFTs/CardsNFTs'
import ModelViewer from '../../components/ModelViewer/ModelViewer'

//UTILS
import { Icon } from '@iconify/react'
import { backgroundGradient, buttonUpload, containerStructureGeneral, structureViewsPanel } from '../../utils/constants/classes'
import ConfigureCreationForm from '../../components/ConfigureCreationForm/ConfigureCreationForm'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend'

export default function ConfigureCreation() {

    const textureSneaker = useSelector(state => state.toolkit.textureSneaker)

    const posterNFT = useSelector(state => state.toolkit.imageNFT)

    const creationType = useSelector(state => state.toolkit.type)

    const userData = useSelector(state => state.general.user)

    const [buttonPress, setButtonPress] = useState(false)

    const history = useHistory()

    const auth = getAuth(app);

    const [termsConditions, setTermsConditions] = useState(false)

    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 0.5, shouldUnregister: true });

    const { t } = useTranslation()

    const onSubmit = async (data) => {
      
        setButtonPress(true)
        let dataNft = {
            name: data.name,
            lore: data.lore,
            type: creationType,
            texture_1: textureSneaker,
            imageNFT: posterNFT,
            userID: userData?._id,
            price: data.price ? Number(data.price) : undefined
        }
       


        if (!userData) {
            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider)
                .then(async (result) => {
                    let obj =  {
                        email: result.user.email,
                        firebaseID: result.user.uid
                    }
                    let userInfo = await fetchBackend('POST',`/user`, obj)

                    let objNft = {
                        ...dataNft,
                        userID: userInfo.data.user._id
                    }
                    let newNFT = await fetchBackend('POST',`/nft`, objNft, userInfo.data.user)
        
                    setButtonPress(false)
                    history.push(`/detailnft/${newNFT.data.created._id}/new`)
                })
                .catch(error => console.log(error))
        } else {
            try {

                let newNFT = await fetchBackend('POST',`/nft`, dataNft, userData)

                
                setButtonPress(false)   
              
                history.push(`/detailnft/${newNFT.data.created._id}/new`)

            } catch (err) {
                console.log(err)
            }
        }

    }

    return (
        <div className={`${containerStructureGeneral}  ${backgroundGradient}`}>

            <div className={`${structureViewsPanel}`}>

                <div className="flex flex-col justify-center items-center gap-y-6  w-full h-full">

                    <div className='w-full text-left flex justify-start items-start'>
                        <button
                            onClick={() => history.goBack()}
                            className="w-auto flex justify-start items-center text-pinky gap-x-2 pb-7 text-[12px] md:text-[16px] lg:w-6/12 "
                        >
                            <Icon icon={"akar-icons:chevron-down"} className="text-[24px] sm:text-[36px] rotate-90" />
                            <span>{t("toolkitNft.viewCreate.backcreate")}</span>
                        </button>
                    </div>


                    <div className="flex flex-col-reverse gap-y-6 lg:flex-row justify-around  w-full h-full  text-white gap-x-10">

                        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-auto flex flex-col items-center justify-around gap-y-6 sm:gap-y-8 lg:gap-y-4 text-white lg:w-7/12">

                            <h1 className='font-semibold text-[22px] md:text-[38px] leading-tight lg:self-start'>{t("toolkitNft.viewCreate.title")}</h1>
                            {/* FORM TERMS AND CONDITIONS AND BUTTON*/}
                            <ConfigureCreationForm
                                register={register}
                                watch={watch}
                                errors={errors}
                                termsConditions={termsConditions}
                                setTermsConditions={setTermsConditions}
                            />

                            {/* ACA VA BOTON DE SUBMIT */}
                            <button 
                            type='submit' 
                            disabled={!termsConditions || !isValid || buttonPress} 
                            className={`${buttonUpload} w-full sm:w-8/12 lg:self-start sm:my-8 `}>
                                {buttonPress ? 
                                    <Icon icon='line-md:loading-loop' className='text-pink text-[20px]'/> 
                                : 
                                    t("toolkitNft.viewConfigure.saveCreation")
                                }
                            </button>

                        </form>

                        {/* VIEWER  */}
                        <div className="w-ful flex flex-col justify-between lg:w-5/12">

                            <div className="w-full flex flex-col justify-between h-[265px]  md:min-h-[471px] xl:min-h-[549px] lg:mb-14 ">
                                <ModelViewer 
                                textureSneaker={textureSneaker} 
                                photo={true}/>
                            </div>

                        </div>


                    </div>

                    <CardsNFTs />

                </div>


            </div>
        </div>
    )
}
