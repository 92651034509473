//GENERAL
import { useTranslation } from "react-i18next"

//UTILS
import { Icon } from "@iconify/react"
export default function SoftwaresEdit() {

    const { t } = useTranslation()
    const soft = [
        {
            name: t('toolkitNft.softwares.paint'),
            icon: "pepicons:paint-pallet"
        },
        {
            name: t('toolkitNft.softwares.corel'),
            icon: "file-icons:coreldraw-alt"
        },
        {
            name: t('toolkitNft.softwares.gimp'),
            icon: "cib:gimp"
        },
        {
            name: t('toolkitNft.softwares.affinity'),
            icon: "simple-icons:affinityphoto"
        },
        {
            name: t('toolkitNft.softwares.ilustrator'),
            icon: "file-icons:adobe-illustrator"
        },
        {
            name: t('toolkitNft.softwares.photoshop'),
            icon: "cib:adobe-photoshop"
        }
    ]

    return (
        <div className="grid grid-cols-3 sm:grid-cols-6  gap-y-6 w-11/12 z-50  sm:w-11/12  lg:w-full xl:w-10/12 ">
            {
                soft.map(e => {
                    return (
                        <div className="flex flex-col gap-y-1 justify-center items-center text-white">
                            <Icon icon={e.icon} className="text-[40px]" />
                            <span className="text-[12px] text-center">{e.name}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}
