//GENERAL
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

//COMPONENTS
import SoftwaresEdit from "../SoftwaresEdit/SoftwaresEdit"
import TitleWithIcon from "../TitleWithIcon/TitleWithIcon"
import SpanTutorial from "../SpanTutorial/SpanTutorial"
import ButtonDownload from "../ButtonDownload/ButtonDownload"

//UTILS
import { Icon } from "@iconify/react"
import { buttonUpload } from "../../utils/constants/classes"
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown"

export default function HeaderCreationType({ type, description, creation, setModal, openDrop, optionsAutomatic, setOpenDrop }) {

    const { t } = useTranslation()
    const history = useHistory()
    const [flagViewer, setFlagViewer] = useState(false)

    const textureSneaker = useSelector(state => state.toolkit.textureSneaker)
    const typeCreation = useSelector(state => state.toolkit.type)
    return (
        <div className={`flex flex-col  w-full gap-y-2 justify-around items-start ${type === 'manual' ? "mb-0" : "mb-6"}`}>

            {/* TITLE DESKTOP */}
            <div className="w-full hidden lg:flex ">
                <TitleWithIcon title={type !== "automatic" ? t("toolkitNft.title") : t("toolkitNft.title2")} icon={"twemoji:shinto-shrine"} />
            </div>

            {/* Button Back desktop */}
            <button
                onClick={() => history.push('/')}
                className="w-auto hidden justify-start items-center gap-x-2 text-pinky lg:flex"
            >
                <Icon icon={"akar-icons:chevron-down"} className="text-[16px] rotate-90" />
                <span>{t("toolkitNft.selector")}</span>
            </button>


            <div className={`flex w-full h-auto  flex-col gap-y-6 items-center   z-50 ${type === 'manual' ? 'justify-between' : 'justify-start gap-y-4'} lg:gap-y-3 lg:h-full lg:items-start`}>

                <div className="w-full flex justify-center items-center lg:justify-start">
                    <h5 className="w-9/12 text-center md-w-full font-semibold text-[22px]  z-20 sm:w-10/12 sm:text-[34px] lg:text-[28px] lg:text-left">{creation}</h5>
                </div>

                <span className="text-[12px] sm:text-[16px] text-center lg:text-left z-20">{description}</span>



                {/* BUTTONS MOBILE AND TABLET */}
                <div className={`w-full flex flex-col justify-between gap-y-2  sm:gap-x-4 lg:hidden`}>
                    
                    <div className={`w-full flex flex-col justify-between sm:flex-row gap-y-2 sm:gap-x-4 ${type === "manual" ? "sm:w-full" : "sm:w-8/12"} `}>
                        {type === 'manual' &&
                            <ButtonDownload
                                setModal={setModal}
                                type={typeCreation}
                                title={t("toolkitNft.buttonDownload")}
                            />
                        }

                        <ButtonDropdown
                            setOpenDrop={setOpenDrop}
                            openDrop={openDrop}
                            title={t(`toolkitNft.${type}ButtonUpload`)}
                            setFlagViewer={setFlagViewer}
                            flagViewer={flagViewer}
                            options={optionsAutomatic}
                        />
                    </div>

                    <div className="flex w-full justify-center items-center gap-x-2  text-white">
                        <Icon icon="mdi:information-outline" className="text-[15px] sm:text-[17px]" />
                        <span className="text-[12px] sm:text-[14px]">{t("toolkitNft.size")}</span>
                    </div>


                </div>



                {openDrop &&
                    <div className="flex justify-center items-center w-full lg:hidden py-4">
                        <button
                            disabled={!textureSneaker}
                            className={`${buttonUpload} w-full sm:w-8/12 flex  lg:hidden`}
                            onClick={() => history.push('/configure')}
                        >
                            {t("toolkitNft.startCreation")}
                        </button>


                    </div>}

                {type === 'manual' && <SoftwaresEdit />}

                <SpanTutorial classSpan={' justify-center py-4 lg:py-0 items-center text-center lg:text-left lg:justify-start'} />

            </div>
        </div>
    )
}
