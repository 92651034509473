import { SET_TEXTURES_ORIGINAL, SET_DATA_TOOLKIT, SET_TEXTURE_SNEAKER, SET_IMAGE_NFT, RESET_STATE, SET_CREATION_TYPE } from '../actions/actionTypes';

const initialState = {
    name: '',
    lore: '',
    type: 'Vehicle',
    textureSneaker: "",
    textureBase: "https://musure.mypinata.cloud/ipfs/QmYPLjhHwrzG6GbyiTdi2bTdsz4cSaBr9ZCVPsqT4DccpF",
    imageNFT: "",
    srcModel: "https://musure.mypinata.cloud/ipfs/QmUbg1fuez7xe7TpKVFexvTwPc3LzPuPUX3wFjwt25b674"
};

export const reducerToolkit = (state = initialState, { type, payload }) => {

    switch (type) {
        case SET_TEXTURES_ORIGINAL: {
            return {
                ...state,
                textureSneaker: payload.textureSneaker
            }
        }
        case SET_CREATION_TYPE:
            return {
                ...state,
                type: payload.type,
                srcModel: payload.assetBase,
                textureBase: payload.textureMaster_1
            }
        case SET_DATA_TOOLKIT:
            return {
                ...state,
                name: payload.name,
                lore: payload.lore
            }
        case SET_TEXTURE_SNEAKER:
            return {
                ...state,
                textureSneaker: payload

            }
        case SET_IMAGE_NFT:
            return {
                ...state,
                imageNFT: payload
            }
        case RESET_STATE:
            return state = initialState

        default: return state
    }
}
