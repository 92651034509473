import {  SET_FLAG_CHARGE_FILES, GET_ALL_CREATIONS, SET_TYPES_NFTS, SET_USER, GET_USER_CREATIONS } from '../actions/actionTypes';

const initialState = {
    flagFiles: false,
    creations: [],
    gallery: [],
    typesNFTs: [],
    user: null
};

export const reducerGeneral = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_FLAG_CHARGE_FILES:
            return {
                ...state,
                flagFiles: true
            }
        case GET_ALL_CREATIONS:
            return {
                ...state,
                creations: payload
            }
        case SET_TYPES_NFTS:
            return {
                ...state,
                typesNFTs: payload
            }
        case SET_USER:
            return {
                ...state,
                user: payload
            }
        case GET_USER_CREATIONS:
            return {
                ...state,
                gallery: payload
            }
        default: return state
    }
}
