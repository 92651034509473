import { Icon } from '@iconify/react';
import React from 'react'
import { useTranslation } from 'react-i18next'
import ShareLinkQr from '../ShareLinkQr/ShareLinkQr'

export default function UtilitiesAndQR({nft, place, handle8thWall}) {
   
    const {t} = useTranslation();
   

    return (
        <div className='w-full h-full flex flex-col justify-center items-center gap-y-4 mb-12'>

        <h4 className='text-[18px] font-semibold text-center sm:text-left w-full'>{t("detailNFT.explore", {creator: nft.createdBy.username || nft.createdBy.id})}</h4>

        <div className={`w-full flex flex-col items-start justify-start gap-y-6 ${place==='mobile'? 'lg:flex-row lg:items-start lg:gap-x-8' : ''}`}>

            <ShareLinkQr handle8thWall={handle8thWall} nftQR={nft?.QR} idNft={nft?._id} place={place}/>

            <div className="w-full h-full flex flex-col justify-start items-start sm:flex-row sm:items-center bg-[#000000]/50 shadow-around rounded-2xl py-3 px-4 gap-y-4 lg:flex-col">
                <div className='w-full flex flex-col items-start justify-start sm:flex-row sm:items-center lg:flex-col lg:items-start sm:gap-x-4 sm:w-8/12 lg:w-full'>
                    <Icon icon='bx:joystick' className='text-[60px] sm:text-[70px]'/>
                    <span className='text-[#E671ED] text-[21px] w-8/12 sm:w-full font-semibold'>{t("detailNFT.playCreation")}</span>
                </div>
                <span className='text-[14px] font-bold sm:w-4/12 sm:text-right lg:w-full lg:text-left'>{t("detailNFT.commingSoon")}</span>
            </div>

        </div>

    </div>
    )
}
