import React from 'react'
import { useState } from 'react';
import { buttonDownload } from '../../utils/constants/classes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BasicInput from '../../components/BasicInput/BasicInput';
import { useForm } from 'react-hook-form'

import { fetchBackend } from '../../utils/functions/logic/fetchBackend';

export default function AlertTransfer({ modalObj }) {

    const user = useSelector(state => state.general.user)

    const [userNotFound, setUserNotFound] = useState()

    const changeOwner = async (email) => {

        let objReq = {
            idNFT: modalObj.idNft,
            email: email,
        }
        try {
            return await fetchBackend('PATCH', '/nft/admin/transfer', objReq, user)

        } catch (err) {
            console.log(err)
        }
    }

    const onSubmitForm = async (data) => {
        let response = await changeOwner(data.mail)
        if (!response.data.transfered) {
            setUserNotFound(true)
            setTimeout(() => {
                setUserNotFound(false)
            }, 3000);
        }else{
            window.location.reload()
        }

    }

    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 1000 });


    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { t } = useTranslation()

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="flex items-center justify-center w-full h-full font-montserrat bg-white rounded-t-2xl sm:rounded-2xl">

            <div className='flex flex-col items-center justify-start w-10/12 h-11/12  sm:w-full gap-y-3'>

                <img src={modalObj.img} className='w-[40px] h-[40px] sm:w-[98px] sm:h-[98px]' />

                <div className={`flex w-full flex-col justify-center items-center text-center gap-y-2 text-darkpurple`}>


                    <div className='flex flex-col justify-center items-center'>

                        <h4 className="font-bold sm:text-[21px]">{modalObj.title}</h4>
                        <h4 className="font sm:text-[21px]">{modalObj.name}</h4>


                    </div>


                    <p className=" text-[12px] sm:text-[16px] text-[#4D3E3E]">{modalObj.text}</p>

                    <BasicInput
                        inputAttributes={{
                            placeholder: modalObj.placeholder,
                            type: 'mail'
                        }}
                        title={modalObj.inputName}
                        register={register}
                        registerName='mail'
                        registerObj={{
                            required: false,
                            validate: (value) => {
                                if (value.length > 0) {
                                    if (regexEmail.test(value)) {
                                        return true
                                    } else {
                                        return t("modal.alertQuestions.invalidEmail")
                                    }
                                } else {
                                    return true
                                }

                            }

                        }}
                        error2={true}
                        inputErrorClassName="text-black"
                        className='w-full sm:w-9/12 text-[#4D3E3E] text-[12px] sm:text-[12px]'
                        inputClassName='border text-[16px]  border-black rounded-2x1 text-black bg-transparent placeholder:text-gray-400 '
                        errors={errors} />

                </div>

                <div className='flex w-full flex-col gap-y-4 justify-between items-center'>

                    <button disabled={!isValid} type='submit' className={`${buttonDownload} w-full text-[14px] sm:text-[16px] h-[42px] sm:w-7/12`} >{modalObj.buttonConfirm}</button>
                    <button className={`${userNotFound ? 'text-rederror' : "text-[#9141C3]"} font-semibold text-[16px] `} onClick={() => modalObj.close()}>{userNotFound ? t("modal.modalTransfer.usernotfound") : modalObj.buttonCancel}</button>
                </div>


            </div>
        </form>
    )
}
