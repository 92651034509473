//GENERAL
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//UTILS
import { changeTexture, CreatePoster } from '../../utils/functions/logic/changeTexture'
import { setImageNFT } from '../../redux/actions/actionsToolkit';
import Loader from '../Loader/Loader'
import { setFlagChargeFiles } from '../../redux/actions/actionsGeneral';
import "./ModelViewer.scss"
import { buttonUpload } from '../../utils/constants/classes'
import { useState } from 'react';

const ModelViewer = ({ nftType, textureSneaker, flagViewer, photo, buttonFlag, modelProps, applyTexture }) => {

    const refModel = useRef(null)
    const dispatch = useDispatch()

    const creationType = useSelector(state => state.toolkit.type)
    const textureBase = useSelector(state => state.toolkit.textureBase)
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true);

    let modelType = require(`../../assets/models/${creationType}.glb`)

    useEffect(() => {

        if (refModel.current) {

            refModel.current.addEventListener('progress', (value) => {

                refModel.current.src = modelType
                if (value.detail.totalProgress === 1) {
                 
                    setLoading(false)
                    if (photo) {
                        //TIENE EL SET TIME OUT PORQUE NO CARGA LA TEXTURA, PUEDE SER POR LAS TEXTURAS PESADAS
                        setTimeout(() => {
                            CreatePoster()
                                .then((res) => {
                                    dispatch(setImageNFT(`https://musure.mypinata.cloud/ipfs/${res.data.IpfsHash}`))
                                    refModel.current.setAttribute('auto-rotate', true)
                                    refModel.current.setAttribute('auto-rotate-delay', 1000)
                                })
                        }, 1000);
                    }
                    if (!applyTexture) {
                        let textures = { textureSneaker }
                        changeTexture(refModel.current, "baseColorTexture", textures, creationType)

                    }

                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagViewer, textureSneaker, refModel.current, creationType, textureBase])



    useEffect(() => {
        let textures = { textureSneaker }
        if (!applyTexture) {
            changeTexture(refModel.current, "baseColorTexture", textures, creationType)
                .then((res) => { dispatch(setFlagChargeFiles()) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagViewer, textureSneaker, refModel.current, creationType])



    const handle8thWall = () => {
        if (creationType === "Sneaker") {
            window.location.href = `https://musureworld.8thwall.app/textureswap/?textLeft=${textureSneaker.slice(35)}`
        }
        if (creationType === "Vehicle") {
            window.location.href = `https://musureworld.8thwall.app/swapmusureghini/?textLeft=${textureSneaker.slice(35)}`
        }
    }

    return (
        <div className='w-full h-full relative flex z-30 flex-col bg-transparent'>
            <model-viewer
                ref={refModel}
                id={"api-frame"}
                src={modelType}
                ios-src={modelType}
                alt="A 3D model of Musure sneakers"
                rotation-per-second={"-26deg"}
                reveal='auto'
                seamless-poster
                auto-rotate={!photo}
                auto-rotate-delay={photo ? false : 1500}
                
                seamless
                camera-orbit='52deg 80deg 100%'
                style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    touchAction: 'none',
                    backgroundColor: 'inherit',
                }}
                exposure={creationType === "Airforce" ? '0.5' : '1.5'}
                loading="eager"
                shadow-intensity='1.5'
                shadow-softness='1'
                with-credentials={true}
                {...modelProps}
            >

                <button id="buttonAr" slot='ar-button' className="hidden" >

                </button>
                {creationType !== "Airforce" && <button id="buttonAr" onClick={() => handle8thWall()} className={`${buttonUpload}w-[60px] ${buttonFlag ? "absolute" : "invisible"} right-1 bottom-2`} >
                    {t("detailNFT.viewAr")}
                </button>
                }
                <div slot='progress-bar' className={`${loading ? 'flex justify-center items-center w-full h-full' : 'hidden'}`}>
                    <Loader />
                </div>

            </model-viewer>

        </div >

    )
}

export default ModelViewer