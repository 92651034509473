import { Icon } from '@iconify/react';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function DropdownLangNavbar({opt, openHamburger, classColorTrans}) {


   
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false)
    const {i18n, t} = useTranslation();

    return (
        <div className="w-full sm:w-auto flex flex-col items-center justify-center mt-4">

            {/*HEADER DROPDOWN*/}
            <div className={`w-full items-center justify-start  ${openHamburger ? 'opacity-1 flex' : 'opacity-0 hidden'} ${classColorTrans}`} onClick={()=>setOpenLanguageMenu(!openLanguageMenu)}>

                <Icon icon={opt.icon} className="text-[24px] mr-1.5"/>

                <div className='w-full flex items-center justify-center'>
                    <span className="w-[90%]">
                        {`${opt.text}: ${i18n.language.includes('en')? t(`navbar.en`) : t(`navbar.es`)}`}
                    </span>
                    <Icon icon='akar-icons:chevron-down' className={`w-[10%] transition-all ease-linear duration-150 ${openLanguageMenu? 'rotate-180': 'rotate-0'}`}/>
                </div>

            </div>

            {/*OPTIONS DROPDOWN*/}
            {
                openLanguageMenu &&
                <div className="flex flex-col items-start justify-center w-full mt-2 gap-y-2">
                    {opt.languageOptions.map(lang=>{
                        return (
                            <div className={`flex items-center justify-between pl-[26px] w-full ${classColorTrans}`} 
                            onClick={()=>{
                                i18n.changeLanguage(lang.type)
                                setOpenLanguageMenu(false)
                            }}>
                                <span>{lang.text}</span>
                                <Icon icon='ci:check-bold' className={`${i18n.language === lang.type ? 'flex' : 'hidden'}`}/>
                            </div>
                        )
                    })}
                </div>
            }

        </div>
    )
}
