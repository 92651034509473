import { Icon } from '@iconify/react'
import React from 'react'

export default function ItemNavbar({opt, onClickItem, classNameItem}) {

    return (
        <div 
        className={classNameItem}
        onClick={onClickItem}>
            <Icon icon={opt.icon} className="text-[24px] mr-1.5"/>
            <span className="w-full">{opt.text}</span>
        </div>
    )
}
