import { initializeApp } from 'firebase/app'


const firebaseConfig = {
    apiKey: "AIzaSyCZA5xp-PdCrbvsNM0nbmkw23XdbGGqeps",
    authDomain: "musure-world-webgame.firebaseapp.com",
    projectId: "musure-world-webgame",
    storageBucket: "musure-world-webgame.appspot.com",
    messagingSenderId: "846943937956",
    appId: "1:846943937956:web:2e5fc3721a7b74ab478d31"
};

export const app = initializeApp(firebaseConfig);