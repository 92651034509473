import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ButtonFilterSort from '../ButtonFilterSort/ButtonFilterSort';
import FilterAndSort from '../FilterAndSort/FilterAndSort';
import Searchbar from '../Searchbar/Searchbar';
import searchCreation from "../../utils/functions/logic/searchCreation";

export default function Toolbar({place}) {

    const {t} = useTranslation();
    const location = useLocation();
    let params = Object.fromEntries(new URLSearchParams(location.search))

    const [modalFilter, setModalFilter] = useState(false)
    const [openFilters, setOpenFilters] = useState({
        status: true,
        creationType: true
    })
    const [selectedFilters, setSelectedFilters] = useState(()=>{
        let filters = {
            status:{
                minted: false,
                notMinted: false,
            },
            creationType:{
                Sneaker: false,
                Pet: false,
                Vehicle: false,
                Skin: false,
                Wing: false,
                Graffitti: false,
                Dance: false,
                Bazooka: false,
            }
        };
        let params = Object.fromEntries(new URLSearchParams(location.search))
        if(params.creationType){
            filters.creationType[params.creationType] = true;
        }
        if(params.status === 'true'){
            filters.status.minted = true
        }
        if(params.status === 'false'){
            filters.status.notMinted = true
        }
        return filters
    })
    
    //SORT STATES
    const [modalSort, setModalSort] = useState(false)
    const [openSorts, setOpenSorts] = useState({
        createdAt: true
    })
    const [selectedSort, setSelectedSort] = useState(()=>{
        let sortBase = {
            createdAt: {
                ascending: false,
                descending: false
            }
        }
        let params = Object.fromEntries(new URLSearchParams(location.search))
        if(params.sortCreatedAt === 'ascending'){
            sortBase.createdAt.ascending = true
        }
        if(params.sortCreatedAt === 'descending'){
            sortBase.createdAt.descending = true
        }
        return sortBase
    })

    const filter = [
        {
            category: 'status',
            title: t('fashioncollection.filter.status.title'),
            options: [
                {
                    type: 'minted',
                    text: t('fashioncollection.filter.status.minted'),
                    value: true
                },
                {
                    type: 'notMinted',
                    text: t('fashioncollection.filter.status.notMinted'),
                    value: false
                }
            ]
        },
        {
            category: 'creationType',
            title: t('fashioncollection.filter.creationType.title'),
            options: [
                {
                    type: "Sneaker",
                    text: t('fashioncollection.filter.creationType.sneaker'),
                    value: "Sneaker"
                },
                {
                    type: "Airforce",
                    text: t('fashioncollection.filter.creationType.airforce'),
                    value: "Airforce"
                },
                {
                    type: "Pet",
                    text: t('fashioncollection.filter.creationType.pet'),
                    value: "Pet"
                },
                {
                    type: "Vehicle",
                    text: t('fashioncollection.filter.creationType.vehicle'),
                    value: "Vehicle"
                },
                {
                    type: "Skin",
                    text: t('fashioncollection.filter.creationType.skin'),
                    value: "Skin"
                },
                {
                    type: "Wing",
                    text: t('fashioncollection.filter.creationType.wing'),
                    value: "Wing"
                },
                {
                    type: "Graffitti",
                    text: t('fashioncollection.filter.creationType.graffitti'),
                    value: "Graffitti"
                },
                {
                    type: "Dance",
                    text: t('fashioncollection.filter.creationType.dance'),
                    value: "Dance"
                },
                {
                    type: "Bazooka",
                    text: t('fashioncollection.filter.creationType.bazooka'),
                    value: "Bazooka"
                },
            ]
        },
    ]
    
    const sort = [
        {
            category: 'createdAt',
            title: t('fashioncollection.sort.createdAt.title'),
            options: [
                {
                    type: 'descending',
                    text: t('fashioncollection.sort.createdAt.newestFirst'),
                    value: 'descending'
                },
                {
                    type: 'ascending',
                    text: t('fashioncollection.sort.createdAt.olderFirst'),
                    value: 'ascending'
                }
            ]
        },
    ]

    return (
        <div id="toolbar" className="w-full mt-8 md:mt-12 xl:mt-16 flex flex-col items-center justify-center md:flex-row md:gap-x-4 md:justify-between">
                    
                    <div className="w-full md:w-7/12 xl:w-5/12">
                        <Searchbar 
                        placeholder={params.name && params.name !== "null" ? params.name : t('fashioncollection.search')} 
                        onSearch={searchCreation}
                        place={place}
                        />
                    </div>
                    
                    <div className="flex items-center justify-between w-full gap-x-2 mt-1 md:mt-0 md:w-5/12 xl:w-3/12 relative">
                        <ButtonFilterSort 
                        flag= 'filter'
                        title={t('fashioncollection.filter.title')} 
                        icon='ph:funnel-bold'
                        modalFilter={modalFilter}
                        modalSort={modalSort}
                        setModalFilter={setModalFilter}
                        setModalSort={setModalSort}/>
                        
                        <FilterAndSort
                        flag = 'filter'
                        title={t('fashioncollection.filter.titleModal')} 
                        close={setModalFilter}
                        show={modalFilter}
                        options={filter}
                        openOptions={openFilters}
                        setOpenOptions={setOpenFilters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        selectedSort={selectedSort}
                        setSelectedSort={setSelectedSort}
                        place={place}
                        />
                        
                        <ButtonFilterSort 
                        flag= 'sort'
                        title={t('fashioncollection.sort.title')} 
                        icon='fluent:filter-16-filled'
                        modalFilter={modalFilter}
                        modalSort={modalSort}
                        setModalSort={setModalSort}
                        setModalFilter={setModalFilter}/>

                        <FilterAndSort 
                        flag = 'sort'
                        title={t('fashioncollection.sort.titleModal')} 
                        close={setModalSort}
                        show={modalSort}
                        options={sort}
                        openOptions={openSorts}
                        setOpenOptions={setOpenSorts}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        selectedSort={selectedSort}
                        setSelectedSort={setSelectedSort}
                        place={place}
                        />
                    </div>
                
                </div>
    )
}
