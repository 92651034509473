import { Icon } from '@iconify/react'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function BasicInputImage({classNameImgContainer, classnameImage, idImage, srcImage, hoverImageClass, onChange}) {

    const [hover, setHover] = useState(false)
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
   

    return (
        <div className={`${classNameImgContainer} group cursor-pointer`}  onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)}>

            <label htmlFor={idImage} className='z-[999] w-full h-full cursor-pointer'>
                
                {
                    srcImage?
                        <div className={classNameImgContainer}>
                            <img 
                            src={srcImage} 
                            className={`${classnameImage} ${loading ? 'brightness-[0.7]': 'brightness-100'} group-hover:brightness-[0.7] transition-all ease-linear duration-100 max-h-[152px] min-h-[68px] sm:min-h-[121px] sm:max-h-[174px] `}
                            
                            />
                            {/* <Icon icon={'akar-icons:cloud-upload'} className={`text-white text-[38px] absolute transition-all ease-linear duration-100 ${hover && !loading ? 'opacity-100': 'opacity-0'} ${hoverImageClass}`}/> */}
                            <div className={` flex flex-col items-center justify-center gap-y-1 ${hover && !loading ? 'opacity-100': 'opacity-0'} ${hoverImageClass} absolute`}>
                                <Icon icon={'akar-icons:cloud-upload'} className={`text-white text-[38px]`}/>
                                <h4 className=' text-white'>{t('detailNFT.formBenefits.imageUpload')}</h4>
                            </div>
                            {
                                loading &&  <Icon icon={'line-md:loading-loop'} className={`text-white text-[22px] absolute transition-all ease-linear duration-100 ${hoverImageClass}`}/>
                            }
                        </div>
                    :
                        <div className={`${classNameImgContainer} flex flex-col items-center justify-center gap-y-2`}>
                            <Icon icon={'akar-icons:cloud-upload'} className={`text-white text-[38px]`}/>
                            <h4 className='text-[14px] text-white text-opacity-40'>{t('detailNFT.formBenefits.imageUpload')}</h4>
                        </div>
                }
                
            </label>
            
            <input
            id={idImage}
            type='file'
            disabled={loading}
            accept='image/*'
            className='hidden'
            onChange={async(e)=>{
                setLoading(true)
                await onChange(e)
                setLoading(false)
            }}
            />

        </div>
    )
}
