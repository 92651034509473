import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import CardNft from '../CardNft/CardNft'
import { getItemsMarket } from '../../redux/actions/actionsGeneral'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend'


export default function GridCreationDetail({nft}) {

    const [moreCreations, setMoreCreations] = useState([])
   

    const otherCreations = useSelector(state=>state.general.creations)
    const params = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory();
    const {t} = useTranslation();



    useEffect(() => {
        fetchBackend('GET', `/nft/filterSort?userID=${nft.createdBy._id || null}`)
        .then((res) => {
            let creationsFiltered = res.data.results.filter(c=> c._id !== nft._id)
            setMoreCreations(creationsFiltered.slice(0,8))
            if(creationsFiltered.length === 0){
                dispatch(getItemsMarket(Object.fromEntries(new URLSearchParams(location.search))))
            }
        })
    }, [nft, params.idNft])

    return (
        <div className='w-full h-full flex flex-col justify-start items-start gap-y-3'>

            <div className='w-full flex justify-center items-center sm:justify-between'>
                <h5 className='font-semibold text-center self-center sm:text-left sm:text-[18px]'>{moreCreations.length > 0 ? t("detailNFT.moreaccessories", { creator: nft.createdBy.username || t("detailNFT.thisCreator") }): t("detailNFT.othercreations")}</h5>
                <span onClick={() => history.push('/fashioncollections')} className='text-[#E671ED] hidden sm:flex cursor-pointer sm:text-[15px] lg:text-[16px]'>{t("detailNFT.seemore")}</span>
            </div>

            <div className='w-full h-full grid grid-cols-2 sm:grid-cols-3  gap-y-2 gap-x-2 justify-items-center sm:gap-y-4 lg:gap-y-8 lg:gap-x-4 xl:gap-x-6'>
                {moreCreations.length > 0 ? moreCreations.map(e => <CardNft key={e._id} nft={e}/> ) : 
                otherCreations.slice(0,8).map(e => <CardNft key={e._id} nft={e}/> )}
            </div>

            <span 
            onClick={()=>history.push('/fashioncollections')} 
            className='text-[#E671ED] text-center w-full sm:hidden cursor-pointer text-[14px] '>
                {t("detailNFT.seemore")}
            </span>

        </div>
    )
}
