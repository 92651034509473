import { Icon } from "@iconify/react";
import { inputWhiteTransp } from "../../utils/constants/classes";


export default function ButtonFilterSort({title, icon, modalFilter, setModalFilter, modalSort, setModalSort, flag}) {


    return (
        <div 
        className={`${inputWhiteTransp} flex justify-between items-center cursor-pointer`}
        onClick={()=>{
            if(flag === 'filter'){
                setModalSort(false)
                setModalFilter(!modalFilter)
            }
            if(flag === 'sort'){
                setModalFilter(false)
                setModalSort(!modalSort)
            }
        }}
        >
            <h4 className="text-[12px] sm:text-[14px] lg:text-[16px]">{title}</h4>
            <Icon icon={icon} className="text-[20px]"/>
        </div>
    )
}
