//GENERAL
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'

//COMPONENTS

//UTILS
import terms from '../../assets/images/icons/terms.svg'
import { buttonDownload } from '../../utils/constants/classes'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend'


export default function AlertTermsConditions({ user, setModalTerms }) {

  const { t } = useTranslation()
  const [termsConditions, setTermsConditions] = useState()

  let changeTerms = async () => {
    try {
      let obj = {
        userID: user._id,
        termsAccepted: true
      }
      let response = await fetchBackend('PATCH', `/user`, obj, user)
      if (response.data.user.termsAccepted) {
        setModalTerms(false)
      }

    } catch (error) {
      console.log(error)
      setModalTerms(false)
    }
  }

  return (
    <div className="flex flex-col items-center justify-around w-full h-full font-montserrat bg-white text-darkpurple rounded-t-2xl sm:rounded-2xl">

      <div className='flex flex-col w-full justify-center gap-y-4 items-center'>
        <img src={terms} className="w-[45px] sm:w-[65px]" alt="Terms" />
        <h1 className='text-darkpurple font-bold text-[16px] sm:text-[21px] w-7/12 text-center'>{t("modal.alertTermsConditions.title")}</h1>

      </div>

      <div className='pl-2 flex w-10/12 leading-tight justify-center align-top items-center'>

        <ul className='list-decimal text-[12px] sm:text-[16px] text-[#4D3E3E]'>

          <li className='flex-w-full'>
            {t("modal.alertTermsConditions.text1")}
          </li>

          <li>
            {t("modal.alertTermsConditions.text2")}
            <ul className='list-disc pl-3'>
              <li>
                {t("modal.alertTermsConditions.subtext1")}
              </li>
              <li>
                {t("modal.alertTermsConditions.subtext2")}
              </li>
            </ul>


          </li>
        </ul>
      </div>

      <div className="flex w-11/12  justify-center gap-x-2 items-center lg-w-full">

        <label htmlFor="terms" className="w-[21px] h-[18px] flex justify-center items-center ">

          {termsConditions ? <Icon icon={"charm:tick"} className="text-[14px] absolute cursor-pointer" /> : null}
          <input
            id='terms'
            className={`w-full h-full rounded-sm border-2 border-black appearance-none cursor-pointer ${termsConditions && 'border-pinky'}`}
            type={'checkbox'}
            checked={termsConditions}
            onClick={() => setTermsConditions(!termsConditions)}
          />

        </label>
        <span className="text-[12px] md:text-[16px]">
          <Trans i18nKey={"modal.alertTermsConditions.subtitle"} components={[<strong onClick={() => window.open('https://www.musure.world/terms-and-conditions', '_blank').focus()} className='text-[#F44CE1] cursor-pointer underline'></strong>]} />
        </span>

      </div>

      <button onClick={() => changeTerms()} disabled={!termsConditions} className={`${buttonDownload} w-10/12 sm:w-7/12`}>{t("modal.alertTermsConditions.button")}</button>

    </div>
  )
}
