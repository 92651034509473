import { SET_TEXTURES_ORIGINAL, SET_DATA_TOOLKIT, SET_TEXTURE_SNEAKER, SET_IMAGE_NFT, RESET_STATE, SET_CREATION_TYPE } from "./actionTypes"

export const setDataToolkit = (payload) => {
    return {
        type: SET_DATA_TOOLKIT,
        payload
    }
}

export const setTextureSneaker = (payload) => {
    return {
        type: SET_TEXTURE_SNEAKER,
        payload
    }
}

export const setCreationType = (payload) => {
    return {
        type: SET_CREATION_TYPE,
        payload
    }
}

export const setImageNFT = (payload) => {
    return {
        type: SET_IMAGE_NFT,
        payload
    }
}

export const setTexturesOriginal = (payload) => {
    return {
        type: SET_TEXTURES_ORIGINAL,
        payload
    }
}

export const resetState = () => {
    return {
        type: RESET_STATE
    }
}