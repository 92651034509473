import React from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchBackend } from '../../utils/functions/logic/fetchBackend';
import BasicInput from '../BasicInput/BasicInput'
import BasicTextArea from '../BasicTextArea/BasicTextArea'
import ButtonsAndWarning from '../ButtonsAndWarning/ButtonsAndWarning';

export default function FormNameDescription({ nft, setFormOn, setUpdated, updated }) {

    const { t } = useTranslation();
    const user = useSelector( state => state.general.user)
    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 500, defaultValues: nft });

    const onSubmit = async (data) => {
        let obj = {
            idNFT: data._id,
            name: data.name,
            lore: data.lore,
            price: data.price ? Number(data.price) : undefined
        }
        await fetchBackend('PATCH', '/nft', obj, user)
        
        setFormOn(false)
        setUpdated(!updated)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={`w-full md:w-10/12 lg:w-full md:self-center flex flex-col items-center justify-start gap-y-3 `}>

            {/* <ButtonsAndWarning setFormOn={setFormOn} isValid={isValid} classNameContainer={'hidden lg:flex'} /> */}
            <div className='w-full flex flex-col items-start justify-start mt-2 lg:mt-0'>
                <span className='text-[14px] sm:text-[16px] font-semibold '>{t("detailNFT.name")}</span>
                <BasicInput
                    errors={errors}
                    register={register}
                    registerName='name'
                    className=''
                    inputClassName={`px-[9px] py-[7px] text-[14px] sm:text-[15px] lg:text-[16px]`}
                    inputAttributes={{
                        placeholder: nft.name,
                        type: 'text',
                    }}
                    registerObj={{
                        required: {
                            value: true,
                            message: t('detailNFT.formNameDescription.nameRequired')
                        },
                        maxLength: {
                            value: 20,
                            message: t('detailNFT.formNameDescription.nameLength', { min: 3, max: 20 })
                        },
                        minLength: {
                            value: 3,
                            message: t('detailNFT.formNameDescription.nameLength', { min: 3, max: 20 })
                        },
                    }}
                />
            </div>
            <div className='w-full flex flex-col items-start justify-start mt-2 lg:mt-0'>
                <span className='text-[14px] sm:text-[16px] font-semibold -mb-1'>{t("detailNFT.description")}</span>
                <BasicTextArea
                    watch={watch}
                    errors={errors}
                    register={register}
                    registerName='lore'
                    textareaAttributes={{
                        maxLength: 280,
                        placeholder: nft.lore
                    }}
                    registerObj={{
                        required: {
                            value: true,
                            message: t('detailNFT.formNameDescription.loreRequired')
                        },
                        maxLength: {
                            value: 280,
                            message: t('detailNFT.formNameDescription.loreLength', { min: 3, max: 280 })
                        },
                        minLength: {
                            value: 3,
                            message: t('detailNFT.formNameDescription.nameLength', { min: 3, max: 200 })
                        }
                    }}
                    classNameTextArea={'mb-0 h-[180px] sm:h-[120px] text-[14px] sm:text-[15px] lg:text-[16px]'}
                    className=''
                />
            </div>
            <div className='w-full flex flex-col items-start justify-start mt-2 lg:mt-0'>
                <span className='text-[14px] sm:text-[16px] font-semibold '>{t("detailNFT.price")}</span>

                <div className='w-full flex justify-center items-center relative'>

                    <BasicInput
                        errors={errors}
                        register={register}
                        registerName='price'
                        className=''
                        inputClassName={`px-[9px] py-[7px] text-[14px] sm:text-[15px] lg:text-[16px]`}
                        inputAttributes={{
                            placeholder: nft.price,
                            type: 'number',
                            step: '.01'
                        }}
                        registerObj={{
                            required: {
                                value: false,
                            },
                            min: {
                                value: 18,
                                message: t('toolkitNft.viewConfigure.priceLength', { min: 18})
                            },
                        }}
                    />
                    <span className="absolute right-3 top-3 text-gray-200 ">{t('toolkitNft.viewConfigure.usdc')}</span>
                </div>
            </div>


            <ButtonsAndWarning setFormOn={setFormOn} isValid={isValid} classNameContainer={'flex '} />

        </form>
    )
}
