
export default function HamburguerButton({openHamburger, setOpenHamburger, containerClassname}) {

    const lineClass = `bg-white rounded-full h-1 absolute right-0 transition-all ease-linear duration-150 `

    return (
        <div className={containerClassname} onClick={()=>setOpenHamburger(!openHamburger)}>
            
            <span className={`top-[4px] ${lineClass} ${openHamburger ? 'w-0' : 'w-[22.65px]'}`}/>
            <span className={`top-[12px] w-[22.65px] ${lineClass} ${openHamburger ? 'rotate-45 top-[13px] w-[23.65px]' : 'rotate-0'}`}/>
            <span className={`top-[12px] w-[22.65px] ${lineClass} ${openHamburger ? '-rotate-45 top-[13px] w-[23.65px]' : 'rotate-0'}`}/>
            <span className={`top-[20px] ${lineClass} ${openHamburger ? 'w-0' : 'w-[22.65px]'}`}/>

        </div>
    )
}
