import axios from "axios"
import { newServerUrl } from "../../../config/configServer"

export const fetchBackend = (method, path, data, user) => {
    return axios({
        baseURL: newServerUrl,
        url: path,
        method: method,
        headers: user ? { "userid": user._id} : null,
        data: data
    })
}