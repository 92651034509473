//GENERAL
import { backgroundGradient, containerStructureGeneral, structureViewsPanel } from '../../utils/constants/classes'
import { useTranslation } from 'react-i18next'

//COMPONENTS
import DropdownInfo from '../../components/DropdownInfo/DropdownInfo'

//UTILS
import CardsNFTs from '../../components/CardsNFTs/CardsNFTs'
import Automate from '../../assets/images/icons/CreationAutomate.svg'
import Personalize from '../../assets/images/icons/CreationPersonalize.svg'
import Manual from '../../assets/images/icons/CreationManual.svg'
import SpanTutorial from '../../components/SpanTutorial/SpanTutorial'
import { useDispatch, useSelector } from 'react-redux'
import { setTextureSneaker } from '../../redux/actions/actionsToolkit'
import { useEffect } from 'react'
import TitleWithIcon from '../../components/TitleWithIcon/TitleWithIcon'
import ModelViewer from '../../components/ModelViewer/ModelViewer'

export default function LandingNft() {

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const textureSneaker = useSelector(state => state.toolkit.textureSneaker)

    useEffect(() => {
        if (textureSneaker) {
            dispatch(setTextureSneaker(""))

        }
    }, [])

    return (
        <div className={`${containerStructureGeneral} ${backgroundGradient}`}>

            <div className={`${structureViewsPanel}`}>
                <div className="flex flex-col justify-center items-center gap-y-12 w-full h-full relative ">

                    <div className="flex justify-between items-start w-full h-full text-white gap-x-10 ">

                        <div className="flex flex-col w-full gap-y-3 h-auto items-center justify-between sm:gap-y-6 lg:gap-y-0 lg:h-[560px] lg:items-start lg:w-[55%] xl:w-[45%]">

                            <TitleWithIcon title={t("toolkitNft.title")} icon={"twemoji:shinto-shrine"} />

                            <div className="flex w-11/12 h-[287px] sm:h-[471px] lg:hidden  ">
                                <ModelViewer applyTexture={true}/>
                            </div>

                            <span className="w-11/12 text-center text-[12px] pb-5 px-1 sm:pb-0  sm:px-0 sm:text-[16px] lg:text-left lg:w-10/12 lg:self-start lg:mt-4">{t("toolkitNft.landing.description")}</span>

                            <SpanTutorial classSpan={'hidden sm:inline py-4 text-center lg:text-left'} />

                            <div className=' flex flex-col w-full h-auto gap-y-4 sm:gap-y-3 sm:flex-row sm:justify-around lg:justify-between sm:items-start lg:gap-x-4 '>
                                <DropdownInfo urlPush={'/automatic'} text={t("toolkitNft.landing.automatic")} text2={t("toolkitNft.landing.textAuto")} icon={Automate} />
                                <DropdownInfo urlPush={'/manual'} text={t("toolkitNft.landing.manual")} text2={t("toolkitNft.landing.textManual")} icon={Manual} />
                                <DropdownInfo urlPush={'/concierge'} text={t("toolkitNft.landing.concierge")} text2={t("toolkitNft.landing.textPersonalize")} icon={Personalize} />
                            </div>

                        </div>

                        <div className="hidden lg:flex w-[560px]  h-[560px] lg:w-[45%] xl:w-[55%]">
                            <ModelViewer applyTexture={true} />
                        </div>

                    </div>

                    {/* SPAN MOBILE */}
                    <SpanTutorial classSpan={'flex sm:hidden justify-center font-semibold text-[12px] items-center text-left'} />

                    <CardsNFTs />

                </div>

            </div>
        </div>
    )
}
