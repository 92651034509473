import { Icon } from '@iconify/react';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import bannerMusure from '../../assets/images/bannerMusure.svg'
import logoMusure from '../../assets/images/logoGallery.png'

export default function DataHeaderGallery({setEditing, owner}) {

    const {t} = useTranslation();
    const location = useLocation()
    const user = useSelector(state=> state.general.user)
    
    return (
        <div className='w-full flex flex-col items-center justify-center text-white gap-y-2'>

            <img 
            src={owner?.banner || bannerMusure} 
            className='min-w-[100vw] absolute top-0 object-cover object-center h-[100px] sm:h-[144px] lg:h-[209px] -mt-10 lg:-mt-16 xl:-mt-20'
            />
            <img 
            src={owner?.image || logoMusure} 
            className={`w-[72px] h-[72px] sm:w-[125px] sm:h-[125px] lg:w-[177px] lg:h-[177px] bg-black object-center object-cover border-2 border-white rounded-2xl z-30 mt-2`}
            />

            <div className='flex items-center justify-center w-full'>
                <h2 className='text-[22px] font-semibold sm:text-[28px] lg:text-[34px] mr-1 sm:mr-1 lg:mr-2'>{ owner?.username || (location.pathname.includes(user?._id) ?t("gallery.defaultName") : t("detailNFT.unnamed")) }</h2>
                {owner?.isValidated &&
                    <div className='flex items-center justify-center relative'>
                        <span className='w-[11px] h-[11px] sm:w-[14px] sm:h-[14px] md:w-[15px] md:h-[15px] bg-white absolute z-10 rounded-full'/>
                        <Icon icon={"ic:round-verified"} className='text-[#31B5FF] text-[16px] sm:text-[20px] md:text-[22px] z-20'/>
                    </div>
                }
            </div>
            <h4 className='text-[14px] text-center sm:text-[15px] lg:text-[16px]'>{ owner?.bio || t('gallery.defaultBio') }</h4>

            <div className='flex flex-col items-center justify-center gap-y-1'>
                    {
                    owner?.instagram &&
                        <div className='flex items-center justify-start'>
                            <Icon icon='akar-icons:instagram-fill' className='text-[20px] mr-2'/>
                            <h5 className='text-[14px] sm:text-[15px] lg:text-[16px]'>{owner?.instagram}</h5>
                        </div>
                    }
                    {
                    owner?.twitter &&
                        <div className='flex items-center justify-start'>
                            <Icon icon='radix-icons:twitter-logo' className='text-[20px] mr-2'/>
                            <h5 className='text-[14px] sm:text-[15px] lg:text-[16px]'>{owner?.twitter}</h5>
                        </div>
                    }
                    {
                    owner?.tiktok &&
                        <div className='flex items-center justify-start'>
                            <Icon icon='ph:tiktok-logo' className='text-[20px] mr-2'/>
                            <h5 className='text-[14px] sm:text-[15px] lg:text-[16px]'>{owner?.tiktok}</h5>
                        </div>
                    }
            </div>

            {
                owner._id === user?._id &&

                <div className='flex items-center justify-center text-[#E671ED] cursor-pointer' onClick={()=>setEditing(true)}>
                    <Icon icon='eva:edit-outline' className='text-[20px] mr-0.5 mb-[1px]'/>
                    <h5 className='text-[14px] sm:text-[15px] lg:text-[16px] font-semibold'>{t('gallery.edit')}</h5>
                </div>
            }

        </div>
    )
}
