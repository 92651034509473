//GENERAL
import { useState } from "react";
import { useHistory } from 'react-router-dom';

export default function DropdownInfo({ icon, text, text2, urlPush }) {

    const history = useHistory()
    const [flagStyles, setFlagStyles] = useState(false)

    return (
        <div
            className={`${flagStyles ? 'bg-darkpurple' : 'bg-transparent'} flex w-full  h-[91px] sm:h-[194px] sm:w-full sm:px-2  lg:px-0 sm:flex-col sm:justify-start sm:items-center lg:w-[120px]  xl:w-[145px] lg:h-[278px] rounded-3xl`}
            onMouseLeave={() => setFlagStyles(false)}
        >

            <div
                className={`${flagStyles ? 'lg:h-[145px]' : 'lg:h-[199px]'} w-full sm:h-full flex sm:flex-col gap-y-2 p-2 cursor-pointer justify-start sm:gap-y-0 sm:justify-around items-center text-center bg-gradient-to-b from-buttonviolet to-dropdownBg z-20 rounded-3xl shadow-around`}
                onClick={() => history.push(urlPush)}
                onMouseEnter={() => setFlagStyles(true)}
            >

                <div className={`${flagStyles ? 'h-full' : 'lg:h-[130px]'} flex  w-4/12 sm:w-5/12 lg:w-full justify-center items-center`}>
                    <img src={icon} className={"w-[42px] sm:w-[64px] lg:w-[76px] sm:pt-2 h-auto"} />
                </div>


                {/* MOBILE */}
                <div className="w-full flex flex-col justify-start items-start  sm:hidden ">
                    <span className=" w-full  sm:hidden sm:w-full   text-white text-[14px] font-semibold text-left">{text}</span>
                    <span className="text-[12px] text-left">{text2}</span>
                </div>

                {!flagStyles && <span className=" w-6/12 hidden sm:flex sm:w-full sm:pt-1 lg:pt-0 lg:w-10/12 text-white sm: justify-center items-start text-[14px] font-semibold">{text}</span>}

                <div className='w-full z-10  hidden  sm:flex lg:hidden justify-center items-center text-center text-[12px]'>
                    <span>{text2}</span>
                </div>
            </div>

            {flagStyles &&
                <div className='w-11/12 z-10 h-[133px] rounded-3xl hidden lg:flex justify-center items-center text-center text-[14px]'>
                    <span>{text2}</span>
                </div>
            }

        </div >
    )
}
