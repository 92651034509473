//GENERAL
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import { useState } from 'react';
import { urlBase } from '../../config/configServer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
//COMPONENTS
import FastAverageColor from 'fast-average-color'
import Tooltip from "rc-tooltip";
import CopyToClipboard from 'react-copy-to-clipboard';
import { setCreationType } from '../../redux/actions/actionsToolkit';

//UTILS

export default function CardNft({ nft }) {

    const history = useHistory();
    const { t } = useTranslation();
    const [copy, setCopy] = useState(false)
    const [background, setBackground] = useState('#eeeeee');

    const dispatch = useDispatch()

    const fac = new FastAverageColor();
    fac.getColorAsync(nft.imageNFT, {
        ignoredColor: [0, 0, 0, 0]
    })
        .then((res) => {
            setBackground(res.hexa)
        })



    return (
        <div className='flex flex-col z-10 w-full min-h-[210px] h-[100%] max-w-[160px] sm:max-w-[200px] sm:min-h-[260px] md:min-h-[316px] md:max-w-[224px] lg:min-w-[184px] lg:max-w-[234px] lg:min-h-[302px] xl:min-h-[362px] xl:max-w-[244px] shadow-around rounded-2xl cursor-pointer'>

            <div className={`h-[67%] rounded-t-2xl relative flex items-center justify-center `} style={{ backgroundColor: `${background}` }}>
                <div className='absolute top-2 sm:top-3 left-3  w-[74px] flex justify-start gap-x-1 items-start  z-50'>
                    {nft.benefits.length > 0 && <span className='bg-[#E9C956] w-[35px] h-[24px] rounded-2xl flex items-center justify-center'><Icon icon='ant-design:star-filled' className='text-black text-[14px] sm:text-[14px]' /></span>}
                    {nft.onSale && <span className='bg-[#00C108] w-[35px] h-[24px] rounded-2xl flex items-center justify-center'><Icon icon='heroicons-solid:shopping-cart' className='text-black text-[14px] sm:text-[15px]' /></span>}
                </div>
                <img src={nft.imageNFT} className={`w-full h-full object-cover object-center relative z-30`} alt="image-creation"
                    onClick={() => {
                        dispatch(setCreationType(nft))
                        history.push(`/detailnft/${nft._id}`)
                    }} />
                <Tooltip
                    placement='right'
                    trigger={['none']}
                    overlay={<span className="text-xs">{t('gallery.tooltipShare')}</span>}
                    overlayClassName={'w-[158px] h-[51px] z-[888] text-white bg-gray-800 flex justify-center items-center rounded-2xl '}
                    visible={copy}
                    destroyTooltipOnHide={true}
                >
                    <CopyToClipboard
                        text={`${urlBase}/detailnft/${nft._id}`}
                        onCopy={() => {
                            setCopy(true)
                            setTimeout(() => {
                                setCopy(false)
                            }, 1300)
                        }}
                    >
                        <Icon icon='ant-design:share-alt-outlined' className='cursor-pointer text-[22px] md:text-[26px] text-white absolute top-2 right-3 z-50' />
                    </CopyToClipboard>
                </Tooltip>

                <span className='w-[35%] h-[35%] absolute z-20 rounded-full' style={{ backgroundColor: `${background}`, boxShadow: `0px 0px 20px 30px ${background}`, filter: 'brightness(120%)' }} />
            </div>


            <div className='flex justify-center items-center w-full h-[33%] relative bg-[#6C347B] rounded-b-2xl' onClick={() => history.push(`/detailnft/${nft._id}`)}>

                <div className='flex flex-col items-start justify-start gap-y-0.5 sm:gap-y-1  relative w-10/12 h-9/12'>

                    <div className='flex relative justify-start items-center w-full'>
                        <span className='text-[10px] md:text-[12px] text-white'>{nft.createdBy.username || t("detailNFT.unnamed")}</span>
                        {nft.createdBy.isValidated &&
                            <div className='flex items-center justify-center relative ml-0.5 lg:ml-0'>
                                <span className='w-[6px] h-[6px] sm:w-[9px] sm:h-[9px] md:w-[12px] md:h-[12px] bg-white absolute z-10 rounded-full lg:ml-1 md:ml-0.5' />
                                <Icon icon={"ic:round-verified"} className='text-[#31B5FF] text-[11px] sm:text-[13px] md:text-[16px] z-20 lg:ml-1 md:ml-0.5' />
                            </div>
                        }
                    </div>
                    {nft.price !== undefined
                        &&
                        <div className='w-full flex items-center justify-start gap-x-3 text-white'>
                            <img className="w-[17px] sm:w-[22px]" src="https://musure.mypinata.cloud/ipfs/QmNcZiNJh5dfydir52Qk5dMorYUBN7DwmcQ9Kd81vjNSxL" alt="USDC" />
                            <p className='text-[14px] sm:text-[18px] '>{nft.price}</p>
                            <span className='text-[10px] sm:text-[14px] '>{t('toolkitNft.viewConfigure.usdc')}</span>
                        </div>}

                    <span className='text-white font-semibold text-[12px] sm:text-[14px] md:text-[16px] leading-snug'>{nft.name}</span>

                </div>
            </div>

        </div>
    )
}
