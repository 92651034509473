//GENERAL
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
//COMPONENTS
import Loader from "../Loader/Loader"
//UTILS
import { Icon } from "@iconify/react"
import { useDispatch, useSelector } from "react-redux"
import { setTypesNFTs } from "../../redux/actions/actionsGeneral"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { setCreationType } from "../../redux/actions/actionsToolkit"
import { fetchBackend } from "../../utils/functions/logic/fetchBackend"

export default function CardsNFTs() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const typesNFTs = useSelector(state => state.general.typesNFTs)
    const creationType = useSelector(state => state.toolkit.type)
    const history = useHistory()

    useEffect(() => {
        if (typesNFTs.length === 0) {
            fetchBackend('GET', '/typesNFT')
                .then((res) => {

                    dispatch(setTypesNFTs(res.data.allTypes.filter(e => e.type !== "Head" && e.type !== "Aura")))
                    setLoading(false)
                })
        }
        else {
            setLoading(false)
        }
    }, [])


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (

        <div className="flex flex-col justify-start items-start w-full h-full lg:pt-44 xl:pt-10">

            {loading ?
                <Loader />
                :
                <div className={`w-full h-full flex justify-around items-start`}>

                    {/* CARDS MOBILE TABLET  */}
                    <div className={`w-screen h-full flex lg:hidden justify-around items-start`}>
                        <Carousel
                            responsive={responsive}
                            draggable={false}
                            swipeable={true}
                            infinite={true}
                            arrows={false}
                            renderButtonGroupOutside={false}
                            customButtonGroup={false}
                            autoPlay={false}

                            sliderClass={`relative h-88`}
                            itemClass="flex justify-start items-center relative mr-1"
                            containerClass={`relative w-full z-20`}
                        >
                            {typesNFTs.map(e => {
                                let imgNft = require(`../../assets/images/accessories/${e.type}.png`)

                                return (
                                    <div
                                        onClick={e.assetBase ? () => {

                                            dispatch(setCreationType(e))
                                            history.push("/")
                                        } : null}
                                        className={`${!e.assetBase ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'} ${e.type === creationType ? 'border-[3px] border-[#F44CE1] shadow-around shadow-[#F44CE1]' : null} z-50 w-[90px] h-[90px] md:w-[111px] md:h-[111px] flex rounded-3xl shadow-around`}>
                                        <div className="w-full h-full relative flex justify-center items-center">
                                            <img src={imgNft} alt="" className=" w-full h-full object-cover rounded-3xl" />
                                            {!e.assetBase && <Icon icon={"bx:lock"} className="w-[55px]  text-white opacity-70 h-[55px] absolute" />}

                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>;
                    </div>

                    {/* CARDS DEKTOP  */}
                    <div className={`w-full h-full hidden lg:flex justify-around items-start`}>
                        {typesNFTs.map(e => {
                            let imgNft = require(`../../assets/images/accessories/${e.type}.png`)
                            return (
                                <div onClick={e.assetBase ? () => {

                                    dispatch(setCreationType(e))
                                    history.push("/")
                                } : null}
                                    className={`${!e.assetBase ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'} ${e.type === creationType ? 'border-[3px] border-[#F44CE1] shadow-around shadow-[#F44CE1]' : null} z-50 w-[90px] h-[90px] xl:w-[111px] xl:h-[111px] flex rounded-3xl shadow-around`}>
                                    <div className="w-full h-full relative flex justify-center items-center">
                                        <img src={imgNft} alt="" className=" w-full h-full object-cover rounded-3xl" />
                                        {!e.assetBase && <Icon icon={"bx:lock"} className="w-[55px]  text-white opacity-70 h-[55px] absolute" />}

                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>

            }

        </div>

    )
}
