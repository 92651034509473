import { Icon } from '@iconify/react'
import React from 'react'

export default function TitleWithIcon({ title, icon }) {
    return (
        <div className='w-full text-center lg:text-left xl:w-11/12 text-[22px] sm:text-[34px] lg:text-[38px] leading-tight'>

            <h1 className={`inline font-semibold z-50 whitespace-normal mb-2`}>{title}</h1>

            {icon && <Icon icon={icon} className="inline ml-2 mb-1 sm:mb-2 lg:mb-3"/>}

        </div>
    )
}
