import React, { useEffect } from 'react'
import { useState } from 'react';
import { buttonDownload } from '../../utils/constants/classes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BasicInput from '../../components/BasicInput/BasicInput';
import { useForm } from 'react-hook-form'

import { fetchBackend } from '../../utils/functions/logic/fetchBackend';

export default function AlertGift({ modalObj }) {

    const { t } = useTranslation()
    const user = useSelector(state => state.general.user)
    const { register, handleSubmit, formState: { errors, isValid }, reset} = useForm({ mode: 'all', delayError: 1000 });
    const [via, setVia] = useState('email')
    const [gifted, setGifted] = useState()

    useEffect(()=>{
        reset()
    },[via])

    const onSubmitForm = async (data) => {
        let objReq = {
            idNFT: modalObj.idNft,
            receiverEmail: via === 'email' ? data.input : "null",
            receiverWallet: via === 'wallet' ? data.input : "null",
        }

        try {
            let request = await fetchBackend('POST', '/nft/admin/gift', objReq, user)
            if(request.data.gifted){
                setGifted(true)
                setTimeout(() => {
                    modalObj.close()
                }, 2000);
            }
            return request

        } catch (err) {
            console.log(err)
        }
    }

    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let regexWallet = /^0x[a-fA-F0-9]{40}$/

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="flex items-center justify-center w-full h-full font-montserrat bg-white rounded-t-2xl sm:rounded-2xl">

            <div className='flex flex-col items-center justify-center w-10/12 h-11/12  sm:w-full gap-y-3'>

                <div className={`flex w-full flex-col justify-center items-center text-center gap-y-2 text-darkpurple`}>

                    <h4 className="font-bold sm:text-[21px]">{t('modal.modalGift.title')}</h4>

                    <p className=" text-[12px] sm:text-[16px] text-[#4D3E3E]">{t('modal.modalGift.description')}</p>

                    <div className='flex items-center justify-center gap-x-4'>

                        <div className='flex items-center gap-x-1'>
                            <input checked={via === 'email'} type='radio' name='via' onChange={()=>setVia('email')}/>
                            <h5>{t('modal.modalGift.email')}</h5>
                        </div>

                        <div className='flex items-center gap-x-1'>
                            <input checked={via === 'wallet'} type='radio' name='via' onChange={()=>setVia('wallet')}/>
                            <h5>{t('modal.modalGift.wallet')}</h5>
                        </div>
                    </div>

                    <BasicInput
                        inputAttributes={{
                            type: 'text'
                        }}
                        title={t(`modal.modalGift.${via}`)}
                        register={register}
                        registerName='input'
                        registerObj={{
                            required: true,
                            validate: (value) => {
                                if(via === 'email'){
                                    if (regexEmail.test(value)) {
                                        return true
                                    } else {
                                        return t("modal.modalGift.error")
                                    }
                                }
                                if(via === 'wallet'){
                                    if (regexWallet.test(value)) {
                                        return true
                                    } else {
                                        return t("modal.modalGift.error")
                                    }
                                }
                            }
                        }}
                        inputErrorClassName="text-black"
                        className='w-full sm:w-9/12 text-[#4D3E3E] text-[12px] sm:text-[12px]'
                        inputClassName='border text-[16px]  border-black rounded-2x1 text-black bg-transparent placeholder:text-gray-400 '
                        errors={errors} />

                </div>

                <div className='flex w-full flex-col gap-y-4 justify-between items-center'>
                    <button disabled={!isValid} type='submit' className={`${buttonDownload} ${gifted? 'bg-none bg-green-600' : ''} w-full text-[14px] sm:text-[16px] h-[42px] sm:w-7/12`} >{gifted ? t('modal.modalGift.send') : t('modal.modalGift.gift')}</button>
                    <button className={`font-semibold text-[16px] `} onClick={() => modalObj.close()}>{t('modal.modalGift.cancel')}</button>
                </div>

            </div>
        </form>
    )
}
