export const GET_ALL_CREATIONS = "GET_ALL_CREATIONS"
export const SET_TEXTURE_SNEAKER = "SET_TEXTURE_SNEAKER"
export const SET_IMAGE_NFT = "SET_IMAGE_NFT"
export const SET_TYPES_NFTS = 'SET_TYPES_NFTS'
export const SET_DATA_TOOLKIT = 'SET_DATA_TOOLKIT'
export const SET_TEXTURES_ORIGINAL = "SET_TEXTURES_ORIGINAL"
export const RESET_STATE = "RESET_STATE"
export const SET_FLAG_CHARGE_FILES= "SET_FLAG_CHARGE_FILES"
export const SET_USER = "SET_USER"
export const GET_USER_CREATIONS = "GET_USER_CREATIONS"
export const SET_CREATION_TYPE = "SET_CREATION_TYPE"