import { Icon } from "@iconify/react";
import { useHistory, useLocation } from "react-router-dom";
import { inputWhiteTransp } from "../../utils/constants/classes";


export default function Searchbar({placeholder, onSearch, place}) {

    const history = useHistory();
    const location = useLocation()
    
    return (
        <form className="w-full relative" onSubmit={(e)=>onSearch(e, history, location, place)}>
            
            <input 
            className={`${inputWhiteTransp} sm:text-[14px] lg:text-[16px]`}
            placeholder = {placeholder}
            />

            <button type="submit">
                <Icon 
                className="text-white absolute right-2 top-2.5 text-[20px] cursor-pointer" 
                icon='fe:search'
                />
            </button>

        </form>
    )
}
