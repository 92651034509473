import { Icon } from '@iconify/react'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import Loader from '../Loader/Loader'
import ModelViewer from '../ModelViewer/ModelViewer'

export default function ViewerAndTeaser({nft, flagViewer}) {

    const {t} = useTranslation()
    const [flagVideo, setFlagVideo] = useState(false)
   
    return (
        <div className='min-w-[288px] max-w-[360px] w-full h-[288px]  sm:h-[480px] sm:max-h-[480px] sm:max-w-[480px] relative flex flex-col justify-start items-start'>
            {nft.benefits.length > 0 &&
                <div className='bg-[#E9C956] rounded-full text-[12px] px-2 py-1 absolute top-2 left-0 z-50'>
                    {t('detailNFT.benefits')}
                </div>
            }
            {nft.teaser &&
                <div onClick={() => setFlagVideo(!flagVideo)} className='absolute top-2 right-0 bg-[#320A52] cursor-pointer opacity-[62] rounded-lg w-[30px] h-[30px] z-50 flex items-center justify-center sm:rounded-xl sm:w-[40px] sm:h-[40px]'>
                    <Icon icon={"clarity:play-solid"} className="text-[18px] sm:text-[24px] text-pink" />
                </div>
            }
            {!flagVideo ?
                <Suspense fallback={<Loader />} >
                    <ModelViewer 
                    nftType={nft.type}
                    flagViewer={flagViewer} 
                    textureSneaker={nft.texture_1} 
                    buttonFlag={true} 
                    modelProps={{
                        'camera-controls': true,
                        'interaction-prompt' :'none'
                    }}
                    />
                </Suspense>
            :
                <ReactPlayer
                width={'100%'}
                height={'100%'}
                controls
                loop
                playing={true}
                url={nft.teaser}
                />
            }
        </div>
    )
}
