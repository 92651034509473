import { Icon } from '@iconify/react'
import React from 'react'
import { Trans } from 'react-i18next'
import warningGradient from '../../assets/images/icons/warningGradient.svg'

export default function AlertExternalBrowser() {

    return (
        <div className='bg-[#eeeeee] w-full h-full rounded-2xl shadow-around p-4 text-[12px] flex items-center justify-between gap-x-2'>
            
            <Icon icon='akar-icons:arrow-up' className='absolute top-[4px] right-[6px] text-[16px] text-[#666]'/>
            
            <img src={warningGradient} alt='warning' className='w-3/12'/>
            
            <div className='w-9/12'>
                <Trans i18nKey={"redirectInstagram.title"} components={[<Icon icon='charm:menu-meatball' className='inline text-[14px] -mt-0.5'/>, <b></b>]} />
            </div>

        </div>
    )
}
