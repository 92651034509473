// require("dotenv").config()

//PRODUCTION

export const urlBase = process.env.REACT_APP_URL_BASE_PRODUCTION;

export const newServerUrl = process.env.REACT_APP_SERVER_NEWURL_PRODUCTION


//DEVELOPMENT

// export const urlBase = process.env.REACT_APP_URL_BASE_DEVELOPMENT;

// export const newServerUrl = process.env.REACT_APP_SERVER_NEWURL_QA

// export const newServerUrl = process.env.REACT_APP_SERVER_NEWURL_DEVELOPMENT;


//QA

// export const urlBase = process.env.REACT_APP_URL_BASE_QA

// export const newServerUrl = process.env.REACT_APP_SERVER_NEWURL_QA


//CRYPTO VARIABLES

export const tokenContract = process.env.REACT_APP_TOKEN_CONTRACT;

export const walletAdmin = process.env.REACT_APP_WALLET_ADMIN;


// KEYS

export const apiKeyPignata = process.env.REACT_APP_PIGNATA_API_KEY;

export const apiSecretKeyPignata = process.env.REACT_APP_PIGNATA_API_SECRET_KEY;