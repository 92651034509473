//GENERAL
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

//COMPONENTS
import BasicInput from '../../components/BasicInput/BasicInput'
import BasicTextArea from '../../components/BasicTextArea/BasicTextArea'

//UTILS
import { Icon } from '@iconify/react'
import logo from '../../assets/images/logos/logoQuestions.svg'
import { buttonDownload } from '../../utils/constants/classes'
import { fetchBackend } from '../../utils/functions/logic/fetchBackend'

export default function AlertQuestions({ setModalQuestions }) {


    const [emailSend, setEmailSend] = useState(false)

    const { register, handleSubmit, formState: { errors, isValid }, watch } = useForm({ mode: 'all', delayError: 1000 });

    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const { t } = useTranslation()

    const onSubmitForm = async (data) => {
        try {
            let obj = {
                feedback: data.message,
                name: data.name,
                email: data.mail
            }
            let responseEmail = await fetchBackend('POST', `/mailing/feedback`, obj)
            if (responseEmail.data.send) {
                setEmailSend(true)
            }
            setTimeout(() => {
                setModalQuestions(false)

            }, 4000);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className='w-full h-full flex justify-center items-center bg-white z-50 rounded-2xl font-montserrat'>
            <Icon onClick={() => setModalQuestions(false)} icon='bi:x' className={`absolute text-[30px] z-[60] cursor-pointer text-darkpurple right-2 top-2  `} />

            <div className='flex flex-col justify-around items-center w-10/12 h-11/12'>

                <img src={logo} className="w-[42px] h-[42px] sm:w-[80px] sm:h-[80px]" alt='empty' />

                <div className='flex flex-col w-full justify-start items-center gap-y-2 text-center'>
                    <h2 className='text-[16px] sm:text-[21px] font-bold text-darkpurple'>{t("modal.alertQuestions.title")}</h2>
                    <p className='text-[12px] sm:text-[16px] text-center '>{t("modal.alertQuestions.text")}</p>
                </div>

                <div className='flex flex-col sm:flex-row justify-between gap-x-3 items-start w-full'>
                    <BasicInput
                        inputAttributes={{
                            placeholder: t("modal.alertQuestions.place1"),
                            type: 'text'
                        }}
                        title={t("modal.alertQuestions.label1")}
                        className='w-full sm:w-6/12 text-[12px] sm:text-[16px]'
                        inputClassName='border border-black rounded-2x1 text-black placeholder:text-gray-500 '
                        register={register}
                        registerName='name'
                        registerObj={{
                            required: {
                                value: false,

                            },
                            maxLength: {
                                value: 15,
                                message: t('toolkitNft.viewConfigure.nameLength', { min: 3, max: 15 })
                            },
                            minLength: {
                                value: 3,
                                message: t('toolkitNft.viewConfigure.nameLength', { min: 3, max: 15 })
                            },
                        }}
                        errors={errors}
                    />
                    <BasicInput
                        inputAttributes={{
                            placeholder: t("modal.alertQuestions.place2"),
                            type: 'mail'
                        }}
                        title={t("modal.alertQuestions.label2")}
                        register={register}
                        registerName='mail'
                        registerObj={{
                            required: false,
                            validate: (value) => {
                                if (value.length > 0) {
                                    if (regexEmail.test(value)) {
                                        return true
                                    } else {
                                        return t("modal.alertQuestions.invalidEmail")
                                    }
                                } else {
                                    return true
                                }

                            }

                        }}
                        error2={true}
                        inputErrorClassName="text-black"
                        className='w-full sm:w-6/12 text-black text-[12px] sm:text-[16px]'
                        inputClassName='border border-black rounded-2x1 text-black bg-transparent placeholder:text-gray-500 '
                        errors={errors}
                    />
                </div>

                <BasicTextArea
                    textareaAttributes={{
                        maxLength: 200,
                        placeholder: t("modal.alertQuestions.place3")
                    }}
                    title={t("modal.alertQuestions.label3")}
                    watch={watch}
                    errors={errors}
                    register={register}
                    textColor={'text-black '}
                    registerObj={{
                        required: {
                            value: true,
                            message: t('modal.alertQuestions.messageRequired')
                        },
                        maxLength: {
                            value: 200,
                            message: t('modal.alertQuestions.messageLength', { min: 3, max: 200 })
                        },
                        minLength: {
                            value: 3,
                            message: t('modal.alertQuestions.messageLength', { min: 3, max: 200 })
                        }
                    }}
                    classNameTextArea={'h-[125px] border border-black text-black placeholder:text-gray-500'}
                    registerName='message'
                    className='text-[12px] sm:text-[16px]'
                />

                {emailSend ?
                    <div className='flex w-full justify-center items-center gap-x-2 text-[#23a730]'>
                        <Icon icon="carbon:email-new" className=' text-[30px] font-bold' />
                        <span className='text-[16px] font-semibold'>{t("modal.alertQuestions.send")}</span>
                    </div>
                    : <button disabled={!errors && isValid} type='submit' className={`${buttonDownload} w-full sm:w-9/12`}>{t("modal.alertQuestions.button")}</button>}

            </div>

        </form>
    )
}
