import React from 'react'
import Tooltip from 'rc-tooltip';
import { Icon } from '@iconify/react';

export default function BasicTooltip({message}) {
    return (
        <Tooltip   
            tip placement="right" 
            trigger={['hover']} 
            overlay={<span>{message}</span>}
            overlayStyle={{width: '170px'}}
        >
            <Icon 
                icon='bi:question-circle-fill' 
                className='text-white text-[10px] ml-1.5 cursor-help'
                href='#'
            />
        </Tooltip>
    )
}
