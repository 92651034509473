
import axios from 'axios'
import { apiKeyPignata, apiSecretKeyPignata } from '../../../config/configServer';

export async function handleTexturePignata(file) {
    try {

        let data = new FormData()

        data.append('file', file)
       
        

        const config = {
            url: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
            method: 'POST',
            headers: {
                'Content-Type': `multipart/form-data;boundary=${data._boundary}`,
                pinata_api_key: apiKeyPignata,
                pinata_secret_api_key: apiSecretKeyPignata
            },
            data

        }
        const response = await axios(config)
        
        return response
    } catch (err) {
        console.log(err)
    }

}


