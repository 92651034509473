//COMPONENTS
import CardNft from '../CardNft/CardNft'

//UTILS
import MessageMarketGrid from "../MessageMarketGrid/MessageMarketGrid";

export default function MarketGrid({ items }) {


    return (
        items?.length > 0 ?
            <div className=" relative w-full py-7 lg:pt-14 lg:gap-y-6 xl:gap-y-10 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 justify-items-center gap-x-4 gap-y-4 ">

               { items.map(e => <CardNft nft={e} />)}
            </div>
        :
            <MessageMarketGrid/>
    )    
}
