//GENERAL
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

//COMPONENTS
import Loader from "../../components/Loader/Loader";
import MarketGrid from "../../components/MarketGrid/MarketGrid";
import { getItemsMarket } from "../../redux/actions/actionsGeneral";

//UTILS
import { backgroundGradient, containerStructureGeneral, normalTitle, structureViewsPanel } from "../../utils/constants/classes";
import Toolbar from "../../components/Toolbar/Toolbar";

export default function FashionCollection() {
    
    const {t} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const items = useSelector(state => state.general.creations)
    const [loading, setLoading] = useState(true)
    

   
    useEffect(()=>{
        setLoading(true)
        dispatch(getItemsMarket(Object.fromEntries(new URLSearchParams(location.search))))
        .then(()=> {
            setLoading(false)
        })
    },[location])

    return (
        <div className={`${containerStructureGeneral} ${backgroundGradient}`}>
            <div className={`${structureViewsPanel} `}>

                <h1 className={`${normalTitle} text-white uppercase`}>{t('fashioncollection.title')}</h1>
                <h4 className="text-white text-[12px] sm:text-[15px] lg:text-[16px] mt-3 md:mt-4 xl:mt-6">{t('fashioncollection.text')}</h4>

                <Toolbar place='fashioncollections'/>

                {
                    loading?
                        <Loader/>
                    :
                        <MarketGrid items={items}/>
                }

            </div>
        </div>
    )
}
